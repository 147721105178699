import React from "react";

export const SmallVolume = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3999 17.7251V15.6751C12.8999 15.2418 14.1082 14.4084 15.0249 13.1751C15.9416 11.9418 16.3999 10.5418 16.3999 8.9751C16.3999 7.40843 15.9416 6.00843 15.0249 4.7751C14.1082 3.54176 12.8999 2.70843 11.3999 2.2751V0.225098C13.4666 0.691764 15.1499 1.7376 16.4499 3.3626C17.7499 4.9876 18.3999 6.85843 18.3999 8.9751C18.3999 11.0918 17.7499 12.9626 16.4499 14.5876C15.1499 16.2126 13.4666 17.2584 11.3999 17.7251ZM0.399902 12.0001V6.0001H4.3999L9.3999 1.0001V17.0001L4.3999 12.0001H0.399902ZM11.3999 13.0001V4.9501C12.1832 5.31676 12.7957 5.86676 13.2374 6.6001C13.6791 7.33343 13.8999 8.13343 13.8999 9.0001C13.8999 9.8501 13.6791 10.6376 13.2374 11.3626C12.7957 12.0876 12.1832 12.6334 11.3999 13.0001ZM7.3999 5.8501L5.2499 8.0001H2.3999V10.0001H5.2499L7.3999 12.1501V5.8501Z"
      fill="#9BA4B5"
    />
  </svg>
);
