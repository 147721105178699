import React, { useEffect, useState } from "react";
import { Wrapper } from "./styledComponents";
import { useTranslation } from "react-i18next";

import { IMiddlePanel } from "core/types/middlePanel";
import MiddlePanel from "src/components/molecules/MiddlePanel";
import { middlePanels } from "src/core/utils";

export const MiddlePanels = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 1450);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <Wrapper>
      {middlePanels.map((panel: IMiddlePanel) => (
        <MiddlePanel
          key={panel.title}
          title={t(panel.title)}
          image={panel.image}
          content={panel.content}
          moreInfo={panel.moreInfo}
          operator={panel.operator}
          isMobile={isMobile}
          isFlexReversed={panel.isFlexReversed}
        />
      ))}
    </Wrapper>
  );
};
