import { Box, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import linePoint from "../../../assets/list_item_red.svg";
import groupIcon from "../../../assets/groupIconRed.svg";
import { ButtonFillOutForm } from "src/components/atoms/ButtonFillOutForm";
import SecondarySection from "src/components/molecules/SecondarySection";
import { PhoneCallIcon } from "src/components/icons/PhoneCallIcon";

export const TicketConditionsGroup: React.FC = () => {
  const { t } = useTranslation();
  const groupConditionTourAreas = [
    t("group_condition_tour_one"),
    t("group_condition_tour_two"),
    t("group_condition_tour_three"),
    t("group_condition_tour_four"),
    t("group_condition_tour_five"),
  ];
  return (
    <Box className={styles.container}>
      <Box className={styles.mainBox}>
        <Box className={styles.titleBox}>
          <img alt="img" src={groupIcon} style={{ margin: "0 20px 0 0" }} />
          <span className={styles.titleString}>{t("company_visits_heading")}</span>
        </Box>
        <Box className={styles.subtitleBox}>
          <span className={styles.subtitleText1}>{t("group_title_text")}</span>
        </Box>
        <Box className={styles.noticeBox}>
          <div className={styles.line}></div>
          <Box className={styles.noticeWrapper}>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: t("group_condition_one") }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <Stack direction="column">
                <span className={styles.text}>{t("group_condition_tour_heading")}</span>
                <Stack direction="column" sx={{ margin: "6px 0 18px 18px" }}>
                  {groupConditionTourAreas.map((area) => (
                    <SecondarySection
                      text={area}
                      key={area}
                      TextAsNode={<span className={styles.text}>{area}</span>}
                      transparentBg
                    />
                  ))}
                </Stack>
                <span className={styles.text}>{t("group_condition_tour_length")}</span>
              </Stack>
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: t("group_condition_safety") }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: t("group_condition_two") }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span className={[styles.text, styles.red_text, styles.bold_text].join(" ")}>
                {t("group_condition_call_to_action")}
              </span>
            </Box>
          </Box>
          <div className={styles.line}></div>
        </Box>

        <Stack direction="column" gap={7} justifyContent="center" alignItems="center" pb={7}>
          <Box className={styles.buttonBox}>
            <ButtonFillOutForm />
          </Box>
          <span className={[styles.text, styles.capitalized_text].join(" ")}>{t("or")}</span>
          <a href={`tel:${t("phone")}`} style={{ textDecoration: "none" }}>
            <Stack direction="row" gap={3} justifyContent="center" alignItems="center">
              <PhoneCallIcon />
              <span className={styles.titleString}>{t("phone")}</span>
            </Stack>
          </a>
        </Stack>
      </Box>
    </Box>
  );
};
