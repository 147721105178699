import React from "react";

import {
  Wrapper,
  Image,
  ItemsWrapper,
  Price,
  Item,
  GreyLine,
  DiscountWrapper,
  DiscountText,
  DiscountPercent,
  PriceSubWrapper,
  PriceWrapper,
  PriceText,
  SelectButton,
  AdditionalPrice,
  SecondPartWrapper,
} from "../Panel/styledComponents";

import { IBundle, IItem } from "src/core/types/budnles";
import { Loc } from "src/core/types/loc";
import { useTranslation } from "react-i18next";
import { ItemText } from "src/components/pages/PageSetTypeOfTicket/components/PanelRow/styledComponent";
import Section from "src/components/molecules/Section";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedBundle } from "src/core/redux/actions/bundleActions";
import { PRE_RESERVATION_ROUTE } from "src/core/routing";

const Panel = ({ bundle, loc }: { bundle: IBundle; loc: Loc }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectPanel = () => {
    dispatch(setSelectedBundle(bundle));
    navigate(PRE_RESERVATION_ROUTE);
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { image_url, items, discount, guide_price } = bundle;

  const getFullPrice = (): number => {
    const price = items.reduce((acc, curr) => acc + curr.price, 0);
    if (discount) {
      return Math.round(price * (1 - discount / 100));
    }
    return price;
  };

  return (
    <Wrapper>
      <Image src={image_url} />
      <ItemsWrapper>
        {bundle &&
          items.map((item: IItem) => (
            <Item key={item.name[loc]}>
              <Section key={item.name[loc]} text={item.name[loc]} TextComponent={ItemText} />
              <Price>{`${item.price}${t("cz_currency")}`}</Price>
            </Item>
          ))}
      </ItemsWrapper>
      <GreyLine />
      <SecondPartWrapper>
        {discount && (
          <DiscountWrapper>
            <DiscountText>{t("sale")}</DiscountText>
            <DiscountPercent>{`${discount} %`}</DiscountPercent>
          </DiscountWrapper>
        )}
        <PriceWrapper>
          <PriceSubWrapper>
            <PriceText>{`${getFullPrice()} ${t("cz_currency")}`}</PriceText>
            {guide_price && (
              <AdditionalPrice
                dangerouslySetInnerHTML={{
                  __html: `Cena s pruvodcem  <br /> ${guide_price} ${t("cz_currency")}`,
                }}
              />
            )}
          </PriceSubWrapper>
          <SelectButton onClick={selectPanel}>Zvolit</SelectButton>
        </PriceWrapper>
      </SecondPartWrapper>
    </Wrapper>
  );
};

export default Panel;
