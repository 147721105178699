import { Action, CapacityType } from "src/core/types/reduxTypes";
import { Capacity } from "src/core/types/capacity";

const initialState: Capacity = {};

export const CapacityReducer = (
  //eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: Action<CapacityType, Capacity>,
) => {
  switch (action.type) {
    case "SET_CAPACITY":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
