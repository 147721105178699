import React, { useEffect, useState } from "react";
import {
  AppStoreCzBadge,
  AppStoreDeBadge,
  AppStoreEnBadge,
  GplayCzBadge,
  GplayDeBadge,
  GplayEnBadge,
} from "src/components/icons/marketBadges";

import styles from "./styles.module.css";
import { Link, Stack } from "@mui/material";
import { Loc } from "src/core/types/loc";

type TJustifyContent = "center" | "flex-start" | "flex-end" | "space-between" | "start" | "end";

const BadgesComponent = ({ justifyContent = "center" }: { justifyContent?: TJustifyContent }) => {
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const updateMedia = (): void => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <Stack
      direction="row"
      gap={{ xs: 2 }}
      className={styles.wrapper}
      justifyContent={justifyContent}
    >
      <Link
        href="https://play.google.com/store/apps/details?id=cz.VisitJakubBrno.officialapp"
        color="inherit"
        target="_blank"
      >
        {loc === "cs" && <GplayCzBadge width={isMobile ? "140" : "195"} height="80" />}
        {loc === "en" && <GplayEnBadge width={isMobile ? "140" : "195"} height="80" />}
        {loc === "de" && <GplayDeBadge width={isMobile ? "140" : "195"} height="80" />}
      </Link>

      <Link
        href="https://apps.apple.com/app/visit-jakub-brno/id6503604487"
        color="inherit"
        target="_blank"
      >
        {loc === "cs" && <AppStoreCzBadge width={isMobile ? "130" : "180"} height="80" />}
        {loc === "en" && <AppStoreEnBadge width={isMobile ? "130" : "180"} height="80" />}
        {loc === "de" && <AppStoreDeBadge width={isMobile ? "130" : "180"} height="80" />}
      </Link>
    </Stack>
  );
};

export default BadgesComponent;
