import React from "react";
import {
  ChangeButton,
  DescriptionText,
  DescriptionWrapper,
  InfoWrapper,
  Line,
  Wrapper,
} from "src/components/molecules/ReservationComponent/components/ChangeComponent/styledComponents";
import { useTranslation } from "react-i18next";

const ChangeComponent = ({
  children,
  prevStep,
  type,
}: {
  children?: React.ReactNode;
  prevStep: () => void;
  type: string;
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <DescriptionWrapper>
        <DescriptionText>{type}</DescriptionText>
        <Line />
        <ChangeButton onClick={prevStep}>{t("to_change")}</ChangeButton>
      </DescriptionWrapper>
      {children && <InfoWrapper>{children}</InfoWrapper>}
    </Wrapper>
  );
};

export default ChangeComponent;
