import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//components
import { Box } from "@mui/material";
import { galleryPhotos } from "src/core/helpers/galleryPhotos";
import NoPhoto from "src/components/icons/NoPhoto";
import SubPageHeader from "src/components/atoms/SubPageHeader";
import { setImage } from "src/core/redux/actions/galleryActions";

import styles from "./styles.module.css";

const PageGallery = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectImageIndex = (image: number): void => {
    dispatch(setImage(image));
    navigate("/showroom");
  };

  const topRef = useRef<any>(null);
  useEffect(() => {
    if (topRef) {
      window.scrollTo({ top: topRef.current.offsetTop, behavior: "smooth" });
    }
  }, [topRef]);
  return (
    <Box className={styles.box} ref={topRef}>
      <SubPageHeader pageName={t("main_page_gallery")} />
      <Box className={styles.wrapper}>
        <Box className={styles.content}>
          {galleryPhotos.map((el, index) => {
            if (el) {
              return (
                <img
                  key={index}
                  className={styles.image}
                  onClick={() => selectImageIndex(index)}
                  src={el}
                  alt=""
                />
              );
            }
            return <NoPhoto key={index} />;
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default PageGallery;
