import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import personIcon from "../../../assets/kontaktni_udaje_cervene.svg";
import { styles } from "./styles";
import penIcon from "../../../assets/edit.svg";
import bluePersonal from "../../../assets/personal_blue.svg";
import { useFormValuesContext } from "src/core/context";
import { Controller, useForm } from "react-hook-form";
import { CheckOutStep, Tab } from "src/core/types";
import styless from "./style.module.css";
import checkmarkIcon from "../../../assets/checkmark (1).svg";
import call from "../../../assets/call.svg";
import mail from "../../../assets/mail (2).svg";
import osoba from "../../../assets/osoba_cerna.svg";
import errorIcon from "../../../assets/error (1).svg";
import FullScreenTicketButton from "src/components/atoms/FullScreenTicketButton";
import { ButtonNextStep } from "src/components/atoms/ButtonNextStep";
import { ButtonArrow } from "src/components/icons/ButtonArrow";
import { setStepToPen, setToNextStep } from "src/core/utils";
import { useDispatch } from "react-redux";
import { setUserData } from "src/core/redux/actions/reservationDataActions";

interface PersonalDataProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  style: any;
  tabs: Tab[];
  nextStep: string;
}

export const PersonalData: React.FC<PersonalDataProps> = ({
  updateCheckOutState,
  step,
  panels,
  style,
  tabs,
  nextStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isRecapActive = panels.find((el) => el.key === "4")?.isEnabled || false;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const penClick = () => {
    const { newTabs, newPanels } = setStepToPen({
      tabs: tabs,
      panels: panels,
      currentKey: Number(step.key),
    });
    updateCheckOutState(newTabs, newPanels);
  };
  const updateMedia = (): void => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
  }, []);

  const { formValues, setFormValues } = useFormValuesContext();

  const ticketsForm = useForm({
    defaultValues: {
      name: formValues.name,
      phone_number: formValues.phone_number,
      email: formValues.email,
      validEmail: formValues.validEmail,
    },
    reValidateMode: "onChange",
    mode: "all",
  });
  const { control, getValues } = ticketsForm;

  const handleContinue = (formData: {
    name: string;
    phone_number: string | number;
    email: string;
    validEmail: string;
  }) => {
    setFormValues({ ...formValues, ...formData });
    const { validEmail, ...rest } = formData;
    dispatch(setUserData(rest));
    sessionStorage.setItem("current_email", formData.email);
    const { newPanels, newTabs } = setToNextStep({
      tabs: tabs,
      panels: panels,
      currentKey: Number(step.key),
    });
    updateCheckOutState(newTabs, newPanels);
  };
  return (
    <Box style={style}>
      {step.isEnabled ? (
        <>
          <form onSubmit={ticketsForm.handleSubmit(handleContinue)}>
            <Box className={styless.box}>
              <span style={styles.title}>
                <img src={personIcon} style={{ marginRight: "8px" }} />
                <span>{t("contant_button")}</span>
              </span>
              <Box className={styless.innerBoxRow}>
                <Box className={styless.innerBox}>
                  <Box className={styless.textfieldRow}>
                    <Box className={styless.textfieldBox}>
                      <img src={osoba} />
                      <FormControl
                        className={styless.textField}
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        <InputLabel
                          htmlFor="name_surname"
                          sx={{
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-focused": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-error": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#E31340",
                            },
                          }}
                        >
                          {t("name_surname")}
                        </InputLabel>
                        <Controller
                          name="name"
                          control={control}
                          rules={{
                            required: t("fill_in_name_and_surname"),
                          }}
                          render={({ field, fieldState: { isTouched, error } }) => (
                            <React.Fragment>
                              <OutlinedInput
                                id="name_surname"
                                value={field.value}
                                onChange={field.onChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {error ? (
                                      <img src={errorIcon} style={{ width: "16px" }} />
                                    ) : isTouched && !error ? (
                                      <img src={checkmarkIcon} style={{ width: "16px" }} />
                                    ) : null}
                                  </InputAdornment>
                                }
                                error={!!error}
                                label={t("name_surname")}
                                sx={{
                                  "& input": {
                                    color: error ? "#E31340" : "#000000",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#79747E",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#062762",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #000000",
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }}
                              />
                              {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </React.Fragment>
                          )}
                        />
                      </FormControl>
                    </Box>
                    <Box className={styless.textfieldBox}>
                      <img src={call} />
                      <FormControl
                        className={styless.textField}
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        <InputLabel
                          sx={{
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-focused": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-error": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#E31340",
                            },
                          }}
                          htmlFor="phone_number"
                        >
                          {t("phone_number")}
                        </InputLabel>
                        <Controller
                          name="phone_number"
                          control={control}
                          rules={{
                            validate: (value) => {
                              const isValidNumber =
                                /^\+?\d{1,4}?[\s.-]?\(?\d{1,4}?\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/.test(
                                  String(value),
                                );
                              return isValidNumber || t("fill_in_valid_phone_number");
                            },
                            required: t("fill_in_valid_phone_number"),
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { isTouched, error },
                          }) => (
                            <React.Fragment>
                              <OutlinedInput
                                id="phone_number"
                                label={t("phone_number")}
                                value={value}
                                onChange={onChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {error ? (
                                      <img src={errorIcon} style={{ width: "16px" }} />
                                    ) : isTouched && !error ? (
                                      <img src={checkmarkIcon} style={{ width: "16px" }} />
                                    ) : null}
                                  </InputAdornment>
                                }
                                error={!!error}
                                sx={{
                                  "& input": {
                                    color: error ? "#E31340" : "#000000",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#79747E",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#062762",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #000000",
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }}
                              />
                              {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </React.Fragment>
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className={styless.textfieldRow}>
                    <Box className={styless.textfieldBox}>
                      <img src={mail} />
                      <FormControl
                        className={styless.textField}
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        <InputLabel
                          sx={{
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-focused": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-error": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#E31340",
                            },
                          }}
                          htmlFor="email_address"
                        >
                          {t("email_address")}
                        </InputLabel>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: t("fill_in_valid_email_address"),
                            validate: (v) =>
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                              t("fill_in_valid_email_address"),
                          }}
                          render={({ field, fieldState: { isTouched, error } }) => (
                            <React.Fragment>
                              <OutlinedInput
                                id="email"
                                value={field.value}
                                onChange={field.onChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {error ? (
                                      <img src={errorIcon} style={{ width: "16px" }} />
                                    ) : isTouched && !error ? (
                                      <img src={checkmarkIcon} style={{ width: "16px" }} />
                                    ) : null}
                                  </InputAdornment>
                                }
                                error={!!error}
                                label={t("email_address")}
                                sx={{
                                  "& input": {
                                    color: error ? "#E31340" : "#000000",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#79747E",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#062762",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #000000",
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }}
                              />
                              {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </React.Fragment>
                          )}
                        />
                      </FormControl>
                    </Box>
                    <Box className={styless.textfieldBox}>
                      <img src={mail} />
                      <FormControl
                        className={styless.textField}
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        <InputLabel
                          sx={{
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-focused": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-error": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#E31340",
                            },
                          }}
                          htmlFor="email_address_confirmation"
                        >
                          {t("email_address_confirmation")}
                        </InputLabel>
                        <Controller
                          name="validEmail"
                          control={control}
                          rules={{
                            required: t("fill_in_valid_email_address"),
                            validate: (value) =>
                              value === getValues("email") || t("fill_in_valid_email_address"),
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { isTouched, error },
                          }) => (
                            <React.Fragment>
                              <OutlinedInput
                                id="email_address_confirmation"
                                label={t("email_address_confirmation")}
                                value={value}
                                onChange={onChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {error ? (
                                      <img src={errorIcon} style={{ width: "16px" }} />
                                    ) : isTouched && !error ? (
                                      <img src={checkmarkIcon} style={{ width: "16px" }} />
                                    ) : null}
                                  </InputAdornment>
                                }
                                error={!!error}
                                sx={{
                                  "& input": {
                                    color: error ? "#E31340" : "#000000",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#79747E",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#062762",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #000000",
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }}
                              />
                              {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </React.Fragment>
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
                <Box className={styless.button_wrapper}>
                  {!isMobile && (
                    <ButtonNextStep isActive={!!ticketsForm.watch("validEmail")}>
                      {t("recap_and_payment")}
                      <ButtonArrow />
                    </ButtonNextStep>
                  )}
                </Box>
                {step.isEnabled && isMobile && (
                  <Button type="submit">
                    <FullScreenTicketButton nextStep={nextStep} />
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        </>
      ) : (
        step.isStepFinished &&
        !isRecapActive && (
          <Box className={styless.closedBox}>
            <div className={styless.rowBox}>
              <img src={bluePersonal} alt="Blue Personal Icon" />
              <Typography sx={{ color: "#051C4766", fontWeight: "600" }}>
                {t("contact_information")}
              </Typography>
            </div>
            {step.isPen && (
              <img className={styless.pen} src={penIcon} onClick={penClick} alt="Pen Icon" />
            )}
          </Box>
        )
      )}
    </Box>
  );
};
