import React from "react";
import {
  Discount,
  Header,
  HeaderText,
  HorizontalLine,
  Logo,
  MiddleWrapper,
  PriceWrapper,
  SlotName,
  SlotPrice,
  TotalPrice,
  TotalPriceItem,
  TotalPriceText,
  TotalPriceWrapper,
  Wrapper,
} from "src/components/atoms/TicketsReceipt/styledComponents";

import logo from "../../../assets/logo_pos.svg";
import { IBundle, SlotKey } from "src/core/types/budnles";
import { getDiscountPrice } from "src/core/utils/getDiscountPrice";
import { Loc } from "src/core/types/loc";

const TicketsReceipt = ({
  getTourPrice,
  bundle,
  isRecapPayment = false,
}: {
  getTourPrice: (tour: SlotKey) => number;
  bundle: IBundle;
  isRecapPayment?: boolean;
}) => {
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const getTotalPrice = () => {
    let total = 0;
    bundle.tours.forEach((tour: SlotKey) => (total += getTourPrice(tour)));
    return total;
  };
  return (
    <Wrapper isRecapPayment={isRecapPayment}>
      {!isRecapPayment && (
        <Header>
          <Logo src={logo} alt="logo" />
          <HeaderText>Vstupenky</HeaderText>
        </Header>
      )}
      <MiddleWrapper isRecapPayment={isRecapPayment}>
        {bundle.tours.map((tour: SlotKey, i: number) => (
          <PriceWrapper key={tour}>
            <SlotName>{bundle.items[i].name[loc]}</SlotName>
            <SlotPrice>{getTourPrice(tour)}</SlotPrice>
          </PriceWrapper>
        ))}
      </MiddleWrapper>
      <HorizontalLine />
      <TotalPriceWrapper>
        {bundle.discount && (
          <>
            <TotalPriceItem>
              <TotalPriceText>Celkova Cena</TotalPriceText>
              <TotalPriceText>{getTotalPrice()}</TotalPriceText>
            </TotalPriceItem>
            <TotalPriceItem>
              <Discount>Sleva</Discount>
              <Discount>{`${bundle.discount} %`}</Discount>
            </TotalPriceItem>
          </>
        )}
        <TotalPriceItem>
          <TotalPrice>Vysledna cena</TotalPrice>
          <TotalPrice>
            {getDiscountPrice({ price: getTotalPrice(), percentage: Number(bundle.discount) })}
          </TotalPrice>
        </TotalPriceItem>
      </TotalPriceWrapper>
    </Wrapper>
  );
};

export default TicketsReceipt;
