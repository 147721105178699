import { Typography } from "@mui/material";
import React from "react";

export const StyledParagraphTextDangerous = ({ text, sx }: { text: string; sx?: any }) => {
  return (
    <Typography
      variant="body1"
      fontWeight={400}
      bgcolor="#ffffff"
      color="#000"
      sx={sx}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
