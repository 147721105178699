import React from "react";

export const PhoneCallIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.226 5.27015C24.1955 -1.75821 12.7985 -1.75652 5.77015 5.27402C-1.25821 12.3046 -1.25652 23.7015 5.77402 30.7299C12.8046 37.7582 24.2015 37.7565 31.2299 30.726C34.6053 27.3495 36.5011 22.7704 36.5 17.9961C36.499 13.2226 34.6019 8.645 31.226 5.27015ZM27.7604 25.0738C27.7596 25.0746 27.7588 25.0754 27.758 25.0762V25.0702L26.846 25.9762C25.6665 27.1706 23.9487 27.662 22.3159 27.2722C20.671 26.8319 19.1072 26.131 17.684 25.1962C16.3617 24.3511 15.1363 23.3632 14.03 22.2502C13.0121 21.2397 12.0976 20.1302 11.3 18.9382C10.4275 17.6556 9.73697 16.2583 9.24795 14.7862C8.68735 13.0568 9.1519 11.1591 10.448 9.88421L11.516 8.81623C11.8129 8.51796 12.2954 8.51691 12.5936 8.81384C12.5943 8.81461 12.5952 8.81539 12.596 8.81623L15.9679 12.1882C16.2662 12.4851 16.2672 12.9676 15.9703 13.2658C15.9695 13.2666 15.9688 13.2674 15.9679 13.2682L13.9879 15.2482C13.4198 15.8101 13.3484 16.7031 13.8199 17.3482C14.5361 18.3311 15.3286 19.2559 16.19 20.1142C17.1504 21.0788 18.1944 21.9562 19.3099 22.7363C19.9545 23.1858 20.8283 23.11 21.3859 22.5563L23.2999 20.6123C23.5968 20.314 24.0793 20.3129 24.3775 20.6099C24.3783 20.6106 24.3791 20.6114 24.3799 20.6123L27.7579 23.9963C28.0563 24.2931 28.0573 24.7755 27.7604 25.0738Z"
        fill="#E31340"
      />
    </svg>
  );
};
