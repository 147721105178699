import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { SubHeader } from "src/components/atoms/SubHeader";
import { TicketConditionsGroup } from "src/components/atoms/TicketConditionsGroup";
import VisitConditions from "src/components/atoms/VisitConditions";
import { useTranslation } from "react-i18next";

import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";
import styles from "./styles.module.css";

export const PageGroup = () => {
  const topRef = useRef<any>();
  const isApp = sessionStorage.getItem("isApp");
  const { t } = useTranslation();

  const ScrollToTop = (): void => {
    window.scrollTo({
      top: topRef.current.offsetTop - 20,
      behavior: "smooth",
    });
  };

  const getCss = (): string => {
    if (isApp) {
      return styles.mob_wrapper;
    }
    return styles.wrapper;
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <Box className={getCss()} ref={topRef}>
      <SubHeader
        mainPhoto={mainPhoto}
        title={t("visit_with_company")}
        subtitle={t("jacobs_church_in_brno")}
      />
      <TicketConditionsGroup />
      <Box my={6} sx={{ px: "18px" }}>
        <VisitConditions />
      </Box>
    </Box>
  );
};
