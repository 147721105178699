import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const DescriptionText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #062762;
`;

export const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  border-bottom: 1px dotted #e1e1e1;
`;

export const ChangeButton = styled.div`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #e6264c;
  cursor: pointer;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #000000;
`;
