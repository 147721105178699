import React, { useEffect, useState } from "react";
import {
  ButtonCount,
  ButtonDecrement,
  ButtonIncrement,
  Buttons,
  DescriptionMessage,
  ErrorMessage,
  ErrorWrapper,
  Price,
  PriceDescription,
  PriceWrapper,
  SubTitle,
  SubTitleBox,
  SubTitleTwo,
  Wrapper,
  WrapperWithError,
} from "src/components/atoms/AmountPeopleComponent/styledComponents";
import { useTranslation } from "react-i18next";
import { IBundle } from "src/core/types/budnles";
import { getDiscountPrice } from "src/core/utils/getDiscountPrice";
import { Loc } from "src/core/types/loc";

import minus from "../../../assets/minus_gray.svg";
import activeMinus from "../../../assets/minus-red.svg";
import unActivePlus from "../../../assets/plus_color.svg";
import plus from "../../../assets/unActivePlus.svg";

interface IAmountPeopleComponent {
  title: string;
  subtitle: string;
  price: number;
  count: number;
  increment: () => void;
  decrement: () => void;
  status: string;
  isError: boolean | null;
  errorMessage: string | null;
  isPermanentMessage?: boolean;
  bundle: IBundle;
}

const AmountPeopleComponent = ({
  title,
  subtitle,
  price,
  count,
  increment,
  decrement,
  status,
  isError,
  errorMessage,
  isPermanentMessage,
  bundle,
}: IAmountPeopleComponent): JSX.Element => {
  const { t } = useTranslation();
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const { discount } = bundle;

  const [isButtonsAvailable, setIsButtonsAvailable] = useState<boolean>(true);

  const handleLocalIncrement = () => {
    increment();
  };

  const handleLocalDecrement = () => {
    decrement();
  };

  useEffect(() => {
    if (status === "success" || status === "idle") {
      setTimeout(() => {
        setIsButtonsAvailable(true);
      }, 0.5);
    } else {
      setIsButtonsAvailable(false);
    }
  }, [status]);

  return (
    <WrapperWithError>
      <Wrapper error={isError || false}>
        <SubTitleBox isDE={loc === "de"}>
          <SubTitle isDE={loc === "de"}>{t(title)}</SubTitle>
          <SubTitleTwo>{t(subtitle)}</SubTitleTwo>
        </SubTitleBox>
        <PriceWrapper>
          <Price isDiscount={!!discount && price !== 0} isDE={loc === "de"}>{`${
            discount ? getDiscountPrice({ price: price, percentage: discount }) : price
          } ${t("cz_currency")}`}</Price>
          {!!discount && <PriceDescription>{price}</PriceDescription>}
        </PriceWrapper>

        <Buttons>
          <ButtonDecrement
            onClick={handleLocalDecrement}
            isActive={isButtonsAvailable && count > 0}
          >
            <img src={count > 0 && isButtonsAvailable ? activeMinus : minus} alt="minus" />
          </ButtonDecrement>
          <ButtonCount>{count}</ButtonCount>
          <ButtonIncrement onClick={handleLocalIncrement} isActive={isButtonsAvailable}>
            <img src={isButtonsAvailable ? plus : unActivePlus} alt="plus" />
          </ButtonIncrement>
        </Buttons>
      </Wrapper>
      <ErrorWrapper>
        {isPermanentMessage && !isError && (
          <DescriptionMessage>{errorMessage && errorMessage}</DescriptionMessage>
        )}
        {isError && <ErrorMessage>{errorMessage && errorMessage}</ErrorMessage>}
      </ErrorWrapper>
    </WrapperWithError>
  );
};

export default AmountPeopleComponent;
