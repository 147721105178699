import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 842px;
  height: 548px;
  border-radius: 10px;
  padding: 16px;
  border: 1px solid #e1e1e1;
  background: #ffffff;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
`;

export const Pointer = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 49px;
  left: -57px;
  background: red;
  border-radius: 50%;
`;

export const Image = styled.img`
  width: 183px;
  height: 102px;
  border-radius: 10px;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000000;
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckboxTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000000;
`;

export const TicketWrapper = styled.div`
  width: 100%;
  height: 290px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const NoTicketsWrapper = styled.div`
  width: 100%;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoTicketsText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #062762;
`;

export const TicketHeader = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #000000;
  gap: 10px;
  padding-left: 20px;
`;

export const HeaderItem = styled.div`
  width: 130px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
