import React, { useState } from "react";
import {
  CheckBoxWrapper,
  Content,
  InfoContent,
  InfoSubContent,
  PayButton,
  PaymentContent,
  Wrapper,
} from "src/components/molecules/ReservationComponent/styledComponents";
import { useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import { Loc } from "src/core/types/loc";
import { SlotKey } from "src/core/types/budnles";
import TourComponent from "src/components/molecules/ReservationComponent/components/TourComponent";
import PersonComponent from "src/components/molecules/ReservationComponent/components/PersonComponent";
import TicketsReceipt from "src/components/atoms/TicketsReceipt";
import { CheckOutStep, IReservation, Tab, TourList } from "src/core/types";
import { StateValue } from "src/core/types/action";
import { usePostReservation } from "src/core/queries/usePostReservation";
import { CheckboxComponent } from "src/components/atoms/Checkbox";
import { setStepToPen } from "src/core/utils";

interface ReservationProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  tabs: Tab[];
}

const ReservationComponent = ({ updateCheckOutState, step, panels, tabs }: ReservationProps) => {
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const selectedBundle = useSelector((state: RootState) => state.bundle);
  const selectedSlots = useSelector((state: RootState) => state.timeSlot);
  const reservationID = useSelector((state: RootState) => state.reservationID?.reservationID);
  const reservationData = useSelector((state: RootState) => state.reservationData);

  const [consentOneState, setConsentOne] = useState<boolean>(false);
  const [consentTwoState, setConsentTwo] = useState<boolean>(false);

  const onConsentChange = (consentOne: boolean, consentTwo: boolean) => {
    setConsentOne(consentOne);
    setConsentTwo(consentTwo);
  };

  const goToCurrentStep = (stepKey: number) => {
    const { newTabs, newPanels } = setStepToPen({
      tabs: tabs,
      panels: panels,
      currentKey: stepKey,
    });
    updateCheckOutState(newTabs, newPanels);
  };

  //queries
  const { mutate } = usePostReservation();

  const handleSubmit = () => {
    if (
      reservationID &&
      reservationData &&
      selectedBundle &&
      reservationData.userData &&
      consentOneState &&
      consentTwoState
    ) {
      const submitData: IReservation = {
        reservation: reservationID ?? "",
        bundle: selectedBundle.name,
        name: reservationData.userData.name,
        email: reservationData.userData.email,
        phone_number: Number(reservationData.userData.phone_number),
        send_promo: false,
        order_type: "single",
      };
      mutate(submitData);
    }
  };

  const getTourTickets = (tour: SlotKey): number => {
    if (
      reservationData &&
      reservationData.selectedTickets &&
      reservationData.selectedTickets[tour]
    ) {
      return reservationData.selectedTickets[tour].reduce((acc, curr) => {
        return acc + curr.amount;
      }, 0);
    }
    return 0;
  };

  const getTourPrice = (tour: SlotKey): number => {
    let total = 0;
    if (
      reservationData &&
      reservationData.tickets &&
      reservationData.selectedTickets &&
      reservationData.selectedTickets[tour]
    ) {
      reservationData.selectedTickets[tour].forEach((ticket: StateValue) => {
        reservationData.tickets?.forEach((ticketType: TourList) => {
          if (ticket.type === ticketType.id) {
            total += Number(ticketType.price) * Number(ticket.amount);
          }
        });
      });
    }
    return total;
  };

  return (
    <Wrapper>
      {step.isEnabled && (
        <Content>
          <InfoContent>
            <InfoSubContent>
              {selectedBundle &&
                selectedBundle.tours.map((tour: SlotKey, i) => (
                  <TourComponent
                    key={i}
                    item={selectedBundle.items[i]}
                    loc={loc}
                    date={reservationData?.selectedDate || undefined}
                    slot={selectedSlots[tour]}
                    prevDateStep={() => goToCurrentStep(1)}
                    ticketAmount={getTourTickets(tour)}
                    prevTicketStep={() => goToCurrentStep(2)}
                  />
                ))}
            </InfoSubContent>
            {reservationData && reservationData.userData && (
              <InfoSubContent>
                <PersonComponent
                  person={reservationData.userData}
                  prevStep={() => goToCurrentStep(3)}
                />
              </InfoSubContent>
            )}
          </InfoContent>
          <PaymentContent>
            <CheckBoxWrapper>
              <CheckboxComponent
                onConsentChange={(consentOne, consentTwo) =>
                  onConsentChange(consentOne, consentTwo)
                }
              />
            </CheckBoxWrapper>
            <TicketsReceipt
              bundle={selectedBundle}
              getTourPrice={getTourPrice}
              isRecapPayment={true}
            />
            <PayButton isActive={consentOneState && consentTwoState} onClick={handleSubmit}>
              Zaplatit
            </PayButton>
          </PaymentContent>
        </Content>
      )}
    </Wrapper>
  );
};

export default ReservationComponent;
