import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 90px;
`;

export const MainPhoto = styled.img`
  width: 100%;
  height: 850px;
  margin-bottom: 1px;
  object-fit: cover;
  border-radius: 10px;
`;

export const PhotosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33%);
  place-items: center;
  gap: 10px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 64px;
  text-align: left;
  color: #000000;
`;

export const SubTitle = styled.span`
  font-family: Montserrat, serif;
  max-width: 1400px;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
`;
