const typography = {
  fontFamily: [
    "Montserrat",
    // Fallback font families
    "Roboto",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    '"Fira Sans"',
    '"Droid Sans"',
    "sans-serif",
  ].join(","),
  h1: {
    fontSize: "2.75rem", // 44px
    lineHeight: 1.23,
    fontWeight: 300,
  },
  h2: {
    fontSize: "1.5rem", // 24px
    lineHeight: 1.2,
    fontWeight: 500,
  },
  h3: {
    fontSize: "1.25rem", // 20px
    lineHeight: 1.2,
    fontWeight: 400,
  },
  body1: {
    fontSize: "1.125rem", // 18px
    lineHeight: 1.22,
    fontWeight: 300,
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
  },
  body2: {
    fontSize: "0.875rem", // 14px
    lineHeight: 1.29,
    fontWeight: 300,
  },
};

export default typography;
