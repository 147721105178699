import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 306px;
  object-fit: cover;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
`;

export const TextWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 184px;
    height: auto;
    border-radius: 10px;
  }
`;

export const ImageText = styled.span`
  position: absolute;
  top: 70%;
  left: 10px;
  font-family: Montserrat, serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
`;
