import React from "react";

//components
import styles from "./styles.module.css";
import { Box } from "@mui/material";
import { ButtonPurchaseTickets } from "src/components/atoms/ButtonPurchaseTickets";

const FastTicketBuy = (): JSX.Element => {
  return (
    <Box className={styles.wrapper}>
      <ButtonPurchaseTickets isMobile />
    </Box>
  );
};

export default FastTicketBuy;
