import React from "react";
import { Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NeedHelp = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap={{ xs: 2, lg: 4 }}>
      <Typography variant="h2" fontSize={{ xs: 18, lg: 22 }}>
        {t("need_help")}
      </Typography>
      <Stack direction="column" gap={2}>
        <Typography variant="body1">
          <Link
            href="https://helpdesk.visitjakub.cz/desk/form/f7e00ee4b643477ba29af76fa9b26ee9"
            color="inherit"
            sx={{ cursor: "pointer" }}
            target="_blank"
          >
            {t("media_contact")}
          </Link>
        </Typography>

        <Typography variant="body1">
          <Link
            href="https://helpdesk.visitjakub.cz/desk"
            color="inherit"
            sx={{ cursor: "pointer" }}
            target="_blank"
          >
            {t("visitor_infoservice")}
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
};
