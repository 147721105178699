import React from "react";
import {
  SectionSubTitle,
  SectionTitle,
  SectionTitleWrapper,
} from "src/components/atoms/SectionTitleComponent/styledComponents";
import { useTranslation } from "react-i18next";

const SectionTitleComponent = () => {
  const { t } = useTranslation();
  return (
    <SectionTitleWrapper>
      <SectionTitle>{t("one_church")}</SectionTitle>
      <SectionSubTitle>{t("three_experiences")}</SectionSubTitle>
    </SectionTitleWrapper>
  );
};

export default SectionTitleComponent;
