import React from "react";

const ArrowRight = ({
  color,
  size,
  rotate,
}: {
  color?: string;
  size?: { w: string; h: string };
  rotate?: string;
}): JSX.Element => {
  return (
    <svg
      width={`${size?.w || "12"}`}
      height={`${size?.h || "13"}`}
      style={{ rotate: rotate || "0deg" }}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31346 6.91167H2.625C2.51859 6.91167 2.42949 6.87577 2.3577 6.80398C2.2859 6.73219 2.25 6.64309 2.25 6.53668C2.25 6.43027 2.2859 6.34117 2.3577 6.26938C2.42949 6.19759 2.51859 6.16169 2.625 6.16169H8.31346L5.72884 3.57707C5.65448 3.50271 5.61778 3.41569 5.61875 3.31602C5.61971 3.21633 5.65897 3.12771 5.73654 3.05015C5.8141 2.97772 5.90191 2.94022 5.99999 2.93765C6.09806 2.93509 6.18588 2.97259 6.26344 3.05015L9.43362 6.22034C9.48042 6.26713 9.51343 6.31649 9.53266 6.36842C9.5519 6.42033 9.56151 6.47642 9.56151 6.53668C9.56151 6.59694 9.5519 6.65302 9.53266 6.70494C9.51343 6.75687 9.48042 6.80622 9.43362 6.85302L6.26344 10.0232C6.19421 10.0924 6.10848 10.1279 6.00624 10.1295C5.904 10.1311 5.8141 10.0956 5.73654 10.0232C5.65897 9.94565 5.62019 9.85655 5.62019 9.7559C5.62019 9.65526 5.65897 9.56616 5.73654 9.4886L8.31346 6.91167Z"
        fill={color ? color : "#E31240"}
      />
    </svg>
  );
};

export default ArrowRight;
