import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

import { StyledParagraphText } from "src/components/atoms/StyledParagraphText";
import { useTranslation } from "react-i18next";
import { StyledParagraphTextDangerous } from "src/components/atoms/StyledParagraphTextDangerous";
import SecondarySection from "src/components/molecules/SecondarySection";

import insideChurch from "../../../assets/img/guided_tours/inside_church.jpg";
import bell from "../../../assets/img/homepage/articlePhotos/bell.png";
import styles from "./styles.module.css";
import VisitConditions from "src/components/atoms/VisitConditions";
import { style } from "src/components/molecules/Header/style";
import TicketIcon from "../../../assets/tickets_white.svg";

export const GuidedTourContent = ({
  isMobile,
  isMidResolution,
}: {
  isMobile: boolean;
  isMidResolution: boolean;
}) => {
  const { t } = useTranslation();
  const tourAttractions = [
    t("tab_experience_choir_organ"),
    t("tab_experience_decks"),
    t("tab_experience_tower_view"),
  ];

  const adventTourAttractions = [
    t("tab_advent_tours_church"),
    t("tab_advent_tours_christmas_decks"),
    t("tab_advent_tours_tower"),
  ];

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      className={styles.content_wrapper}
      gap={isMobile ? 1 : 4}
      mx={isMobile ? "18px" : "auto"}
      px={isMobile ? 0 : "18px"}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        my={isMobile ? 3 : 12}
      >
        <Box className={styles.main_heading}>{t("tour_guides_article_heading")}</Box>
        <StyledParagraphText
          sx={{ fontSize: isMobile ? "1.125rem" : "1.375rem", textAlign: "center" }}
        >
          {t("tour_guides_article_subheading")}
        </StyledParagraphText>
      </Stack>

      <Stack
        direction={isMidResolution ? "column-reverse" : "row"}
        spacing={2}
        mt={isMobile ? 1 : 4}
        justifyContent="start"
      >
        <Stack
          direction="column"
          spacing={2}
          alignItems="flex-start"
          justifyContent="space-between"
          maxWidth="700px"
        >
          <Box className={styles.sub_heading}>{t("tab_experience_heading")}</Box>
          <StyledParagraphText sx={{ fontWeight: 700 }} gutterBottom>
            {t("tab_experience_subheading")}
          </StyledParagraphText>
          <StyledParagraphText gutterBottom>
            {t("tab_experience_actions_heading")}
          </StyledParagraphText>

          <Stack direction="column" sx={isMobile ? { marginLeft: "1rem !important" } : {}}>
            {tourAttractions.map((text) => (
              <Box sx={{ mb: 0.7 }} key={text}>
                <SecondarySection
                  text={text}
                  TextAsNode={<StyledParagraphTextDangerous text={text} />}
                />
              </Box>
            ))}
          </Stack>

          <Stack direction="column" gap={0.6}>
            <StyledParagraphTextDangerous text={t("tab_experience_tour_length")} />
            <StyledParagraphTextDangerous text={t("tab_experience_start")} />
          </Stack>

          <Stack direction="column" width="100%" gap={2} alignItems="center">
            <Box className={styles.call_to_action}>{t("tab_experience_call_to_action")}</Box>
            <Button
              onClick={() => {
                // TODO: Add a tour guide shop link
                // navigate(PURCHASE_WITH_GUIDE_ROUTE);
              }}
              sx={{
                ...style.primary,
              }}
            >
              <Typography style={{ fontSize: "17px", cursor: "pointer", padding: "0 8px" }}>
                {t("want_to_buy_button")}
              </Typography>
              <img alt={"ticket"} src={TicketIcon} style={{ padding: "0 8px" }} />
            </Button>
          </Stack>
        </Stack>
        <img
          src={insideChurch}
          id={styles.guided_tour_img_inside}
          className={styles.guided_tour_img}
          alt="photo from inside of the church"
        />
      </Stack>

      {/* TODO: Add a tour guide */}

      <Stack
        direction={isMobile ? "column-reverse" : "row-reverse"}
        spacing={2}
        mt={4}
        justifyContent="start"
      >
        <Stack
          direction="column"
          gap={5}
          alignItems="flex-start"
          justifyContent="start"
          maxWidth="700px"
          sx={{ ":first-of-type": { marginTop: "1.2rem" } }}
        >
          <Box className={styles.sub_heading}>{t("tab_advent_tours_heading")}</Box>
          <StyledParagraphText sx={{ fontWeight: 700 }} gutterBottom>
            {t("tab_advent_tours_subheading")}
          </StyledParagraphText>

          <Stack direction="column" gap={2}>
            <StyledParagraphText gutterBottom>
              {t("tab_advent_tours_actions_heading")}
            </StyledParagraphText>

            <Stack direction="column">
              {adventTourAttractions.map((text) => (
                <Box sx={{ mb: 0.7 }} key={text}>
                  <SecondarySection
                    text={text}
                    TextAsNode={<StyledParagraphTextDangerous text={text} />}
                  />
                </Box>
              ))}
            </Stack>
          </Stack>

          <Stack direction="column" gap={0.6}>
            <StyledParagraphTextDangerous text={t("tab_advent_tours_call_to_action")} />
            <StyledParagraphTextDangerous
              sx={{ color: "#E31340" }}
              text={t("tab_advent_tours_call_to_action_notice")}
            />
          </Stack>
        </Stack>

        <img
          src={bell}
          id={styles.guided_tour_img_bell}
          className={styles.guided_tour_img}
          alt="bell"
        />
      </Stack>
      <Box
        display="flex"
        alignItems="center"
        mt={4}
        width="100%"
        sx={{ ">div": { width: "100%" } }}
      >
        <VisitConditions />
      </Box>
    </Stack>
  );
};
