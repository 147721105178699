import React from "react";
import {
  Row,
  SelectButton,
  TimeRow,
  Wrapper,
} from "src/components/molecules/BundleComponent/components/TableTicket/styledComponents";
import { ISlot, ISlotData, SlotKey } from "src/core/types/budnles";
import { Loc } from "src/core/types/loc";

const TableTicket = ({
  slot,
  bundle,
  tour,
  selectSlot,
  loc,
  isAllowed,
}: {
  slot: ISlot;
  bundle: ISlotData;
  tour: SlotKey;
  selectSlot: (slot: ISlot, tour: SlotKey) => void;
  loc: Loc;
  isAllowed: boolean;
}) => {
  //eslint-disable-next-line  @typescript-eslint/naming-convention
  const { time, story_name, is_guided, language, capacity } = slot;
  //eslint-disable-next-line  @typescript-eslint/naming-convention
  const { can_have_exposition } = bundle;

  return (
    <Wrapper isAllowed={isAllowed}>
      <TimeRow>{time.slice(0, 5)}</TimeRow>
      {can_have_exposition && <Row>{story_name ? story_name[loc] : "Some history"}</Row>}
      <Row>{is_guided ? "Pruvodce" : "Bez pruvodce"}</Row>
      <Row>{language.toUpperCase()}</Row>
      <Row>{`${capacity} volnych mist`}</Row>
      <SelectButton isAllowed={isAllowed} onClick={() => isAllowed && selectSlot(slot, tour)}>
        Zvolit
      </SelectButton>
    </Wrapper>
  );
};

export default TableTicket;
