import React, { SetStateAction } from "react";
import { preferButtons } from "src/core/helpers/preferbuttons";

//components
import TimePreferButton from "src/components/atoms/TimePreferButton";
import styles from "./styles.module.css";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TicketFilterPayload } from "src/core/types/reduxTypes";

const PreferButtons = ({
  selectedOption,
  setSelectedOption,
}: {
  selectedOption: TicketFilterPayload;
  setSelectedOption: React.Dispatch<SetStateAction<TicketFilterPayload>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const selectOption = (id: TicketFilterPayload): void => {
    setSelectedOption(id);
  };

  return (
    <Box className={styles.wrapper}>
      {preferButtons.map((el) => (
        <TimePreferButton
          key={el.id}
          isSelected={selectedOption === el.id}
          onClick={() => selectOption(el.id)}
        >
          {t(el.text_content)}
        </TimePreferButton>
      ))}
    </Box>
  );
};

export default PreferButtons;
