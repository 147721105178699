import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ContentPicture } from "src/components/atoms/ContentPicture";
import { useFormValuesContext } from "src/core/context";
import styles from "./styles.module.css";

//assets
import mobilePhoto from "../../../assets/img/guided_tours/cover_mobile.jpg";
import desktopPhoto from "../../../assets/img/guided_tours/cover.webp";
import { visitorsCategories } from "src/core/lists";
import { GuidedTourContent } from "src/components/organisms";

export const PageGuidedTours = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMidResolution, setIsMidResolution] = useState(window.innerWidth <= 1080);
  const isApp = sessionStorage.getItem("isApp");
  const { formValues, setFormValues } = useFormValuesContext();
  const formRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
    setIsMidResolution(window.innerWidth <= 1080);
  };

  useEffect(() => {
    setFormValues({ ...formValues, finalPrice: visitorsCategories[6].price });
    sessionStorage.setItem("totalPrice", String(visitorsCategories[6].price));
  }, [formValues, setFormValues]);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const mainPhoto = isMobile ? mobilePhoto : desktopPhoto;

  const handleButtonClick = (): void => {
    if (formRef && formRef.current) {
      window.scrollTo({
        top: formRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (topRef && topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, []);

  const getCss = (): string => {
    if (isApp) {
      return styles.mob_container;
    }
    return styles.main_container;
  };

  return (
    <Box className={getCss()} ref={topRef}>
      <ContentPicture
        mainPhoto={mainPhoto}
        title={"tours_with_tower_climb"}
        subtitle={""}
        click={handleButtonClick}
        hasButton={false}
        buttonText={""}
        className={styles.header_util}
      />
      <GuidedTourContent isMobile={isMobile} isMidResolution={isMidResolution} />
    </Box>
  );
};
