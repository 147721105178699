import styled from "styled-components";

interface IWrapper {
  isActive: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  min-width: 200px;
  justify-content: space-between;
  align-items: center;
  padding: 13px 16px;
  gap: 20px;
  border-radius: 25px;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
  background: ${(props) => (props.isActive ? "#e31340" : "grey")};
`;
