import styled from "styled-components";

export const Wrapper = styled.div`
  width: 700px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SmallCircleWrapper = styled.div`
  width: 359px;
  height: 359px;
  position: absolute;
  top: 80px;
  left: 305px;

  @media (max-width: 768px) {
    top: 200px;
    left: 320px;
  }
`;
