import styled from "styled-components";

export const Wrapper = styled.div`
  width: 1360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 52px;
  border-radius: 10px;
  background: #f8f8f8;
  margin-bottom: 10px;
`;

export const ChangeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
`;

export const ChangeButtonText = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #e6264c;
`;
