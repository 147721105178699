import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LeftSideWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

export const TextWrapper = styled.div`
  display: flex;
  max-width: 900px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Image = styled.img`
  width: 400px;
  height: 290px;
  border-radius: 10px;
`;

export const ImageSideWrapper = styled.div``;

export const Text = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  white-space: break-spaces;
  font-weight: 400;
  line-height: 21.94px;
  text-align: left;
  color: #000000;
  background: #ffffff;
`;

export const Description = styled.div`
  margin-top: 20px;
  max-width: 1350px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
