import React from "react";
import { Link, Typography } from "@mui/material";
import styles from "./styles.module.css";

type TIconPrefixedContactProps = {
  iconSrc: string;
  text: string;
  linkHref: string;
  className?: string;
};

export const IconPrefixedContact = ({
  iconSrc,
  text,
  linkHref,
  className,
}: TIconPrefixedContactProps) => {
  return (
    <Link
      href={linkHref}
      target="_blank"
      rel="noreferrer"
      color="inherit"
      style={{ textDecoration: "none" }}
      className={[styles.icon_prefixed_link, className].join(" ")}
    >
      <img src={iconSrc} alt="text" style={{ width: "36px", height: "36px" }} />
      <Typography fontWeight={700} fontSize="24px">
        {text}
      </Typography>
    </Link>
  );
};
