import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const AppStoreEnBadge: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 62" fill="none">
      <g clipPath="url(#clip0_10057_61436)">
        <path
          d="M165.666 0.915967H14.3422C13.7906 0.915967 13.2456 0.915967 12.6955 0.918975C12.235 0.921984 11.7781 0.930723 11.3132 0.938079C10.3031 0.949965 9.29543 1.03883 8.29888 1.20389C7.30373 1.37254 6.33976 1.69051 5.43956 2.14703C4.54046 2.60741 3.71893 3.20563 3.00477 3.91998C2.28686 4.63233 1.68842 5.4557 1.23248 6.35844C0.775271 7.25939 0.458244 8.22484 0.292343 9.22144C0.124866 10.2168 0.0347448 11.2236 0.0227888 12.2329C0.00882972 12.6941 0.00735559 13.1568 0 13.618V48.3868C0.00735559 48.8538 0.00882972 49.3063 0.0227888 49.7735C0.0347485 50.7827 0.12487 51.7895 0.292343 52.7848C0.457785 53.782 0.774831 54.748 1.23248 55.6492C1.68821 56.5491 2.28673 57.3691 3.00477 58.0775C3.71622 58.795 4.53828 59.3936 5.43956 59.8505C6.33975 60.3082 7.30362 60.6281 8.29888 60.7994C9.2956 60.9631 10.3032 61.052 11.3132 61.0653C11.7781 61.0755 12.235 61.0814 12.6955 61.0814C13.2456 61.0844 13.7906 61.0844 14.3422 61.0844H165.666C166.207 61.0844 166.756 61.0844 167.296 61.0814C167.755 61.0814 168.225 61.0755 168.683 61.0653C169.691 61.0527 170.697 60.9638 171.692 60.7994C172.69 60.6269 173.657 60.3071 174.562 59.8505C175.462 59.3934 176.284 58.7948 176.995 58.0775C177.711 57.3663 178.311 56.5469 178.772 55.6492C179.226 54.7473 179.54 53.7814 179.703 52.7848C179.871 51.7893 179.964 50.7828 179.982 49.7735C179.988 49.3063 179.988 48.8538 179.988 48.3868C180 47.8403 180 47.2968 180 46.7415V15.2603C180 14.7095 180 14.163 179.988 13.618C179.988 13.1568 179.988 12.6941 179.982 12.2328C179.964 11.2235 179.871 10.2169 179.703 9.22138C179.54 8.22535 179.226 7.25998 178.772 6.35838C177.843 4.54893 176.371 3.07605 174.562 2.14689C173.657 1.69149 172.69 1.37361 171.692 1.20375C170.697 1.03796 169.691 0.949071 168.683 0.937868C168.225 0.930528 167.755 0.921713 167.296 0.91878C166.756 0.915771 166.207 0.915967 165.666 0.915967Z"
          fill="#A6A6A6"
        />
        <path
          d="M12.7028 59.768C12.2445 59.768 11.7973 59.7622 11.3426 59.7519C10.4006 59.7397 9.46085 59.6577 8.53099 59.5066C7.66395 59.3573 6.82402 59.0795 6.03891 58.6824C5.26099 58.2887 4.55147 57.7724 3.93752 57.1533C3.31469 56.5415 2.79615 55.8319 2.40246 55.0526C2.00443 54.2683 1.72897 53.4276 1.58567 52.5599C1.43093 51.6274 1.3472 50.6846 1.33522 49.7395C1.32569 49.4222 1.3132 48.366 1.3132 48.366V13.6178C1.3132 13.6178 1.3265 12.5778 1.3353 12.2723C1.34677 11.3287 1.43001 10.3873 1.58429 9.45628C1.72785 8.58613 2.00352 7.74301 2.40177 6.95613C2.79401 6.17742 3.30967 5.46731 3.92875 4.85332C4.54714 4.2335 5.25893 3.71452 6.03817 3.31531C6.82148 2.91953 7.65982 2.64372 8.52511 2.49711C9.45802 2.34453 10.401 2.26204 11.3463 2.25033L12.7036 2.23196H167.289L168.663 2.25107C169.599 2.2622 170.534 2.34395 171.458 2.49564C172.332 2.64408 173.179 2.92182 173.971 3.31972C175.533 4.12424 176.803 5.39707 177.604 6.9598C177.996 7.74125 178.267 8.57749 178.409 9.44013C178.565 10.3788 178.653 11.3275 178.671 12.2789C178.675 12.7049 178.675 13.1625 178.675 13.6178C178.687 14.1819 178.687 14.7188 178.687 15.2601V46.7413C178.687 47.2877 178.687 47.821 178.675 48.3586C178.675 48.8478 178.675 49.2959 178.669 49.7571C178.652 50.6914 178.566 51.6233 178.412 52.5451C178.272 53.4192 177.998 54.2664 177.6 55.0571C177.203 55.8279 176.688 56.5314 176.072 57.1415C175.458 57.7639 174.747 58.2833 173.967 58.6796C173.177 59.0797 172.331 59.3585 171.458 59.5066C170.528 59.6585 169.588 59.7405 168.646 59.7519C168.206 59.7622 167.744 59.768 167.297 59.768L165.666 59.771L12.7028 59.768Z"
          fill="black"
        />
        <path
          d="M37.2576 31.4523C37.2738 30.1968 37.6073 28.9657 38.227 27.8738C38.8468 26.7818 39.7327 25.8643 40.8024 25.2067C40.1229 24.2362 39.2264 23.4376 38.1843 22.8742C37.1421 22.3108 35.9829 21.9981 34.7988 21.9611C32.2729 21.696 29.8241 23.4726 28.5369 23.4726C27.2249 23.4726 25.2431 21.9874 23.109 22.0313C21.7286 22.0759 20.3833 22.4773 19.2042 23.1964C18.0251 23.9155 17.0524 24.9278 16.3808 26.1346C13.4716 31.1713 15.6416 38.5736 18.4283 42.6449C19.8226 44.6386 21.4521 46.8655 23.5842 46.7865C25.6706 46.7 26.4499 45.456 28.9684 45.456C31.4635 45.456 32.1946 46.7865 34.37 46.7363C36.6088 46.6999 38.0194 44.7338 39.3647 42.7213C40.3665 41.3008 41.1374 39.7308 41.6488 38.0695C40.3481 37.5194 39.2381 36.5986 38.4572 35.4218C37.6763 34.2451 37.2591 32.8645 37.2576 31.4523Z"
          fill="white"
        />
        <path
          d="M33.1487 19.2835C34.3694 17.8181 34.9708 15.9346 34.8251 14.0329C32.9602 14.2288 31.2375 15.1201 30.0003 16.5293C29.3954 17.2177 28.9321 18.0186 28.6369 18.8862C28.3417 19.7538 28.2204 20.671 28.2799 21.5855C29.2127 21.5951 30.1355 21.393 30.9789 20.9942C31.8223 20.5955 32.5641 20.0106 33.1487 19.2835Z"
          fill="white"
        />
        <path
          d="M63.6315 41.7395H56.5115L54.8016 46.7883H51.7859L58.5298 28.109H61.6631L68.4071 46.7883H65.3399L63.6315 41.7395ZM57.2489 39.4098H62.8927L60.1105 31.2159H60.0326L57.2489 39.4098Z"
          fill="white"
        />
        <path
          d="M82.9718 39.9797C82.9718 44.2118 80.7067 46.9308 77.2884 46.9308C76.4225 46.9761 75.5613 46.7766 74.8035 46.3553C74.0457 45.9339 73.4219 45.3076 73.0035 44.5481H72.9388V51.2936H70.1434V33.1696H72.8492V35.4348H72.9006C73.3383 34.6789 73.9727 34.0559 74.7362 33.632C75.4998 33.208 76.3641 32.999 77.237 33.0271C80.6934 33.0272 82.9718 35.7594 82.9718 39.9797ZM80.0985 39.9797C80.0985 37.2225 78.6736 35.4098 76.4996 35.4098C74.3637 35.4098 72.9271 37.2607 72.9271 39.9797C72.9271 42.7237 74.3637 44.5614 76.4996 44.5614C78.6736 44.5614 80.0985 42.7619 80.0985 39.9797Z"
          fill="white"
        />
        <path
          d="M97.961 39.9797C97.961 44.2118 95.6959 46.9308 92.2776 46.9308C91.4117 46.9761 90.5506 46.7766 89.7927 46.3552C89.0349 45.9339 88.4111 45.3076 87.9927 44.5481H87.928V51.2936H85.1326V33.1696H87.8384V35.4347H87.8898C88.3274 34.6789 88.9618 34.0559 89.7254 33.632C90.489 33.208 91.3532 32.999 92.2262 33.0271C95.6827 33.0271 97.961 35.7594 97.961 39.9797ZM95.0877 39.9797C95.0877 37.2225 93.6628 35.4098 91.4888 35.4098C89.3529 35.4098 87.9163 37.2607 87.9163 39.9797C87.9163 42.7237 89.3529 44.5614 91.4888 44.5614C93.6628 44.5614 95.0877 42.7619 95.0877 39.9797Z"
          fill="white"
        />
        <path
          d="M107.868 41.5838C108.075 43.4362 109.874 44.6524 112.333 44.6524C114.689 44.6524 116.385 43.4361 116.385 41.7659C116.385 40.316 115.362 39.4479 112.941 38.853L110.521 38.2698C107.091 37.4413 105.498 35.8372 105.498 33.2342C105.498 30.0113 108.307 27.7976 112.295 27.7976C116.242 27.7976 118.948 30.0113 119.039 33.2342H116.217C116.048 31.3701 114.507 30.2449 112.255 30.2449C110.003 30.2449 108.463 31.3833 108.463 33.0403C108.463 34.3609 109.447 35.138 111.854 35.7328L113.912 36.2382C117.745 37.1445 119.337 38.684 119.337 41.4162C119.337 44.9109 116.554 47.0996 112.126 47.0996C107.984 47.0996 105.187 44.9623 105.006 41.5837L107.868 41.5838Z"
          fill="white"
        />
        <path
          d="M125.37 29.9467V33.1696H127.96V35.3834H125.37V42.8912C125.37 44.0575 125.889 44.6011 127.027 44.6011C127.335 44.5957 127.642 44.5741 127.947 44.5364V46.7369C127.435 46.8325 126.915 46.8758 126.394 46.8661C123.637 46.8661 122.562 45.8305 122.562 43.1893V35.3834H120.581V33.1696H122.562V29.9467H125.37Z"
          fill="white"
        />
        <path
          d="M129.46 39.9797C129.46 35.6948 131.984 33.0022 135.919 33.0022C139.867 33.0022 142.379 35.6947 142.379 39.9797C142.379 44.2764 139.881 46.9572 135.919 46.9572C131.959 46.9572 129.46 44.2764 129.46 39.9797ZM139.531 39.9797C139.531 37.0403 138.184 35.3055 135.919 35.3055C133.654 35.3055 132.308 37.0535 132.308 39.9797C132.308 42.9308 133.654 44.6525 135.919 44.6525C138.184 44.6525 139.531 42.9308 139.531 39.9797Z"
          fill="white"
        />
        <path
          d="M144.684 33.1696H147.35V35.4876H147.415C147.595 34.7637 148.019 34.1239 148.616 33.6758C149.213 33.2277 149.945 32.9986 150.691 33.0271C151.013 33.026 151.334 33.061 151.648 33.1314V35.7462C151.242 35.6219 150.818 35.5648 150.392 35.5772C149.986 35.5608 149.581 35.6324 149.206 35.7871C148.83 35.9419 148.492 36.1761 148.215 36.4738C147.939 36.7715 147.729 37.1255 147.602 37.5116C147.475 37.8977 147.434 38.3067 147.48 38.7105V46.7883H144.684L144.684 33.1696Z"
          fill="white"
        />
        <path
          d="M164.537 42.7884C164.161 45.2606 161.753 46.9572 158.673 46.9572C154.711 46.9572 152.252 44.3028 152.252 40.0443C152.252 35.7726 154.724 33.0022 158.555 33.0022C162.323 33.0022 164.693 35.5905 164.693 39.7197V40.6775H155.074V40.8464C155.03 41.3476 155.092 41.8525 155.258 42.3276C155.424 42.8027 155.689 43.2371 156.035 43.6019C156.382 43.9668 156.802 44.2537 157.268 44.4437C157.734 44.6337 158.235 44.7224 158.738 44.7039C159.398 44.7658 160.061 44.6128 160.628 44.2676C161.194 43.9225 161.635 43.4037 161.883 42.7883L164.537 42.7884ZM155.087 38.7237H161.896C161.921 38.2731 161.853 37.8221 161.695 37.3991C161.538 36.9761 161.295 36.5902 160.981 36.2657C160.667 35.9411 160.29 35.6849 159.872 35.5131C159.455 35.3413 159.007 35.2576 158.555 35.2673C158.1 35.2646 157.649 35.352 157.228 35.5246C156.806 35.6972 156.424 35.9515 156.101 36.2729C155.779 36.5942 155.523 36.9763 155.349 37.3969C155.175 37.8176 155.086 38.2685 155.087 38.7237Z"
          fill="white"
        />
        <path
          d="M56.8986 14.049C57.4846 14.007 58.0727 14.0955 58.6204 14.3083C59.1681 14.521 59.6618 14.8527 60.0658 15.2793C60.4698 15.7059 60.7741 16.2169 60.9567 16.7754C61.1393 17.3338 61.1957 17.9259 61.1218 18.5088C61.1218 21.3762 59.5721 23.0244 56.8986 23.0244H53.6566V14.049H56.8986ZM55.0506 21.7551H56.7429C57.1616 21.7801 57.5807 21.7115 57.9696 21.5541C58.3585 21.3968 58.7074 21.1547 58.991 20.8454C59.2745 20.5362 59.4855 20.1677 59.6087 19.7666C59.7318 19.3656 59.7639 18.9421 59.7028 18.5271C59.7595 18.1137 59.7241 17.6928 59.5991 17.2947C59.474 16.8966 59.2625 16.5311 58.9796 16.2243C58.6967 15.9176 58.3495 15.6772 57.9628 15.5204C57.5761 15.3636 57.1595 15.2943 56.7429 15.3174H55.0506V21.7551Z"
          fill="white"
        />
        <path
          d="M62.6965 19.6347C62.654 19.1896 62.7049 18.7405 62.846 18.3162C62.9872 17.8919 63.2155 17.5018 63.5162 17.1709C63.817 16.84 64.1836 16.5756 64.5925 16.3947C65.0014 16.2138 65.4436 16.1203 65.8908 16.1203C66.3379 16.1203 66.7802 16.2138 67.1891 16.3947C67.598 16.5756 67.9646 16.84 68.2653 17.1709C68.5661 17.5018 68.7944 17.8919 68.9355 18.3162C69.0767 18.7405 69.1276 19.1896 69.085 19.6347C69.1284 20.0803 69.0781 20.53 68.9373 20.955C68.7965 21.38 68.5684 21.7708 68.2676 22.1023C67.9667 22.4339 67.5999 22.6988 67.1905 22.8801C66.7812 23.0615 66.3385 23.1551 65.8908 23.1551C65.4431 23.1551 65.0004 23.0615 64.591 22.8801C64.1817 22.6988 63.8148 22.4339 63.514 22.1023C63.2132 21.7708 62.985 21.38 62.8442 20.955C62.7035 20.53 62.6531 20.0803 62.6965 19.6347ZM67.7101 19.6347C67.7101 18.1665 67.0505 17.3079 65.893 17.3079C64.731 17.3079 64.0774 18.1665 64.0774 19.6347C64.0774 21.1147 64.7311 21.9667 65.893 21.9667C67.0505 21.9667 67.7101 21.1088 67.7101 19.6347Z"
          fill="white"
        />
        <path
          d="M77.5771 23.0243H76.1904L74.7905 18.0357H74.6847L73.2907 23.0243H71.9172L70.0501 16.2509H71.406L72.6194 21.4194H72.7192L74.1118 16.2509H75.3942L76.7868 21.4194H76.8925L78.1 16.2509H79.4368L77.5771 23.0243Z"
          fill="white"
        />
        <path
          d="M81.0071 16.2509H82.2939V17.3269H82.3938C82.5632 16.9405 82.849 16.6165 83.2113 16.4002C83.5737 16.1839 83.9945 16.086 84.415 16.1202C84.7446 16.0954 85.0755 16.1451 85.3833 16.2656C85.6911 16.3861 85.9678 16.5743 86.1929 16.8162C86.4181 17.0581 86.5859 17.3476 86.684 17.6633C86.7821 17.9789 86.8079 18.3125 86.7595 18.6394V23.0242H85.4228V18.9751C85.4228 17.8866 84.9498 17.3453 83.9611 17.3453C83.7374 17.3349 83.514 17.373 83.3063 17.457C83.0987 17.5409 82.9116 17.6688 82.758 17.8319C82.6044 17.9949 82.4878 18.1892 82.4163 18.4015C82.3449 18.6138 82.3201 18.839 82.3438 19.0618V23.0243H81.0071L81.0071 16.2509Z"
          fill="white"
        />
        <path d="M88.8895 13.6068H90.2262V23.0243H88.8895V13.6068Z" fill="white" />
        <path
          d="M92.0845 19.6347C92.0419 19.1896 92.0929 18.7404 92.2341 18.3161C92.3753 17.8918 92.6036 17.5017 92.9043 17.1708C93.2051 16.8399 93.5718 16.5755 93.9807 16.3946C94.3897 16.2136 94.8319 16.1202 95.2791 16.1202C95.7263 16.1202 96.1685 16.2136 96.5775 16.3946C96.9864 16.5755 97.353 16.8399 97.6538 17.1708C97.9546 17.5017 98.1829 17.8918 98.3241 18.3161C98.4653 18.7404 98.5162 19.1896 98.4737 19.6347C98.517 20.0803 98.4667 20.53 98.3258 20.955C98.185 21.38 97.9568 21.7708 97.656 22.1023C97.3551 22.4339 96.9882 22.6988 96.5789 22.8801C96.1695 23.0614 95.7268 23.1551 95.2791 23.1551C94.8314 23.1551 94.3886 23.0614 93.9793 22.8801C93.5699 22.6988 93.203 22.4339 92.9022 22.1023C92.6013 21.7708 92.3731 21.38 92.2323 20.955C92.0915 20.53 92.0411 20.0803 92.0845 19.6347ZM97.098 19.6347C97.098 18.1665 96.4384 17.3079 95.2809 17.3079C94.119 17.3079 93.4653 18.1665 93.4653 19.6347C93.4653 21.1147 94.119 21.9667 95.2809 21.9667C96.4385 21.9667 97.098 21.1088 97.098 19.6347Z"
          fill="white"
        />
        <path
          d="M99.881 21.1088C99.881 19.8896 100.789 19.1867 102.4 19.0868L104.235 18.981V18.3964C104.235 17.681 103.762 17.277 102.848 17.277C102.102 17.277 101.585 17.551 101.437 18.0299H100.142C100.279 16.8665 101.373 16.1202 102.91 16.1202C104.608 16.1202 105.566 16.9656 105.566 18.3964V23.0243H104.279V22.0724H104.173C103.959 22.4139 103.657 22.6922 103.3 22.8791C102.942 23.0659 102.542 23.1546 102.139 23.1359C101.854 23.1655 101.567 23.1352 101.295 23.0469C101.023 22.9586 100.773 22.8143 100.56 22.6233C100.347 22.4323 100.177 22.1989 100.06 21.938C99.9431 21.6772 99.8821 21.3947 99.881 21.1088ZM104.235 20.53V19.9638L102.581 20.0695C101.648 20.1319 101.225 20.4492 101.225 21.0464C101.225 21.656 101.754 22.0107 102.481 22.0107C102.694 22.0323 102.909 22.0108 103.114 21.9475C103.319 21.8842 103.508 21.7803 103.672 21.6422C103.836 21.504 103.97 21.3344 104.066 21.1433C104.163 20.9523 104.22 20.7437 104.235 20.53Z"
          fill="white"
        />
        <path
          d="M107.323 19.6347C107.323 17.4944 108.423 16.1386 110.134 16.1386C110.558 16.1191 110.978 16.2205 111.346 16.4309C111.713 16.6413 112.014 16.9521 112.211 17.3269H112.311V13.6068H113.648V23.0243H112.367V21.9541H112.261C112.048 22.3264 111.738 22.6333 111.363 22.8416C110.988 23.0499 110.563 23.1517 110.134 23.1359C108.411 23.136 107.323 21.7801 107.323 19.6347ZM108.704 19.6347C108.704 21.0713 109.381 21.9358 110.513 21.9358C111.64 21.9358 112.336 21.0589 112.336 19.6406C112.336 18.2289 111.633 17.3394 110.513 17.3394C109.388 17.3394 108.704 18.2098 108.704 19.6347Z"
          fill="white"
        />
        <path
          d="M119.179 19.6347C119.136 19.1896 119.187 18.7405 119.328 18.3162C119.469 17.8919 119.698 17.5018 119.998 17.1709C120.299 16.84 120.666 16.5756 121.075 16.3947C121.483 16.2138 121.926 16.1203 122.373 16.1203C122.82 16.1203 123.262 16.2138 123.671 16.3947C124.08 16.5756 124.447 16.84 124.747 17.1709C125.048 17.5018 125.276 17.8919 125.418 18.3162C125.559 18.7405 125.61 19.1896 125.567 19.6347C125.611 20.0803 125.56 20.53 125.419 20.955C125.279 21.38 125.05 21.7708 124.75 22.1023C124.449 22.4339 124.082 22.6988 123.673 22.8801C123.263 23.0615 122.821 23.1551 122.373 23.1551C121.925 23.1551 121.482 23.0615 121.073 22.8801C120.664 22.6988 120.297 22.4339 119.996 22.1023C119.695 21.7708 119.467 21.38 119.326 20.955C119.186 20.53 119.135 20.0803 119.179 19.6347ZM124.192 19.6347C124.192 18.1665 123.533 17.3079 122.375 17.3079C121.213 17.3079 120.559 18.1665 120.559 19.6347C120.559 21.1147 121.213 21.9667 122.375 21.9667C123.533 21.9667 124.192 21.1088 124.192 19.6347Z"
          fill="white"
        />
        <path
          d="M127.361 16.2509H128.648V17.3269H128.747C128.917 16.9405 129.203 16.6165 129.565 16.4002C129.927 16.1839 130.348 16.086 130.769 16.1202C131.098 16.0954 131.429 16.1451 131.737 16.2656C132.045 16.3861 132.321 16.5743 132.547 16.8162C132.772 17.0581 132.94 17.3476 133.038 17.6633C133.136 17.9789 133.162 18.3125 133.113 18.6394V23.0242H131.776V18.9751C131.776 17.8866 131.303 17.3453 130.315 17.3453C130.091 17.3349 129.868 17.373 129.66 17.457C129.452 17.5409 129.265 17.6688 129.112 17.8319C128.958 17.9949 128.841 18.1892 128.77 18.4015C128.698 18.6138 128.674 18.839 128.697 19.0618V23.0243H127.361V16.2509Z"
          fill="white"
        />
        <path
          d="M140.667 14.5646V16.2818H142.134V17.4077H140.667V20.8906C140.667 21.6001 140.959 21.9108 141.624 21.9108C141.795 21.9103 141.965 21.9 142.134 21.88V22.9934C141.894 23.0364 141.651 23.0592 141.407 23.0617C139.92 23.0617 139.328 22.5388 139.328 21.2329V17.4077H138.253V16.2817H139.328V14.5646H140.667Z"
          fill="white"
        />
        <path
          d="M143.96 13.6068H145.285V17.3394H145.391C145.568 16.9494 145.862 16.6236 146.232 16.4064C146.601 16.1893 147.029 16.0914 147.456 16.1261C147.784 16.1082 148.112 16.163 148.416 16.2864C148.72 16.4098 148.993 16.5988 149.216 16.84C149.439 17.0812 149.606 17.3686 149.705 17.6817C149.804 17.9947 149.832 18.3258 149.789 18.6512V23.0243H148.451V18.981C148.451 17.8991 147.947 17.3512 147.002 17.3512C146.772 17.3323 146.541 17.3639 146.325 17.4437C146.109 17.5235 145.913 17.6496 145.75 17.8132C145.588 17.9767 145.463 18.1738 145.385 18.3906C145.306 18.6075 145.276 18.8388 145.297 19.0684V23.0243H143.96L143.96 13.6068Z"
          fill="white"
        />
        <path
          d="M157.583 21.1954C157.401 21.8145 157.008 22.35 156.471 22.7082C155.935 23.0664 155.289 23.2246 154.648 23.155C154.202 23.1668 153.758 23.0813 153.348 22.9045C152.939 22.7278 152.572 22.4639 152.274 22.1312C151.977 21.7986 151.755 21.4052 151.624 20.9782C151.494 20.5513 151.458 20.1011 151.519 19.6589C151.46 19.2153 151.496 18.7642 151.626 18.336C151.757 17.9078 151.977 17.5125 152.273 17.177C152.569 16.8414 152.934 16.5734 153.343 16.3911C153.752 16.2088 154.195 16.1164 154.642 16.1202C156.527 16.1202 157.664 17.4078 157.664 19.5348V20.0012H152.881V20.0761C152.86 20.3247 152.891 20.5749 152.973 20.8107C153.054 21.0464 153.184 21.2626 153.354 21.4453C153.523 21.628 153.73 21.7732 153.959 21.8716C154.188 21.9699 154.435 22.0193 154.685 22.0165C155.005 22.0549 155.328 21.9974 155.615 21.8511C155.902 21.7049 156.139 21.4767 156.296 21.1954L157.583 21.1954ZM152.881 19.0126H156.302C156.319 18.7852 156.288 18.5569 156.211 18.3422C156.135 18.1275 156.014 17.9313 155.857 17.766C155.7 17.6008 155.51 17.4702 155.3 17.3827C155.089 17.2952 154.863 17.2527 154.635 17.2579C154.404 17.255 154.174 17.2984 153.96 17.3856C153.746 17.4727 153.551 17.6019 153.388 17.7654C153.224 17.9289 153.095 18.1235 153.008 18.3377C152.921 18.5519 152.878 18.7814 152.881 19.0126Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10057_61436">
          <rect width="180" height="62" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
