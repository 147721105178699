import React from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import error from "../../../assets/error.svg";
import payUIcon from "../../../assets/PAYU_LOGO_WHITE.png";
import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";
import style from "./styles.module.css";
import { useGetRepay } from "src/core/queries/useGetRepay";

export const PageErrorResult = () => {
  const { t } = useTranslation();
  const reservationId = sessionStorage.getItem("current_reservation_id");

  //queries

  const { refetch } = useGetRepay({ reservationId: reservationId || "" });
  const styles = {
    button: {
      backgroundColor: "#E31340",
      textDecoration: "none",
      borderRadius: "25px",
    },
  };
  const repayFunc = (): void => {
    void refetch();
  };
  return (
    <Box className={style.container}>
      <Box className={style.box}>
        <Box className={style.imageContainer}>
          <img src={mainPhoto} className={style.image} style={{ objectFit: "cover" }} />
        </Box>
        <Box className={style.overlayPic}></Box>
        <Box className={style.overlay}>
          <span className={style.overlayText}>{t("payment_error")}</span>
          <span className={style.overlayText}>{t("payment_error2")}</span>
          <img src={error} className={style.error} />
          <Button style={styles.button} className={style.button} onClick={repayFunc}>
            {t("to_pay")} <img src={payUIcon} style={{ marginLeft: "20px" }} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
