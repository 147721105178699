import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { WaitingIcon } from "src/components/icons/WaitingIcon";
import { useNavigate } from "react-router-dom";

import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";
import "./spinner.css";
import style from "./styles.module.css";

export const PageSpinner: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isFromApp = sessionStorage.getItem("isApp");
  const homeHref = isFromApp ? "VisitJakubChurch://cz.VisitJakubBrno.officialApp/home" : "/";

  const handleLinkClick = () => {
    if (isFromApp) {
      window.location.href = homeHref;
    } else {
      navigate(homeHref);
    }
  };
  return (
    <Box className={style.container}>
      <Box className={style.box}>
        <Box className={style.imageContainer}>
          <img src={mainPhoto} className={style.image} style={{ objectFit: "cover" }} alt="main" />
        </Box>
        <Box className={style.overlayPic}></Box>
        <Box className={style.overlay} alignItems={"center"}>
          <span className={style.overlayText1}>{t("waiting_for_payment1")}</span>
          <WaitingIcon />
          <span className={style.description}>{t("waiting_for_payment_desc")}</span>
          <span className={style.description}>{t("waiting_thanks")}</span>
          <a onClick={handleLinkClick} className={style.to_home}>
            {t("home")}
          </a>
        </Box>
      </Box>
    </Box>
  );
};
