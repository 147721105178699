import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./styles.module.css";
import MainImagePagination from "src/components/atoms/MainImagePagination";
import { fullPageGallery } from "src/core/helpers/fullPageGallery";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IMainPageList, MainPageList } from "src/core/helpers/MainPageList";
import MainPageListRuleItem from "src/components/atoms/MainPageListRuleItem";
import ArrowLeft from "src/components/icons/ArrowLeft";
import loadvideoimage from "../../../assets/LoadVideoImage.png";
import { ArrowWrapper } from "src/components/atoms/FullPagePagination/styledComponents";
import { ButtonPurchaseTickets } from "src/components/atoms/ButtonPurchaseTickets";

const HomePictureDesktop = (): JSX.Element => {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);

  const navigate = useNavigate();
  const nextImage = (): void => {
    setCurrentImage((prevImage) => (prevImage + 1) % fullPageGallery.length);
  };

  const prevImage = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    setCurrentImage((prevImage) => (prevImage === 0 ? fullPageGallery.length - 1 : prevImage - 1));
  };

  const handleVideoLoaded = () => {
    setTimeout(() => {
      setIsVideoLoaded(true);
    }, 1500);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.overlayPic}>
        <div className={styles.text_wrapper}>
          <ArrowWrapper active={true} onClick={prevImage}>
            <ArrowLeft color="#000" />
          </ArrowWrapper>
          <div className={styles.middle_content}>
            <div className={styles.text_inner}>{t("homepage_title")}</div>
            <div className={styles.text_description}>{t("homepage_sub_title")}</div>
            <div className={styles.list_wrapper}>
              {MainPageList.map((el: IMainPageList, index) => (
                <MainPageListRuleItem key={index} icon={el.icon} text={el.text} />
              ))}
            </div>
            <div className={styles.primary}>
              <ButtonPurchaseTickets />
            </div>
          </div>
          <ArrowWrapper active={true} onClick={nextImage}>
            <ArrowLeft color="#000" rotate={"0deg"} />
          </ArrowWrapper>
        </div>
        <AnimatePresence mode={"wait"}>
          <motion.div
            key={currentImage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ opacity: { duration: 1 }, ease: "easeInOut" }}
            className={styles.imageWrapper}
          >
            {currentImage === 0 ? (
              <>
                {!isVideoLoaded && (
                  <img src={loadvideoimage} className={styles.image} alt="Loading..." />
                )}
                <video
                  loop
                  muted
                  autoPlay
                  className={styles.image}
                  onLoadedData={handleVideoLoaded}
                  style={{ display: isVideoLoaded ? "block" : "none" }}
                >
                  <source src={fullPageGallery[currentImage]} />
                </video>
              </>
            ) : (
              <img
                src={fullPageGallery[currentImage]}
                className={styles.image}
                alt={`Slide ${currentImage}`}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
      <MainImagePagination currentImage={currentImage} fullPageGallery={fullPageGallery} />
    </div>
  );
};

export default HomePictureDesktop;
