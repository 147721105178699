import React from "react";

export const SmallCircle = ({ isMobile }: { isMobile: boolean }) => (
  <svg
    width={`${isMobile ? "178" : "359"}`}
    height={`${isMobile ? "178" : "359"}`}
    viewBox="0 0 359 359"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="179.5" cy="179.5" r="179" stroke="#E31340" strokeOpacity="0.7" />
  </svg>
);
