import React from "react";
import {
  PanelWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "src/components/pages/PageSetTypeOfTicket/components/PanelRow/styledComponent";
import Panel from "src/components/pages/PageSetTypeOfTicket/components/Panel";
import { IBundle } from "src/core/types/budnles";
import { Loc } from "src/core/types/loc";

interface PanelRowProps {
  title: string;
  panels: IBundle[];
  loc: Loc;
}

const PanelRow: React.FC<PanelRowProps> = ({ title, panels, loc }) => (
  <Wrapper>
    <TitleWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
    <PanelWrapper>
      {panels.map((panel: IBundle) => (
        <Panel key={panel.name} bundle={panel} loc={loc} />
      ))}
    </PanelWrapper>
  </Wrapper>
);

export default PanelRow;
