import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import personIcon from "../../../assets/kontaktni_udaje_cervene.svg";
import { styles } from "./styles";
import arrowDown from "../../../assets/arrow_white.svg";
import { useFormValuesContext } from "src/core/context";
import { Controller, useForm } from "react-hook-form";
import styless from "./style.module.css";
import checkmarkIcon from "../../../assets/checkmark (1).svg";
import call from "../../../assets/call.svg";
import mail from "../../../assets/mail (2).svg";
import osoba from "../../../assets/osoba_cerna.svg";
import errorIcon from "../../../assets/error (1).svg";
import officeIcon from "../../../assets/office_icon.svg";
import { GroupCheckBox } from "../GroupCheckBox";
import { useDispatch } from "react-redux";
import { selectForm } from "src/core/redux/actions/travelActions";
import { CheckOutStep, Tab } from "src/core/types";
import { setToNextStep } from "src/core/utils";

interface ITravelForm {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  tabs: Tab[];
  ref?: React.Ref<HTMLDivElement>;
}

const TravelForm = forwardRef<HTMLDivElement, ITravelForm>(
  ({ updateCheckOutState, step, panels, tabs }, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { formValues, setFormValues } = useFormValuesContext();

    const ticketsForm = useForm({
      defaultValues: {
        name: formValues.name,
        agency_name: formValues.agency_name,
        phone_number: formValues.phone_number,
        email: formValues.email,
        validEmail: formValues.validEmail,
        amount: 0,
      },
      reValidateMode: "onChange",
      mode: "all",
    });

    const penClick = () => {
      const { newTabs, newPanels } = setToNextStep({
        tabs: tabs,
        panels: panels,
        currentKey: Number(step.key),
      });
      updateCheckOutState(newTabs, newPanels);
    };

    const {
      control,
      getValues,
      formState: { isValid },
    } = ticketsForm;
    const handleContinue = (formData: {
      name: string;
      phone_number: string | number;
      email: string;
      validEmail: string;
      agency_name: string;
      amount: any;
    }) => {
      const newFormValues = { ...formValues, ...formData };
      dispatch(
        selectForm({
          name: formData.name,
          agency_name: formData.agency_name,
          email: formData.email,
          phone_number: formData.phone_number,
        }),
      );
      setFormValues(newFormValues);
      setFormValues(newFormValues);
      penClick();
    };

    // CheckboxComponent
    const [consentOneChecked, setConsentOneChecked] = useState(false);

    const handleConsentChange = (consentOne: boolean) => {
      sessionStorage.setItem("consent", String(consentOneChecked));
      setConsentOneChecked(consentOne);
    };

    useEffect(() => {
      const isConsent = sessionStorage.getItem("consent");
      if (isConsent) {
        setConsentOneChecked(true);
      }
    }, [panels, step]);

    return step.isEnabled ? (
      <Box className={styless.container} ref={ref}>
        <Box className={styless.wrapper}>
          <form onSubmit={ticketsForm.handleSubmit(handleContinue)}>
            <Box className={styless.box}>
              <span style={styles.title}>
                <img src={personIcon} style={{ marginRight: "8px" }} />
                <span>{t("contant_button")}</span>
              </span>
              <Box className={styless.innerBoxRow}>
                <Box className={styless.innerBox}>
                  <Box className={styless.textfieldRow}>
                    <Box className={styless.textfieldBox}>
                      <img src={officeIcon} />
                      <FormControl
                        className={styless.textField}
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        <InputLabel
                          htmlFor="name_surname"
                          sx={{
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-focused": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-error": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#E31340",
                            },
                          }}
                        >
                          {t("agency_name")}
                        </InputLabel>
                        <Controller
                          name="agency_name"
                          control={control}
                          rules={{
                            validate: (v) => {
                              return (
                                /^[A-Za-z\sěščřžýá]+$/.test(v) || t("fill_in_tour_agency_name")
                              );
                            },
                            required: t("fill_in_tour_agency_name"),
                          }}
                          render={({ field, fieldState: { isTouched, error } }) => (
                            <React.Fragment>
                              <OutlinedInput
                                id="agency_name"
                                inputProps={{ maxLength: 50 }}
                                value={field.value}
                                onChange={field.onChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {error ? (
                                      <img src={errorIcon} style={{ width: "16px" }} />
                                    ) : isTouched && !error ? (
                                      <img src={checkmarkIcon} style={{ width: "16px" }} />
                                    ) : null}
                                  </InputAdornment>
                                }
                                error={!!error}
                                label={t("agency_name")}
                                sx={{
                                  "& input": {
                                    color: error ? "#E31340" : "#000000",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#79747E",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#062762",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #000000",
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }}
                              />
                              {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </React.Fragment>
                          )}
                        />
                      </FormControl>
                    </Box>
                    <Box className={styless.textfieldBox}>
                      <img src={osoba} />
                      <FormControl
                        className={styless.textField}
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        <InputLabel
                          htmlFor="name_surname"
                          sx={{
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-focused": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-error": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#E31340",
                            },
                          }}
                        >
                          {t("name_surname")}
                        </InputLabel>
                        <Controller
                          name="name"
                          control={control}
                          rules={{
                            validate: (v) => {
                              return (
                                /^[A-Za-z\sěščřžýá]+$/.test(v) || t("fill_in_tour_agency_name")
                              );
                            },
                            required: t("fill_in_name_and_surname"),
                          }}
                          render={({ field, fieldState: { isTouched, error } }) => (
                            <React.Fragment>
                              <OutlinedInput
                                id="name_surname"
                                value={field.value}
                                onChange={field.onChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {error ? (
                                      <img src={errorIcon} style={{ width: "16px" }} />
                                    ) : isTouched && !error ? (
                                      <img src={checkmarkIcon} style={{ width: "16px" }} />
                                    ) : null}
                                  </InputAdornment>
                                }
                                error={!!error}
                                label={t("name_surname")}
                                sx={{
                                  "& input": {
                                    color: error ? "#E31340" : "#000000",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#79747E",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#062762",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #000000",
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }}
                              />
                              {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </React.Fragment>
                          )}
                        />
                      </FormControl>
                    </Box>
                    <Box className={styless.textfieldBox}>
                      <img src={call} />
                      <FormControl
                        className={styless.textField}
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        <InputLabel
                          sx={{
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-focused": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-error": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#E31340",
                            },
                          }}
                          htmlFor="phone_number"
                        >
                          {t("phone_number")}
                        </InputLabel>
                        <Controller
                          name="phone_number"
                          control={control}
                          rules={{
                            validate: (value) => {
                              const isValidNumber =
                                /^\+?\d{1,4}?[\s.-]?\(?\d{1,4}?\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/.test(
                                  String(value),
                                );
                              return isValidNumber || t("fill_in_valid_phone_number");
                            },
                            required: t("fill_in_valid_phone_number"),
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { isTouched, error },
                          }) => (
                            <React.Fragment>
                              <OutlinedInput
                                id="phone_number"
                                label={t("phone_number")}
                                value={value}
                                onChange={onChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {error ? (
                                      <img src={errorIcon} style={{ width: "16px" }} />
                                    ) : isTouched && !error ? (
                                      <img src={checkmarkIcon} style={{ width: "16px" }} />
                                    ) : null}
                                  </InputAdornment>
                                }
                                error={!!error}
                                sx={{
                                  "& input": {
                                    color: error ? "#E31340" : "#000000",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#79747E",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#062762",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #000000",
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }}
                              />
                              {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </React.Fragment>
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className={styless.textfieldRow}>
                    <Box className={styless.textfieldBox}>
                      <img src={mail} />
                      <FormControl
                        className={styless.textField}
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        <InputLabel
                          sx={{
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-focused": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-error": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#E31340",
                            },
                          }}
                          htmlFor="email_address"
                        >
                          {t("email_address")}
                        </InputLabel>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: t("fill_in_valid_email_address"),
                            validate: (v) =>
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                              t("fill_in_valid_email_address"),
                          }}
                          render={({ field, fieldState: { isTouched, error } }) => (
                            <React.Fragment>
                              <OutlinedInput
                                id="email"
                                value={field.value}
                                onChange={field.onChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {error ? (
                                      <img src={errorIcon} style={{ width: "16px" }} />
                                    ) : isTouched && !error ? (
                                      <img src={checkmarkIcon} style={{ width: "16px" }} />
                                    ) : null}
                                  </InputAdornment>
                                }
                                error={!!error}
                                label={t("email_address")}
                                sx={{
                                  "& input": {
                                    color: error ? "#E31340" : "#000000",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#79747E",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#062762",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #000000",
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }}
                              />
                              {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </React.Fragment>
                          )}
                        />
                      </FormControl>
                    </Box>
                    <Box className={styless.textfieldBox}>
                      <img src={mail} />
                      <FormControl
                        className={styless.textField}
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        <InputLabel
                          sx={{
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-focused": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#000000",
                            },
                            "&.Mui-error": {
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#E31340",
                            },
                          }}
                          htmlFor="email_address_confirmation"
                        >
                          {t("email_address_confirmation")}
                        </InputLabel>
                        <Controller
                          name="validEmail"
                          control={control}
                          rules={{
                            required: t("fill_in_valid_email_address"),
                            validate: (value) =>
                              value === getValues("email") || t("fill_in_valid_email_address"),
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { isTouched, error },
                          }) => (
                            <React.Fragment>
                              <OutlinedInput
                                id="email_address_confirmation"
                                label={t("email_address_confirmation")}
                                value={value}
                                onChange={onChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {error ? (
                                      <img src={errorIcon} style={{ width: "16px" }} />
                                    ) : isTouched && !error ? (
                                      <img src={checkmarkIcon} style={{ width: "16px" }} />
                                    ) : null}
                                  </InputAdornment>
                                }
                                error={!!error}
                                sx={{
                                  "& input": {
                                    color: error ? "#E31340" : "#000000",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#79747E",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? "#E31340" : "#062762",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #000000",
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }}
                              />
                              {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </React.Fragment>
                          )}
                        />
                      </FormControl>
                    </Box>
                    <Button
                      disabled={!isValid || !consentOneChecked}
                      type="submit"
                      className={styless.buttonToGo}
                      sx={{
                        ...styles.buttonToGo,
                        backgroundColor: "#E31340",
                        "&:hover": {
                          backgroundColor: "#E31340",
                          color: "white",
                        },
                        "&:disabled": {
                          backgroundColor: "grey",
                          color: "white",
                        },
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <span style={{ marginRight: "5px" }}>{t("recap_and_payment")}</span>
                      <img src={arrowDown} />
                    </Button>
                  </Box>
                </Box>
                <Box className={styless.footerItems}>
                  <GroupCheckBox
                    isConsent={consentOneChecked}
                    onConsentChange={handleConsentChange}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    ) : (
      <div></div>
    );
  },
);
TravelForm.displayName = "TravelForm";
export default TravelForm;
