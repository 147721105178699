import React from "react";

export const BigCircle = ({ isMobile }: { isMobile: boolean }) => (
  <svg
    width={`${isMobile ? "353" : "713"}`}
    height={`${isMobile ? "353" : "713"}`}
    viewBox="0 0 713 713"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="356.5" cy="356.5" r="356" stroke="#E31340" strokeOpacity="0.7" />
  </svg>
);
