import {
  Image,
  MainPhoto,
  PhotosWrapper,
  Wrapper,
  TitleDescription,
  TitleWrapper,
  SubTitle,
} from "src/components/atoms/MainPageGalleryMobile/styledComponents";
import SmallImage from "src/components/atoms/MainPageGalleryDesktop/components/SmallImage";
import mainPhoto from "../../../assets/img/homepage/jacob_palace.jpg";
import photoInside from "../../../assets/img/homepage/sidePhotos/jacob_palace_inside.jpg";
import photoWindows from "../../../assets/img/homepage/sidePhotos/jacob_palace_windows.jpg";
import photoStatues from "../../../assets/img/homepage/sidePhotos/jacob_palace_pillar_front.jpg";
import { useTranslation } from "react-i18next";

const MainPageGalleryMobile = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TitleWrapper>
        <TitleDescription>{t("discover_church")}</TitleDescription>
        <SubTitle dangerouslySetInnerHTML={{ __html: t("discover_church_sub") }} />
      </TitleWrapper>
      <MainPhoto src={mainPhoto} />
      <PhotosWrapper>
        <SmallImage text={""} image={photoInside} />
        <SmallImage text={""} image={photoWindows} />
      </PhotosWrapper>
      <Image src={photoStatues} />
    </Wrapper>
  );
};

export default MainPageGalleryMobile;
