import React from "react";

import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import Section from "src/components/molecules/Section";
import SecondarySection from "src/components/molecules/SecondarySection";
import { StyledParagraphText } from "src/components/atoms/StyledParagraphText";
import { StyledParagraphTextDangerous } from "src/components/atoms/StyledParagraphTextDangerous";

export const JacobsTowerContent = () => {
  const { t } = useTranslation();
  const visitAttractions = [
    t("tab_tower_activities_bells"),
    t("tab_tower_activities_clock_mechanism"),
    t("tab_tower_activities_panorama_views"),
  ];

  const importantVisitInformation = [
    t("tab_tower_information_difficulty"),
    t("tab_tower_information_abilities"),
    t("tab_tower_information_safety"),
  ];

  return (
    <Stack direction="column" mb={2}>
      <StyledParagraphText gutterBottom sx={{ fontWeight: 700 }}>
        {t("tab_tower_sub_title_bold")}
      </StyledParagraphText>
      <StyledParagraphText sx={{ mb: 2 }}>{t("tab_tower_visit")}</StyledParagraphText>
      <StyledParagraphText gutterBottom>{t("tab_tower_activities_heading")}</StyledParagraphText>
      {visitAttractions.map((text) => (
        <Box sx={{ mb: 0.7 }} key={text}>
          <Section text={text} TextAsNode={<StyledParagraphTextDangerous text={text} />} />
        </Box>
      ))}
      <StyledParagraphText gutterBottom sx={{ mt: 2, fontWeight: 700, fontSize: 16 }}>
        {t("tab_tower_information_heading")}
      </StyledParagraphText>
      {importantVisitInformation.map((text) => (
        <Box key={text}>
          <SecondarySection
            text={text}
            TextAsNode={<StyledParagraphTextDangerous text={text} sx={{ fontSize: 16 }} />}
          />
        </Box>
      ))}
    </Stack>
  );
};
