import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const AppStoreDeBadge: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 60" fill="none">
      <g clipPath="url(#clip0_10057_61438)">
        <path
          d="M165.666 -0.084033H14.3422C13.7906 -0.084033 13.2456 -0.084033 12.6955 -0.0810245C12.235 -0.0780161 11.7781 -0.0692767 11.3132 -0.0619211C10.3031 -0.0500351 9.29543 0.0388254 8.29888 0.203888C7.30373 0.372544 6.33976 0.690509 5.43956 1.14703C4.54046 1.60741 3.71893 2.20563 3.00477 2.91998C2.28686 3.63233 1.68842 4.4557 1.23248 5.35844C0.775271 6.25939 0.458244 7.22484 0.292343 8.22144C0.124866 9.21678 0.0347448 10.2236 0.0227888 11.2329C0.00882972 11.6941 0.00735559 12.1568 0 12.618V47.3868C0.00735559 47.8538 0.00882972 48.3063 0.0227888 48.7735C0.0347485 49.7827 0.12487 50.7895 0.292343 51.7848C0.457785 52.782 0.774831 53.748 1.23248 54.6492C1.68821 55.5491 2.28673 56.3691 3.00477 57.0775C3.71622 57.795 4.53828 58.3936 5.43956 58.8505C6.33975 59.3082 7.30362 59.6281 8.29888 59.7994C9.2956 59.9631 10.3032 60.052 11.3132 60.0653C11.7781 60.0755 12.235 60.0814 12.6955 60.0814C13.2456 60.0844 13.7906 60.0844 14.3422 60.0844H165.666C166.207 60.0844 166.756 60.0844 167.296 60.0814C167.755 60.0814 168.225 60.0755 168.683 60.0653C169.691 60.0527 170.697 59.9638 171.692 59.7994C172.69 59.6269 173.657 59.3071 174.562 58.8505C175.462 58.3934 176.284 57.7948 176.995 57.0775C177.711 56.3663 178.311 55.5469 178.772 54.6492C179.226 53.7473 179.54 52.7814 179.703 51.7848C179.871 50.7893 179.964 49.7828 179.982 48.7735C179.988 48.3063 179.988 47.8538 179.988 47.3868C180 46.8403 180 46.2968 180 45.7415V14.2603C180 13.7095 180 13.163 179.988 12.618C179.988 12.1568 179.988 11.6941 179.982 11.2328C179.964 10.2235 179.871 9.21686 179.703 8.22138C179.54 7.22535 179.226 6.25998 178.772 5.35838C177.843 3.54893 176.371 2.07605 174.562 1.14689C173.657 0.691489 172.69 0.373609 171.692 0.203753C170.697 0.0379627 169.691 -0.0509286 168.683 -0.0621317C168.225 -0.0694722 167.755 -0.0782869 167.296 -0.0812201C166.756 -0.0842285 166.207 -0.084033 165.666 -0.084033Z"
          fill="#A6A6A6"
        />
        <path
          d="M12.7028 58.768C12.2445 58.768 11.7973 58.7622 11.3425 58.7519C10.4006 58.7397 9.46082 58.6577 8.53096 58.5066C7.66392 58.3573 6.82399 58.0795 6.03888 57.6824C5.26096 57.2887 4.55144 56.7724 3.93749 56.1533C3.31466 55.5415 2.79612 54.8319 2.40243 54.0526C2.0044 53.2683 1.72894 52.4276 1.58564 51.5599C1.4309 50.6274 1.34717 49.6846 1.33519 48.7395C1.32566 48.4222 1.31317 47.366 1.31317 47.366V12.6178C1.31317 12.6178 1.32647 11.5778 1.33527 11.2723C1.34674 10.3287 1.42998 9.38727 1.58426 8.45628C1.72782 7.58613 2.00349 6.74301 2.40174 5.95613C2.79398 5.17742 3.30964 4.46731 3.92872 3.85332C4.54711 3.2335 5.2589 2.71452 6.03814 2.31531C6.82145 1.91953 7.65979 1.64372 8.52508 1.49711C9.45799 1.34453 10.401 1.26204 11.3462 1.25033L12.7035 1.23196H167.289L168.663 1.25107C169.599 1.2622 170.534 1.34395 171.458 1.49564C172.332 1.64408 173.179 1.92182 173.971 2.31972C175.533 3.12424 176.803 4.39707 177.604 5.9598C177.996 6.74125 178.267 7.57749 178.409 8.44013C178.565 9.37876 178.653 10.3275 178.671 11.2789C178.675 11.7049 178.675 12.1625 178.675 12.6178C178.687 13.1819 178.687 13.7188 178.687 14.2601V45.7413C178.687 46.2877 178.687 46.821 178.675 47.3586C178.675 47.8478 178.675 48.2959 178.669 48.7571C178.652 49.6914 178.566 50.6233 178.412 51.5451C178.272 52.4192 177.998 53.2664 177.6 54.0571C177.203 54.8279 176.688 55.5314 176.072 56.1415C175.458 56.7639 174.747 57.2833 173.967 57.6796C173.177 58.0797 172.331 58.3585 171.458 58.5066C170.528 58.6585 169.588 58.7405 168.646 58.7519C168.206 58.7622 167.744 58.768 167.296 58.768L165.666 58.771L12.7028 58.768Z"
          fill="black"
        />
        <path
          d="M37.2576 30.4523C37.2738 29.1968 37.6073 27.9657 38.227 26.8738C38.8468 25.7818 39.7327 24.8643 40.8024 24.2067C40.1229 23.2362 39.2264 22.4376 38.1843 21.8742C37.1421 21.3108 35.9829 20.9981 34.7988 20.9611C32.2729 20.696 29.8241 22.4726 28.5369 22.4726C27.2249 22.4726 25.2431 20.9874 23.109 21.0313C21.7286 21.0759 20.3833 21.4773 19.2042 22.1964C18.0251 22.9155 17.0524 23.9278 16.3808 25.1346C13.4716 30.1713 15.6416 37.5736 18.4283 41.6449C19.8226 43.6386 21.4521 45.8655 23.5842 45.7865C25.6706 45.7 26.4499 44.456 28.9684 44.456C31.4635 44.456 32.1946 45.7865 34.37 45.7363C36.6088 45.6999 38.0194 43.7338 39.3647 41.7213C40.3665 40.3008 41.1374 38.7308 41.6488 37.0695C40.3481 36.5194 39.2381 35.5986 38.4572 34.4218C37.6763 33.2451 37.2591 31.8645 37.2576 30.4523Z"
          fill="white"
        />
        <path
          d="M33.1487 18.2835C34.3694 16.8181 34.9708 14.9346 34.8251 13.0329C32.9602 13.2288 31.2375 14.1201 30.0003 15.5293C29.3954 16.2177 28.9321 17.0186 28.6369 17.8862C28.3417 18.7538 28.2204 19.671 28.2799 20.5855C29.2127 20.5951 30.1355 20.393 30.9789 19.9942C31.8223 19.5955 32.5641 19.0106 33.1487 18.2835Z"
          fill="white"
        />
        <path
          d="M63.6315 40.7395H56.5115L54.8016 45.7883H51.7859L58.5298 27.109H61.6631L68.4071 45.7883H65.3399L63.6315 40.7395ZM57.2489 38.4098H62.8927L60.1105 30.2159H60.0326L57.2489 38.4098Z"
          fill="white"
        />
        <path
          d="M82.9718 38.9797C82.9718 43.2118 80.7067 45.9308 77.2884 45.9308C76.4225 45.9761 75.5614 45.7766 74.8035 45.3553C74.0457 44.9339 73.4219 44.3076 73.0035 43.5481H72.9388V50.2936H70.1434V32.1696H72.8492V34.4348H72.9006C73.3383 33.6789 73.9727 33.0559 74.7363 32.632C75.4998 32.208 76.3641 31.999 77.237 32.0271C80.6934 32.0272 82.9718 34.7594 82.9718 38.9797ZM80.0985 38.9797C80.0985 36.2225 78.6736 34.4098 76.4996 34.4098C74.3637 34.4098 72.9271 36.2607 72.9271 38.9797C72.9271 41.7237 74.3637 43.5614 76.4996 43.5614C78.6736 43.5614 80.0985 41.7619 80.0985 38.9797Z"
          fill="white"
        />
        <path
          d="M97.961 38.9797C97.961 43.2118 95.6959 45.9308 92.2776 45.9308C91.4117 45.9761 90.5506 45.7766 89.7927 45.3552C89.0349 44.9339 88.4111 44.3076 87.9927 43.5481H87.928V50.2936H85.1326V32.1696H87.8384V34.4347H87.8898C88.3274 33.6789 88.9618 33.0559 89.7254 32.632C90.489 32.208 91.3532 31.999 92.2262 32.0271C95.6827 32.0271 97.961 34.7594 97.961 38.9797ZM95.0877 38.9797C95.0877 36.2225 93.6628 34.4098 91.4888 34.4098C89.3529 34.4098 87.9163 36.2607 87.9163 38.9797C87.9163 41.7237 89.3529 43.5614 91.4888 43.5614C93.6628 43.5614 95.0877 41.7619 95.0877 38.9797Z"
          fill="white"
        />
        <path
          d="M107.868 40.5838C108.075 42.4362 109.874 43.6524 112.333 43.6524C114.69 43.6524 116.385 42.4361 116.385 40.7659C116.385 39.316 115.362 38.4479 112.941 37.853L110.521 37.2698C107.091 36.4413 105.498 34.8372 105.498 32.2342C105.498 29.0113 108.307 26.7976 112.295 26.7976C116.242 26.7976 118.948 29.0113 119.039 32.2342H116.217C116.048 30.3701 114.507 29.2449 112.255 29.2449C110.004 29.2449 108.463 30.3833 108.463 32.0403C108.463 33.3609 109.447 34.138 111.854 34.7328L113.912 35.2382C117.745 36.1445 119.337 37.684 119.337 40.4162C119.337 43.9109 116.554 46.0996 112.126 46.0996C107.984 46.0996 105.187 43.9623 105.006 40.5837L107.868 40.5838Z"
          fill="white"
        />
        <path
          d="M125.37 28.9467V32.1696H127.96V34.3834H125.37V41.8912C125.37 43.0575 125.889 43.6011 127.027 43.6011C127.335 43.5957 127.642 43.5741 127.947 43.5364V45.7369C127.435 45.8325 126.915 45.8758 126.394 45.8661C123.637 45.8661 122.562 44.8305 122.562 42.1893V34.3834H120.581V32.1696H122.562V28.9467H125.37Z"
          fill="white"
        />
        <path
          d="M129.46 38.9797C129.46 34.6948 131.984 32.0022 135.919 32.0022C139.867 32.0022 142.379 34.6947 142.379 38.9797C142.379 43.2764 139.881 45.9572 135.919 45.9572C131.959 45.9572 129.46 43.2764 129.46 38.9797ZM139.531 38.9797C139.531 36.0403 138.184 34.3055 135.919 34.3055C133.654 34.3055 132.308 36.0535 132.308 38.9797C132.308 41.9308 133.654 43.6525 135.919 43.6525C138.184 43.6525 139.531 41.9308 139.531 38.9797Z"
          fill="white"
        />
        <path
          d="M144.684 32.1696H147.35V34.4876H147.415C147.595 33.7637 148.019 33.1239 148.616 32.6758C149.213 32.2277 149.945 31.9986 150.691 32.0271C151.013 32.026 151.334 32.061 151.648 32.1314V34.7462C151.242 34.6219 150.818 34.5648 150.392 34.5772C149.986 34.5608 149.581 34.6324 149.206 34.7871C148.83 34.9419 148.492 35.1761 148.215 35.4738C147.938 35.7715 147.729 36.1255 147.602 36.5116C147.475 36.8977 147.434 37.3067 147.48 37.7105V45.7883H144.684L144.684 32.1696Z"
          fill="white"
        />
        <path
          d="M164.537 41.7884C164.161 44.2606 161.753 45.9572 158.673 45.9572C154.711 45.9572 152.252 43.3028 152.252 39.0443C152.252 34.7726 154.724 32.0022 158.555 32.0022C162.323 32.0022 164.693 34.5905 164.693 38.7197V39.6775H155.074V39.8464C155.03 40.3476 155.092 40.8525 155.258 41.3276C155.424 41.8027 155.689 42.2371 156.035 42.6019C156.382 42.9668 156.802 43.2537 157.268 43.4437C157.734 43.6337 158.235 43.7224 158.738 43.7039C159.398 43.7658 160.061 43.6128 160.628 43.2676C161.194 42.9225 161.635 42.4037 161.883 41.7883L164.537 41.7884ZM155.087 37.7237H161.896C161.921 37.2731 161.853 36.8221 161.695 36.3991C161.538 35.9761 161.295 35.5902 160.981 35.2657C160.667 34.9411 160.29 34.6849 159.872 34.5131C159.455 34.3413 159.007 34.2576 158.555 34.2673C158.1 34.2646 157.649 34.352 157.228 34.5246C156.806 34.6972 156.424 34.9515 156.101 35.2729C155.779 35.5942 155.523 35.9763 155.349 36.3969C155.175 36.8176 155.086 37.2685 155.087 37.7237Z"
          fill="white"
        />
        <path d="M59.2548 22.0243H53.6566V13.049H55.0506V20.7426H59.2548V22.0243Z" fill="white" />
        <path
          d="M60.6635 20.1088C60.6635 18.8895 61.5713 18.1866 63.1828 18.0868L65.0175 17.981V17.3964C65.0175 16.681 64.5445 16.277 63.6308 16.277C62.8846 16.277 62.3675 16.551 62.2191 17.0299H60.925C61.0616 15.8664 62.156 15.1202 63.6925 15.1202C65.3906 15.1202 66.3484 15.9656 66.3484 17.3964V22.0243H65.0616V21.0724H64.9558C64.7411 21.4139 64.4397 21.6922 64.0823 21.8791C63.7248 22.0659 63.3242 22.1545 62.9213 22.1359C62.637 22.1655 62.3496 22.1351 62.0777 22.0468C61.8058 21.9585 61.5554 21.8143 61.3427 21.6233C61.1299 21.4323 60.9596 21.1988 60.8426 20.938C60.7256 20.6771 60.6646 20.3947 60.6635 20.1088ZM65.0175 19.53V18.9637L63.3634 19.0695C62.4306 19.1319 62.0075 19.4492 62.0075 20.0463C62.0075 20.6559 62.5364 21.0107 63.2636 21.0107C63.4766 21.0323 63.6919 21.0108 63.8964 20.9475C64.101 20.8842 64.2908 20.7803 64.4544 20.6422C64.6181 20.504 64.7523 20.3344 64.849 20.1433C64.9457 19.9522 65.003 19.7437 65.0175 19.53Z"
          fill="white"
        />
        <path
          d="M68.1052 18.6347C68.1052 16.4944 69.2055 15.1386 70.9168 15.1386C71.3401 15.1191 71.7602 15.2205 72.128 15.4309C72.4959 15.6413 72.7962 15.9521 72.9939 16.3269H73.0938V12.6068H74.4305V22.0243H73.1496V20.9541H73.0438C72.8308 21.3264 72.5201 21.6333 72.1451 21.8416C71.7702 22.0499 71.3454 22.1517 70.9168 22.1359C69.1937 22.136 68.1052 20.7801 68.1052 18.6347ZM69.4861 18.6347C69.4861 20.0713 70.1633 20.9358 71.2958 20.9358C72.4225 20.9358 73.1188 20.0589 73.1188 18.6406C73.1188 17.2289 72.4152 16.3394 71.2958 16.3394C70.1706 16.3394 69.4861 17.2098 69.4861 18.6347Z"
          fill="white"
        />
        <path
          d="M82.3512 20.1954C82.1695 20.8145 81.776 21.35 81.2395 21.7082C80.703 22.0664 80.0576 22.2246 79.4162 22.155C78.9699 22.1668 78.5265 22.0813 78.1166 21.9045C77.7067 21.7278 77.3402 21.4639 77.0425 21.1312C76.7448 20.7986 76.523 20.4052 76.3926 19.9782C76.2622 19.5513 76.2263 19.1011 76.2873 18.6589C76.2279 18.2153 76.2645 17.7642 76.3946 17.336C76.5247 16.9078 76.7452 16.5125 77.0414 16.177C77.3375 15.8414 77.7023 15.5734 78.111 15.3911C78.5197 15.2088 78.9628 15.1164 79.4103 15.1202C81.295 15.1202 82.4319 16.4078 82.4319 18.5348V19.0012H77.649V19.0761C77.6281 19.3247 77.6593 19.5749 77.7408 19.8107C77.8222 20.0464 77.952 20.2626 78.1218 20.4453C78.2916 20.628 78.4978 20.7732 78.727 20.8716C78.9562 20.9699 79.2035 21.0193 79.4529 21.0165C79.7727 21.0549 80.0966 20.9974 80.3836 20.8511C80.6705 20.7049 80.9075 20.4767 81.0644 20.1954L82.3512 20.1954ZM77.649 18.0126H81.0702C81.087 17.7852 81.0561 17.5569 80.9795 17.3422C80.9029 17.1275 80.7821 16.9313 80.6251 16.766C80.4681 16.6008 80.2783 16.4702 80.0678 16.3827C79.8573 16.2952 79.6308 16.2527 79.4029 16.2579C79.1717 16.255 78.9423 16.2984 78.7281 16.3856C78.514 16.4727 78.3194 16.6019 78.156 16.7654C77.9925 16.9289 77.8635 17.1235 77.7764 17.3377C77.6893 17.5519 77.646 17.7814 77.649 18.0126Z"
          fill="white"
        />
        <path
          d="M84.227 15.2509H85.5138V16.3269H85.6137C85.7831 15.9405 86.0689 15.6165 86.4312 15.4002C86.7936 15.1839 87.2143 15.086 87.6349 15.1202C87.9645 15.0954 88.2954 15.1451 88.6032 15.2656C88.911 15.3861 89.1877 15.5743 89.4128 15.8162C89.638 16.0581 89.8058 16.3476 89.9039 16.6633C90.002 16.9789 90.0278 17.3125 89.9794 17.6394V22.0242H88.6426V17.9751C88.6426 16.8866 88.1696 16.3453 87.181 16.3453C86.9573 16.3349 86.7339 16.373 86.5262 16.457C86.3186 16.5409 86.1315 16.6688 85.9779 16.8319C85.8243 16.9949 85.7077 17.1892 85.6362 17.4015C85.5647 17.6138 85.54 17.839 85.5637 18.0618V22.0243H84.227L84.227 15.2509Z"
          fill="white"
        />
        <path
          d="M95.5468 13.248C95.5365 13.0749 95.5784 12.9026 95.6671 12.7536C95.7558 12.6046 95.8872 12.4856 96.0443 12.4121C96.2014 12.3386 96.377 12.314 96.5483 12.3414C96.7195 12.3689 96.8786 12.4471 97.0049 12.566C97.1312 12.6849 97.2188 12.8389 97.2565 13.0082C97.2943 13.1775 97.2803 13.3542 97.2164 13.5155C97.1525 13.6767 97.0417 13.8151 96.8983 13.9126C96.7549 14.0102 96.5855 14.0624 96.412 14.0625C96.3012 14.0682 96.1903 14.0514 96.0861 14.0131C95.9819 13.9748 95.8865 13.9158 95.8057 13.8398C95.7249 13.7637 95.6603 13.672 95.6158 13.5703C95.5713 13.4686 95.5478 13.359 95.5468 13.248ZM95.7466 15.2509H97.0774V22.0243H95.7466V15.2509Z"
          fill="white"
        />
        <path
          d="M99.2346 15.2509H100.521V16.3394H100.621C100.782 15.9765 101.045 15.6682 101.378 15.4518C101.711 15.2354 102.099 15.1202 102.496 15.1202C102.893 15.1202 103.282 15.2354 103.615 15.4518C103.948 15.6682 104.211 15.9765 104.372 16.3394H104.477C104.666 15.9592 104.961 15.6418 105.327 15.4254C105.692 15.2091 106.112 15.1031 106.537 15.1202C106.834 15.0959 107.132 15.1377 107.411 15.2428C107.69 15.3478 107.941 15.5133 108.148 15.7275C108.355 15.9416 108.512 16.199 108.608 16.4811C108.703 16.7632 108.735 17.063 108.701 17.3589V22.0243H107.364V17.7078C107.364 16.7933 106.928 16.3453 106.051 16.3453C105.862 16.3372 105.673 16.3683 105.497 16.4365C105.32 16.5048 105.16 16.6088 105.026 16.742C104.892 16.8752 104.786 17.0347 104.717 17.2105C104.647 17.3863 104.615 17.5746 104.621 17.7636V22.0243H103.308V17.577C103.317 17.4066 103.288 17.2364 103.224 17.0781C103.161 16.9198 103.063 16.7772 102.939 16.6601C102.815 16.5431 102.667 16.4543 102.505 16.3999C102.344 16.3456 102.172 16.3269 102.002 16.3453C101.806 16.3485 101.613 16.3913 101.434 16.471C101.255 16.5507 101.094 16.6657 100.96 16.8092C100.826 16.9526 100.723 17.1216 100.656 17.3059C100.59 17.4902 100.561 17.6861 100.572 17.8818V22.0243H99.2348L99.2346 15.2509Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10057_61438">
          <rect width="180" height="60" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
