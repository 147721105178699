import { useQuery } from "react-query";
import axios from "axios";
import { BUNDLES } from "../endpoints";
import { IBundle } from "src/core/types/budnles";

export const useGetBundles = () => {
  const fetchBundles: () => Promise<IBundle[]> = async () => {
    const res = await axios.get(`${window.BASE_API_URL}/${BUNDLES}`);
    return res.data.bundles;
  };

  return useQuery(`bundles`, fetchBundles, {
    enabled: true,
    refetchOnWindowFocus: false,
  });
};
