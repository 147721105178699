import styled from "styled-components";

interface IBudnlesProps {
  isSelectedTwo: boolean;
}

export const Wrapper = styled.div`
  width: 1360px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  border-radius: 10px;
  background: #f8f8f8;
`;

export const CalendarContainer = styled.div`
  width: 350px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BundlesWrapper = styled.div<IBudnlesProps>`
  position: relative;
  width: 100%;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: ${(props) => (props.isSelectedTwo ? "0" : "30px")};
`;

export const Checked = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 73px;
  left: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #58bc51;
  z-index: 4;
`;

export const ButtonWrapper = styled.div`
  width: 220px;
  margin: auto 15% 15px 85%;
  cursor: pointer;
`;
