import React from "react";
import {
  Wrapper,
  ChangeButton,
  ChangeButtonText,
} from "src/components/molecules/PenComponent/styledComponents";

import { useTranslation } from "react-i18next";

const PenComponent = ({
  children,
  prevStep,
}: {
  children: React.ReactNode;
  prevStep: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {children}
      <ChangeButton onClick={prevStep}>
        <ChangeButtonText>{t("to_change")}</ChangeButtonText>
      </ChangeButton>
    </Wrapper>
  );
};

export default PenComponent;
