import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const JKTrustInfo = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap={{ xs: 2, lg: 4 }}>
      <Typography variant="h2" fontSize={{ xs: 20, lg: 26 }} fontWeight={{ xs: 400, lg: 700 }}>
        {t("footer_title")}
      </Typography>
      <Stack direction="column">
        <Typography variant="body1">{t("footer_address_1")}</Typography>
        <Typography variant="body1">{t("footer_address_2")}</Typography>
      </Stack>
      <Stack direction="column">
        <Typography variant="body1">{t("footer_ico")}</Typography>
        <Typography variant="body1">{t("footer_dic")}</Typography>
      </Stack>
    </Stack>
  );
};
