import React from "react";
import { Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { styles } from "./styles";

export const Legal = ({ setOpenModal }: { setOpenModal: (open: boolean) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack
      direction={{ xs: "column-reverse", lg: "row" }}
      gap={{ xs: 3, lg: 0 }}
      justifyContent="space-between"
    >
      <Typography variant="body2">{t("rights_reserved")}</Typography>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        gap={{ xs: 1, lg: 5 }}
        justifyContent={{ xs: "flex-start", lg: "flex-end" }}
        sx={styles.linksWrapper}
      >
        <Typography variant="body2">
          <Link
            color="inherit"
            target="_blank"
            href="https://docs.google.com/document/d/1LnHoNo55yx8gXgawdA9cq-gku8rHzbbpeEiye-0WbQc/edit#heading=h.4iw50apfwhgj"
          >
            {t("operating_rules")}
          </Link>
        </Typography>
        <Typography variant="body2" onClick={() => setOpenModal(true)}>
          {[t("cookies")]}
        </Typography>
        <Typography variant="body2" onClick={() => navigate("/privacy-policy")}>
          {[t("personal_info")]}
        </Typography>

        <Typography variant="body2">
          <Link
            href="https://docs.google.com/document/d/1nAL-uK1yiYK_QTyyxRDXfOo6ZrevjXBPLOUYQ6SEiw8/edit?usp=sharing"
            target="_blank"
            rel="noreferrer"
            color="inherit"
          >
            {[t("conditions")]}
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
};
