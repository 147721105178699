import React from "react";
import {
  MainPhoto,
  PhotosWrapper,
  SubTitle,
  Title,
  TitleWrapper,
  Wrapper,
} from "src/components/atoms/MainPageGalleryDesktop/styledComponents";

import mainPhoto from "../../../assets/img/homepage/jacob_palace.jpg";
import photoInside from "../../../assets/img/homepage/sidePhotos/jacob_palace_inside.jpg";
import photoWindows from "../../../assets/img/homepage/sidePhotos/jacob_palace_windows.jpg";
import photoStatues from "../../../assets/img/homepage/sidePhotos/jacob_palace_pillar_front.jpg";
import SmallImage from "src/components/atoms/MainPageGalleryDesktop/components/SmallImage";
import { useTranslation } from "react-i18next";

const MainPageGalleryDesktop = () => {
  const { t } = useTranslation();
  const sidePhotos = [photoInside, photoWindows, photoStatues];
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t("discover_church")}</Title>
        <SubTitle dangerouslySetInnerHTML={{ __html: t("discover_church_sub") }} />
      </TitleWrapper>
      <MainPhoto src={mainPhoto} />
      <PhotosWrapper>
        {sidePhotos.map((photo) => (
          <SmallImage key={photo} text={""} image={photo} />
        ))}
      </PhotosWrapper>
    </Wrapper>
  );
};

export default MainPageGalleryDesktop;
