import styled from "styled-components";

interface IWrapper {
  flexDirection: "row" | "row-reverse" | "column";
}

interface IImage {
  isMobile: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 150px;
`;

export const Wrapper = styled.div<IWrapper>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => props.flexDirection};
  align-items: flex-start;
  gap: 35px;

  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const Image = styled.img<IImage>`
  width: ${(props) => (props.isMobile ? "100%" : "650px")};
  height: ${(props) => (props.isMobile ? "auto" : "480px")};
  border-radius: 12px;
  object-fit: cover;
`;

export const TextWrapper = styled.div`
  display: flex;
  min-height: 540px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #000;
  gap: 20px;

  @media (max-width: 1450px) {
    min-height: 465px;
  }

  @media (max-width: 768px) {
    max-width: 650px;
    min-height: 0;
  }
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 52px;
  font-weight: 700;
  text-align: left;
  white-space: pre-wrap;
  color: #000;

  @media (max-width: 768px) {
    font-family: Montserrat, serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 34.13px;
    text-align: left;
  }
`;

export const PreWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  flex-grow: 1;

  @media (max-width: 768px) {
    justify-content: flex-start;
    max-height: none;
    height: auto;
    gap: 20px;
  }
`;

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5px;
  gap: 5px;

  @media (max-width: 768px) {
    gap: 7px;
  }

  @media (max-width: 400px) {
    gap: 7px;
  }
`;

export const SubTitle = styled.span`
  width: 100%;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26.82px;
  text-align: left;
  color: #000;
  background: #ffffff;

  @media (max-width: 768px) {
    width: 400px;
    font-family: Montserrat, serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
    margin-bottom: 10px;
  }

  @media (max-width: 400px) {
    width: 340px;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 16px;
`;

export const SectionText = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.94px;
  text-align: left;
  color: #000;
  background: #ffffff;

  @media (max-width: 768px) {
    font-family: Montserrat, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
  }
`;

export const Circle = styled.div`
  width: 12px;
  height: 12px;
  background: #e31340;
  border-radius: 50%;
`;

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  background: #000000;
  border-radius: 50%;
  margin-top: 10px;
`;

export const SeeMoreWrapper = styled.div`
  width: 100%;
  min-width: 669px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #b9b9b9;
  cursor: pointer;
  z-index: 4;

  @media (max-width: 768px) {
    width: 92vw;
    min-width: 0;
  }
`;

export const SeeMoreText = styled.span`
  color: #1d1b20;
  font-family: Montserrat, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ImageWrapper = styled.div`
  width: 650px;
  height: 100%;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CircleWrapper = styled.div`
  position: absolute;
  top: -135px;
  left: 90px;
  z-index: -1;

  @media (max-width: 768px) {
    top: -230px;
    left: -130px;
  }
`;

export const SectionsOnly = styled.div`
  width: 100%;
  min-height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    min-height: 0;
  }
`;
