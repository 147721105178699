import React from "react";
import {
  Wrapper,
  Image,
  ImageText,
  TextWrapper,
} from "src/components/atoms/MainPageGalleryDesktop/components/SmallImage/styledComponents";

const SmallImage = ({ image, text }: { image: string; text: string }) => (
  <Wrapper>
    <Image src={image} alt="" />
    <TextWrapper>
      <ImageText>{text}</ImageText>
    </TextWrapper>
  </Wrapper>
);

export default SmallImage;
