export const guidedHeader = [
  {
    id: "time",
    label: "time",
  },
  {
    id: "story_name",
    label: "history",
  },
  {
    id: "is_guided",
    label: "guide",
  },
  {
    id: "language",
    label: "language",
  },
  {
    id: "capacity",
    label: "capacity",
  },
];

export const unGuidedHeader = [
  {
    id: "time",
    label: "time",
  },
  {
    id: "is_guided",
    label: "guide",
  },
  {
    id: "language",
    label: "language",
  },
  {
    id: "capacity",
    label: "capacity",
  },
];
