import React from "react";
import {
  InfoWrapper,
  KeyText,
  ValueText,
  Wrapper,
} from "src/components/molecules/ReservationComponent/components/PersonComponent/styledComponents";
import ChangeComponent from "src/components/molecules/ReservationComponent/components/ChangeComponent";
import { IPerson } from "src/core/types/personType";

const PersonComponent = ({ person, prevStep }: { person: IPerson; prevStep: () => void }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { name, email, phone_number } = person;
  return (
    <Wrapper>
      <ChangeComponent type={"Kontaktni udaje"} prevStep={prevStep} />
      <InfoWrapper>
        <KeyText>Jmeno</KeyText>
        <ValueText>{name}</ValueText>
      </InfoWrapper>
      <InfoWrapper>
        <KeyText>Email</KeyText>
        <ValueText>{email}</ValueText>
      </InfoWrapper>
      <InfoWrapper>
        <KeyText>Telefonni cislo</KeyText>
        <ValueText>{phone_number}</ValueText>
      </InfoWrapper>
    </Wrapper>
  );
};

export default PersonComponent;
