import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import iconButton from "../../../assets/formIcon.svg";

export const ButtonFillOutForm = () => {
  const { t } = useTranslation();
  const style = {
    buttonToBuy: {
      minWidth: "200px",
      minHeight: "50px",
      height: "50px",
      padding: "5px 20px !important",
      flexShrink: 0,
      backgroundColor: "#E31340",
      color: "white",
      display: "flex",
      justifyContent: "center",
      borderRadius: "25px",
    },
  };
  return (
    <Button
      sx={{
        ...style.buttonToBuy,
        "&:hover": {
          backgroundColor: "#E31340",
          color: "white",
        },
      }}
      style={{ textDecoration: "none" }}
      onClick={() =>
        open("https://helpdesk.visitjakub.cz/desk/form/346e03a8e7924444935876343c668696")
      }
    >
      {t("group_form")} <img alt="img" src={iconButton} style={{ marginLeft: "20px" }} />
    </Button>
  );
};
