import React from "react";

import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { StyledParagraphText } from "src/components/atoms/StyledParagraphText";
import { StyledParagraphTextDangerous } from "src/components/atoms/StyledParagraphTextDangerous";
import SecondarySection from "src/components/molecules/SecondarySection";

import styles from "./styles.module.css";

import bell from "../../../assets/img/homepage/articlePhotos/bell.png";

export const JacobsTowerContentMoreInfo = () => {
  const { t } = useTranslation();
  const towerInfo = [
    t("tab_tower_more_info_build_history"),
    t("tab_tower_more_info_style"),
    t("tab_tower_more_info_height"),
    t("tab_tower_more_info_reparations"),
    t("tab_tower_more_info_curiosity"),
  ];

  return (
    <Box className={styles.jacobs_bell_content_wrapper}>
      <img src={bell} alt="kostnice" className={styles.img_bell} />
      <Stack
        direction="column"
        justifyContent="space-between"
        className={styles.tower_bell_text_content}
      >
        <Box>
          <StyledParagraphText sx={{ mb: 2 }}>
            {t("tab_tower_more_info_heading")}
          </StyledParagraphText>

          {towerInfo.map((text) => (
            <Box className={styles.secondary_section_item} key={`towerInfo ${text}`}>
              <SecondarySection
                text={text}
                TextAsNode={<StyledParagraphTextDangerous text={text} />}
              />
            </Box>
          ))}
        </Box>
        <StyledParagraphText sx={{ color: "#062762", whiteSpace: "pre-line" }}>
          {t("tab_tower_more_info_notice")}
        </StyledParagraphText>
      </Stack>
    </Box>
  );
};
