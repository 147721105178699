import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BadgesComponent from "src/components/molecules/BadgesComponent";

export const DownloadApp = () => {
  const { t } = useTranslation();
  const [isSmallerThanLg, setIsSmallerThanLg] = React.useState(window.innerWidth <= 1200);

  const updateMedia = (): void => {
    setIsSmallerThanLg(window.innerWidth <= 1200);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <Stack
      direction="column"
      alignItems={{ xs: "start", lg: "end" }}
      gap={2}
      sx={{ maxWidth: "610px" }}
    >
      <Typography variant="h1" fontSize={{ xs: 20, lg: 26 }}>
        {t("download_application")}
      </Typography>
      <Typography
        fontSize={20}
        textAlign={{ xs: "start", lg: "end" }}
        dangerouslySetInnerHTML={{ __html: t("download_application1") }}
      />
      <BadgesComponent justifyContent={isSmallerThanLg ? "flex-start" : "flex-end"} />
    </Stack>
  );
};
