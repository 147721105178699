import styled from "styled-components";

export const PanelWrapper = styled.div`
  width: 1360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 52px;
  border-radius: 10px;
  background: #f8f8f8;
  margin-bottom: 10px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const ChangeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
`;

export const ChangeButtonText = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #e6264c;
`;

export const BundleName = styled.span`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #051c4766;
`;

export const Description = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #051c4766;
`;

export const BlockWithIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const BlockWithIconText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #051c4766;
`;

export const SlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 13px;
`;
