import { CounterState } from "src/core/types/action";
import { TourList } from "core/types";

export const setSelectedTickets = (tickets: CounterState) => ({
  type: "SET_SELECTED_TICKETS",
  payload: tickets,
});

export const setSelectedDate = (date: Date) => ({
  type: "SET_SELECTED_DATE",
  payload: date,
});

export const setUserData = (userData: {
  name: string;
  email: string;
  phone_number: string | number;
}) => ({
  type: "SET_USER_DATA",
  payload: userData,
});

export const setTickets = (tickets: TourList[]) => ({
  type: "SET_TICKETS",
  payload: tickets,
});
