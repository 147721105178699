import React, { useState } from "react";
import {
  CheckboxTitle,
  CheckboxWrapper,
  Filters,
  HeaderItem,
  HeaderWrapper,
  Image,
  LoadingWrapper,
  NoTicketsText,
  NoTicketsWrapper,
  Pointer,
  TicketHeader,
  TicketWrapper,
  Title,
  Wrapper,
} from "src/components/molecules/BundleComponent/components/styledComponents";

import photo from "../../../assets/2023_11_12_sv. Jakub_vizualizace_prechod2.jpg";
import PreferButtons from "src/components/molecules/PreferButtons";
import { Checkbox, CircularProgress } from "@mui/material";
import TableTicket from "src/components/molecules/BundleComponent/components/TableTicket";
import { IPrioritySlot, ISlot, ISlotData, SlotKey, SlotState } from "src/core/types/budnles";
import { TicketFilterPayload } from "src/core/types/reduxTypes";
import { Loc } from "src/core/types/loc";
import { guidedHeader, unGuidedHeader } from "src/core/utils/ticketsHeader";
import { filterTimeSlots } from "src/core/utils/filterTimeSlots";
import SelectedBundle from "src/components/molecules/BundleComponent/components/SelectedBundle";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";

const fadeInVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

const BundleComponent = ({
  bundle,
  loc,
  tour,
  isSelected,
  selectSlot,
  selectedSlot,
  deleteSlot,
  selectedSlots,
  is_alone,
  i,
}: {
  bundle: ISlotData | undefined;
  loc: Loc;
  tour: SlotKey;
  selectedSlots: SlotState;
  isSelected: boolean;
  selectSlot: (slot: ISlot, tour: SlotKey) => void;
  selectedSlot: IPrioritySlot;
  deleteSlot: (tour: SlotKey) => void;
  is_alone: boolean;
  i: number;
}) => {
  const selectedBundle = useSelector((state: RootState) => state.bundle);
  const headerArray = bundle?.can_have_exposition ? guidedHeader : unGuidedHeader;
  const [checkBoxState, setCheckBoxState] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<TicketFilterPayload>("everytime");

  const filterByGuide = (slots: ISlot[]) => {
    if (checkBoxState) {
      return slots.filter((slot: ISlot) => slot.is_guided);
    }
    return slots;
  };

  const isBundleAvailable = (): boolean => {
    if (selectedBundle && selectedBundle.items.length < 2) return true;

    if (selectedSlots) {
      const slots = (Object.keys(selectedSlots) as SlotKey[])?.map((_tour: SlotKey) => {
        if (selectedSlots[_tour]) {
          return selectedSlots[_tour];
        }
      });

      if (!slots || slots.length === 0) {
        return i === 0;
      }

      const firstSlot = slots?.find(
        (slot) => slot && slot.priority > (selectedSlot?.priority ?? 0),
      );
      return !!firstSlot;
    }
    return false;
  };

  return (
    <AnimatePresence mode="wait">
      {isSelected ? (
        <motion.div
          key="selected"
          layout
          onClick={isBundleAvailable}
          variants={fadeInVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.3 }}
        >
          <SelectedBundle
            slot={selectedSlot}
            tour={tour}
            is_alone={is_alone}
            bundle={bundle || undefined}
            deleteSlot={deleteSlot}
            loc={loc}
          />
        </motion.div>
      ) : (
        <motion.div
          key="unselected"
          layout
          variants={fadeInVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.3 }}
        >
          <Wrapper>
            {!bundle ? (
              <LoadingWrapper>
                <CircularProgress color={"error"} />
              </LoadingWrapper>
            ) : (
              <>
                <HeaderWrapper>
                  <Image src={photo} />
                  <Pointer />
                  <Title>{bundle.title[loc] || bundle.title.cs || ""}</Title>
                </HeaderWrapper>
                {filterByGuide(filterTimeSlots({ slots: bundle.slots, filter: selectedFilter }))
                  .length > 0 ? (
                  <>
                    <Filters>
                      <PreferButtons
                        setSelectedOption={setSelectedFilter}
                        selectedOption={selectedFilter}
                      />
                      {bundle && bundle.can_be_guided && (
                        <CheckboxWrapper>
                          <Checkbox
                            onChange={(_, state) => setCheckBoxState(state)}
                            checked={checkBoxState}
                          />
                          <CheckboxTitle>S pruvodcem</CheckboxTitle>
                        </CheckboxWrapper>
                      )}
                    </Filters>
                    <TicketHeader>
                      {headerArray.map((element) => (
                        <HeaderItem key={element.id}>{element.label}</HeaderItem>
                      ))}
                    </TicketHeader>
                    <TicketWrapper>
                      {bundle &&
                        filterByGuide(
                          filterTimeSlots({ slots: bundle.slots, filter: selectedFilter }),
                        ).map((slot: ISlot) => (
                          <TableTicket
                            key={slot.id}
                            slot={slot}
                            bundle={bundle}
                            tour={tour}
                            loc={loc}
                            selectSlot={selectSlot}
                            isAllowed={isBundleAvailable()}
                          />
                        ))}
                    </TicketWrapper>
                  </>
                ) : (
                  <NoTicketsWrapper>
                    <NoTicketsText>
                      Litujeme, pro vybraný den již nejsou dostupné žádné volné časové sloty.
                      <br />
                      Prosím, vyberte si jiný termín.
                    </NoTicketsText>
                  </NoTicketsWrapper>
                )}
              </>
            )}
          </Wrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BundleComponent;
