import React from "react";

import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { StyledParagraphText } from "src/components/atoms/StyledParagraphText";
import { StyledParagraphTextDangerous } from "src/components/atoms/StyledParagraphTextDangerous";
import SecondarySection from "src/components/molecules/SecondarySection";

export const MorgueContentMoreInfo = () => {
  const { t } = useTranslation();
  const towerInfo = [
    t("tab_morgue_more_info_founding_history"),
    t("tab_morgue_more_info_epidemic"),
    t("tab_morgue_more_info_oblivion_discovery"),
    t("tab_morgue_more_info_present"),
  ];

  return (
    <Stack direction="column" justifyContent="space-between" sx={{ py: 4 }} maxWidth={1350}>
      <StyledParagraphText sx={{ mb: 2 }}>{t("tab_morgue_more_info_heading")}</StyledParagraphText>

      {towerInfo.map((text) => (
        <Box sx={{ mb: 3, ml: 2, whiteSpace: "pre-line" }} key={`towerInfo ${text}`}>
          <SecondarySection text={text} TextAsNode={<StyledParagraphTextDangerous text={text} />} />
        </Box>
      ))}
    </Stack>
  );
};
