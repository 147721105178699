import { STOP_RESERVATION_API_ROUTE } from "../endpoints";
import { useQuery } from "react-query";
import axios from "axios";
import { Timer } from "../types/timer";
import { useDispatch } from "react-redux";
import { refreshReservationId } from "src/core/redux/actions/reservationActions";
import { setCapacity } from "src/core/redux/actions/capacityAction";

export const useGetRemoveReservation = ({ reservationId }: { reservationId: string | null }) => {
  const dispatch = useDispatch();
  const fetchStopReservation = (): Promise<Timer> => {
    return axios
      .get(`${window.BASE_API_URL}/${STOP_RESERVATION_API_ROUTE}/${reservationId}`)
      .then((response) => {
        dispatch(refreshReservationId());
        dispatch(setCapacity({}));
        return response.data;
      });
  };

  return useQuery<Timer, Error>(`fetchStopReservation-${reservationId}`, fetchStopReservation, {
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: false,
  });
};
