import { TicketFilterPayload } from "src/core/types/reduxTypes";
import { ISlot } from "src/core/types/budnles";

export const filterTimeSlots = ({
  slots,
  filter,
}: {
  slots: ISlot[];
  filter: TicketFilterPayload;
}) => {
  switch (filter) {
    case "evening":
      return slots.filter((slot: ISlot) => parseInt(slot.time.slice(0, 2)) >= 12);
    case "morning":
      return slots.filter((slot: ISlot) => {
        const hour = parseInt(slot.time.slice(0, 2));
        const minute = parseInt(slot.time.slice(3, 5));
        return hour < 12 || (hour === 12 && minute === 0);
      });
    default:
      return slots;
  }
};
