import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { headerRoutes } from "src/core/utils";
import { ButtonPurchaseTickets } from "src/components/atoms/ButtonPurchaseTickets";
import MobileMenu from "src/components/atoms/MobileMenu";

import styles from "./styles.module.css";
import { style } from "./style";

import { Logo } from "../../atoms/Logo";

const HomeHeader: React.FC<{
  VisitClick?: () => void;
  purchaseTicketsClick?: () => void;
}> = ({ purchaseTicketsClick, VisitClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateTo = (route: string): void => {
    navigate(route);
  };

  return (
    <AppBar color="inherit">
      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
            <Logo />
            <div className={styles.desktop_only}>
              <div className={styles.route_wrapper}>
                <div className={styles.routes}>
                  {headerRoutes.map((route) => (
                    <Button
                      key={route.path}
                      onClick={() => {
                        navigateTo(route.path);
                      }}
                    >
                      <Typography style={style.buttonText}>{t(route.label)}</Typography>
                    </Button>
                  ))}
                </div>
              </div>
              {/* notice: add back when functionality implemented */}
              {/*<Button variant="outlined">{t("gift_cards")}</Button>*/}
            </div>
          </Box>
          <div className={styles.desktop_only}>
            <ButtonPurchaseTickets />
            <Button
              variant="outlined"
              onClick={() => {
                open("https://helpdesk.visitjakub.cz/desk");
              }}
            >
              {t("visitor_infoservice")}
            </Button>
            {/* notice: add back when functionality implemented */}
            {/*<div className={styles.cart_container}>*/}
            {/*  <CartIcon />*/}
            {/*</div>*/}
          </div>
          <div className={styles.mobile_only}>
            <MobileMenu VisitClick={VisitClick} onButtonsClick={purchaseTicketsClick} />
          </div>
        </div>
      </div>
    </AppBar>
  );
};
export default HomeHeader;
