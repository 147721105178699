import React, { useEffect, useRef, useState } from "react";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import { FAQ } from "src/core/helpers/FAQ";
import ToggleComponent from "src/components/atoms/ToggleComponent";
import SubPageHeader from "src/components/atoms/SubPageHeader";
import DidNotFound from "src/components/atoms/DidNotFound";
import OrComponent from "src/components/atoms/OrComponent";
import FAQForm from "src/components/atoms/FAQForm";
import { useTranslation } from "react-i18next";

const PageFAQ = (): JSX.Element => {
  const { t } = useTranslation();
  const topRef = useRef<any>(null);

  const [isMobileSmall, setIsMobileSmall] = useState<boolean>(window.innerWidth <= 400);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileSmall(window.innerWidth <= 400);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (topRef) {
      window.scrollTo({ top: topRef.current.offsetTop - 155, behavior: "smooth" });
    }
  }, [topRef]);

  return (
    <Box className={styles.wrapper} ref={topRef}>
      <SubPageHeader pageName={`${isMobileSmall ? t("faq_head_mob") : t("faq_head")}`} />
      {FAQ.map((el) => (
        <ToggleComponent key={el.header} headerText={t(el.header)}>
          {typeof el.content === "string" ? (
            <div
              style={{ marginTop: "20px" }}
              dangerouslySetInnerHTML={{ __html: t(el.content) }}
            />
          ) : (
            el.content
          )}
        </ToggleComponent>
      ))}
      <DidNotFound />
      <OrComponent />
      <FAQForm />
    </Box>
  );
};

export default PageFAQ;
