import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const AppStoreCzBadge: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 60" fill="none">
      <g clipPath="url(#clip0_10057_61437)">
        <path
          d="M165.666 0.000195547H14.3422C13.7906 0.000195547 13.2456 0.000195547 12.6955 0.00320397C12.235 0.00621239 11.7781 0.0149519 11.3132 0.0223074C10.3031 0.0341934 9.29543 0.123054 8.29888 0.288117C7.30373 0.456773 6.33976 0.774737 5.43956 1.23126C4.54046 1.69164 3.71893 2.28986 3.00477 3.00421C2.28686 3.71655 1.68842 4.53993 1.23248 5.44267C0.775271 6.34362 0.458244 7.30906 0.292343 8.30567C0.124866 9.30101 0.0347448 10.3078 0.0227888 11.3171C0.00882972 11.7783 0.00735559 12.241 0 12.7023V47.471C0.00735559 47.938 0.00882972 48.3905 0.0227888 48.8577C0.0347485 49.8669 0.12487 50.8737 0.292343 51.869C0.457785 52.8662 0.774831 53.8322 1.23248 54.7335C1.68821 55.6333 2.28673 56.4533 3.00477 57.1617C3.71622 57.8792 4.53828 58.4779 5.43956 58.9347C6.33975 59.3925 7.30362 59.7123 8.29888 59.8836C9.2956 60.0473 10.3032 60.1362 11.3132 60.1495C11.7781 60.1598 12.235 60.1656 12.6955 60.1656C13.2456 60.1686 13.7906 60.1686 14.3422 60.1686H165.666C166.207 60.1686 166.756 60.1686 167.296 60.1656C167.755 60.1656 168.225 60.1598 168.683 60.1495C169.691 60.1369 170.697 60.048 171.692 59.8836C172.69 59.7111 173.657 59.3913 174.562 58.9347C175.462 58.4776 176.284 57.879 176.995 57.1617C177.711 56.4506 178.311 55.6311 178.772 54.7335C179.226 53.8315 179.54 52.8656 179.703 51.869C179.871 50.8736 179.964 49.867 179.982 48.8577C179.988 48.3905 179.988 47.938 179.988 47.471C180 46.9245 180 46.381 180 45.8257V14.3445C180 13.7937 180 13.2472 179.988 12.7023C179.988 12.241 179.988 11.7783 179.982 11.317C179.964 10.3077 179.871 9.30109 179.703 8.30561C179.54 7.30958 179.226 6.34421 178.772 5.44261C177.843 3.63316 176.371 2.16028 174.562 1.23112C173.657 0.775718 172.69 0.457837 171.692 0.287981C170.697 0.122191 169.691 0.0332999 168.683 0.0220969C168.225 0.0147563 167.755 0.00594163 167.296 0.00300842C166.756 0 166.207 0.000195547 165.666 0.000195547Z"
          fill="#A6A6A6"
        />
        <path
          d="M12.7027 58.8523C12.2444 58.8523 11.7972 58.8464 11.3425 58.8362C10.4005 58.8239 9.46075 58.7419 8.5309 58.5908C7.66386 58.4415 6.82393 58.1637 6.03881 57.7667C5.2609 57.3729 4.55138 56.8566 3.93743 56.2375C3.3146 55.6257 2.79606 54.9161 2.40237 54.1369C2.00434 53.3525 1.72888 52.5119 1.58558 51.6441C1.43084 50.7116 1.34711 49.7688 1.33513 48.8237C1.3256 48.5064 1.31311 47.4502 1.31311 47.4502V12.7021C1.31311 12.7021 1.32641 11.662 1.33521 11.3565C1.34668 10.4129 1.42992 9.4715 1.5842 8.54051C1.72776 7.67035 2.00343 6.82724 2.40168 6.04036C2.79392 5.26165 3.30958 4.55154 3.92866 3.93755C4.54705 3.31773 5.25884 2.79875 6.03808 2.39954C6.82139 2.00376 7.65973 1.72794 8.52502 1.58134C9.45793 1.42876 10.401 1.34627 11.3462 1.33456L12.7035 1.31619H167.289L168.663 1.3353C169.599 1.34643 170.534 1.42818 171.458 1.57987C172.332 1.72831 173.179 2.00605 173.971 2.40395C175.532 3.20847 176.803 4.4813 177.604 6.04403C177.996 6.82548 178.267 7.66172 178.409 8.52436C178.565 9.46298 178.653 10.4118 178.67 11.3631C178.675 11.7891 178.675 12.2467 178.675 12.7021C178.687 13.2662 178.687 13.8031 178.687 14.3444V45.8255C178.687 46.372 178.687 46.9052 178.675 47.4428C178.675 47.932 178.675 48.3801 178.669 48.8413C178.651 49.7757 178.566 50.7075 178.412 51.6293C178.272 52.5034 177.998 53.3506 177.6 54.1414C177.203 54.9122 176.688 55.6156 176.072 56.2257C175.457 56.8482 174.747 57.3675 173.967 57.7638C173.177 58.1639 172.331 58.4427 171.458 58.5908C170.528 58.7427 169.588 58.8247 168.646 58.8362C168.206 58.8464 167.744 58.8523 167.296 58.8523L165.666 58.8553L12.7027 58.8523Z"
          fill="black"
        />
        <path
          d="M37.2576 30.5362C37.2738 29.2807 37.6073 28.0497 38.227 26.9577C38.8468 25.8657 39.7327 24.9482 40.8024 24.2906C40.1229 23.3202 39.2264 22.5215 38.1843 21.9581C37.1421 21.3947 35.9829 21.0821 34.7988 21.045C32.2729 20.7799 29.8241 22.5565 28.5369 22.5565C27.2249 22.5565 25.2431 21.0713 23.109 21.1152C21.7286 21.1598 20.3833 21.5612 19.2042 22.2803C18.0251 22.9994 17.0524 24.0117 16.3808 25.2185C13.4716 30.2553 15.6416 37.6576 18.4283 41.7289C19.8226 43.7225 21.4521 45.9494 23.5842 45.8704C25.6706 45.7839 26.4499 44.54 28.9684 44.54C31.4635 44.54 32.1946 45.8704 34.37 45.8202C36.6088 45.7839 38.0194 43.8177 39.3647 41.8053C40.3665 40.3847 41.1374 38.8148 41.6488 37.1535C40.3481 36.6033 39.2381 35.6825 38.4572 34.5058C37.6763 33.329 37.2591 31.9484 37.2576 30.5362Z"
          fill="white"
        />
        <path
          d="M33.1486 18.3677C34.3693 16.9022 34.9707 15.0187 34.8251 13.117C32.9601 13.3129 31.2374 14.2042 30.0002 15.6134C29.3953 16.3019 28.932 17.1028 28.6368 17.9703C28.3416 18.8379 28.2203 19.7552 28.2798 20.6697C29.2126 20.6793 30.1355 20.4771 30.9788 20.0783C31.8222 19.6796 32.564 19.0947 33.1486 18.3677Z"
          fill="white"
        />
        <path
          d="M63.6317 40.8237H56.5117L54.8018 45.8725H51.786L58.53 27.1932H61.6633L68.4073 45.8725H65.3401L63.6317 40.8237ZM57.2491 38.494H62.8929L60.1107 30.3001H60.0328L57.2491 38.494Z"
          fill="white"
        />
        <path
          d="M82.9721 39.0639C82.9721 43.296 80.707 46.015 77.2887 46.015C76.4228 46.0603 75.5616 45.8609 74.8038 45.4395C74.046 45.0181 73.4222 44.3918 73.0038 43.6324H72.9391V50.3778H70.1436V32.2539H72.8495V34.519H72.9009C73.3386 33.7632 73.9729 33.1401 74.7365 32.7162C75.5001 32.2922 76.3644 32.0832 77.2373 32.1114C80.6937 32.1114 82.9721 34.8436 82.9721 39.0639ZM80.0988 39.0639C80.0988 36.3067 78.6739 34.494 76.4999 34.494C74.364 34.494 72.9274 36.3449 72.9274 39.0639C72.9274 41.808 74.364 43.6456 76.4999 43.6456C78.6739 43.6456 80.0988 41.8462 80.0988 39.0639Z"
          fill="white"
        />
        <path
          d="M97.9611 39.0639C97.9611 43.296 95.696 46.015 92.2777 46.015C91.4118 46.0603 90.5506 45.8609 89.7928 45.4395C89.035 45.0181 88.4112 44.3918 87.9928 43.6324H87.9281V50.3778H85.1327V32.2538H87.8385V34.519H87.8899C88.3275 33.7632 88.9619 33.1401 89.7255 32.7162C90.4891 32.2922 91.3533 32.0832 92.2262 32.1114C95.6827 32.1114 97.9611 34.8436 97.9611 39.0639ZM95.0878 39.0639C95.0878 36.3067 93.6629 34.494 91.4889 34.494C89.353 34.494 87.9164 36.3449 87.9164 39.0639C87.9164 41.8079 89.353 43.6456 91.4889 43.6456C93.6629 43.6456 95.0878 41.8461 95.0878 39.0639Z"
          fill="white"
        />
        <path
          d="M107.868 40.668C108.075 42.5204 109.874 43.7366 112.333 43.7366C114.69 43.7366 116.385 42.5203 116.385 40.8501C116.385 39.4003 115.362 38.5321 112.942 37.9372L110.521 37.354C107.091 36.5255 105.498 34.9214 105.498 32.3184C105.498 29.0955 108.307 26.8818 112.295 26.8818C116.242 26.8818 118.948 29.0955 119.039 32.3184H116.217C116.048 30.4543 114.507 29.3291 112.256 29.3291C110.004 29.3291 108.463 30.4675 108.463 32.1245C108.463 33.4451 109.447 34.2222 111.855 34.8171L113.913 35.3224C117.745 36.2287 119.337 37.7682 119.337 40.5005C119.337 43.9951 116.554 46.1838 112.126 46.1838C107.984 46.1838 105.187 44.0465 105.006 40.6679L107.868 40.668Z"
          fill="white"
        />
        <path
          d="M125.37 29.0309V32.2538H127.96V34.4675H125.37V41.9753C125.37 43.1417 125.889 43.6852 127.027 43.6852C127.335 43.6798 127.642 43.6582 127.947 43.6205V45.821C127.435 45.9166 126.915 45.96 126.394 45.9503C123.637 45.9503 122.562 44.9147 122.562 42.2735V34.4675H120.582V32.2538H122.562V29.0309H125.37Z"
          fill="white"
        />
        <path
          d="M129.46 39.0639C129.46 34.7789 131.984 32.0863 135.919 32.0863C139.868 32.0863 142.379 34.7789 142.379 39.0639C142.379 43.3606 139.881 46.0414 135.919 46.0414C131.959 46.0414 129.46 43.3606 129.46 39.0639ZM139.531 39.0639C139.531 36.1245 138.184 34.3897 135.919 34.3897C133.654 34.3897 132.308 36.1377 132.308 39.0639C132.308 42.015 133.654 43.7366 135.919 43.7366C138.184 43.7366 139.531 42.015 139.531 39.0639Z"
          fill="white"
        />
        <path
          d="M144.684 32.2537H147.35V34.5717H147.415C147.596 33.8478 148.02 33.208 148.616 32.7599C149.213 32.3118 149.945 32.0828 150.691 32.1112C151.013 32.1101 151.334 32.1451 151.649 32.2155V34.8303C151.242 34.706 150.818 34.6489 150.393 34.6613C149.987 34.6449 149.582 34.7165 149.206 34.8712C148.83 35.026 148.492 35.2603 148.215 35.5579C147.939 35.8556 147.73 36.2096 147.603 36.5957C147.476 36.9818 147.434 37.3908 147.48 37.7946V45.8724H144.684L144.684 32.2537Z"
          fill="white"
        />
        <path
          d="M164.537 41.8725C164.161 44.3448 161.753 46.0414 158.673 46.0414C154.711 46.0414 152.252 43.387 152.252 39.1285C152.252 34.8568 154.724 32.0863 158.555 32.0863C162.323 32.0863 164.693 34.6746 164.693 38.8039V39.7616H155.074V39.9306C155.03 40.4318 155.092 40.9367 155.258 41.4118C155.424 41.8868 155.689 42.3212 156.035 42.6861C156.382 43.0509 156.802 43.3379 157.268 43.5279C157.734 43.7179 158.235 43.8065 158.738 43.788C159.398 43.8499 160.061 43.6969 160.628 43.3518C161.194 43.0067 161.635 42.4879 161.883 41.8725L164.537 41.8725ZM155.087 37.8079H161.896C161.921 37.3573 161.853 36.9063 161.695 36.4833C161.538 36.0603 161.294 35.6744 160.981 35.3498C160.667 35.0253 160.29 34.7691 159.872 34.5973C159.455 34.4255 159.007 34.3418 158.555 34.3515C158.1 34.3487 157.649 34.4362 157.228 34.6088C156.806 34.7814 156.424 35.0357 156.101 35.3571C155.779 35.6784 155.523 36.0604 155.349 36.4811C155.175 36.9017 155.086 37.3527 155.087 37.8079Z"
          fill="white"
        />
        <path
          d="M54.6155 19.6949C54.733 20.5469 55.5659 21.0882 56.7675 21.0882C57.8869 21.0882 58.7079 20.5102 58.7079 19.714C58.7079 19.0295 58.185 18.6189 56.9966 18.3391L55.8405 18.0593C54.2173 17.6796 53.477 16.8959 53.477 15.5775C53.477 13.9785 54.7829 12.915 56.7425 12.915C57.5126 12.8291 58.2854 13.0519 58.8916 13.5345C59.4979 14.0171 59.8882 14.7203 59.9771 15.4901H58.6198C58.5305 15.0688 58.2858 14.6966 57.9343 14.4476C57.5829 14.1986 57.1506 14.0912 56.7235 14.1467C55.6218 14.1467 54.8887 14.6755 54.8887 15.4842C54.8887 16.1246 55.3617 16.4919 56.531 16.7651L57.5197 17.0082C59.3603 17.4371 60.1197 18.1834 60.1197 19.5333C60.1197 21.2498 58.7888 22.3258 56.6618 22.3258C54.6714 22.3258 53.3331 21.2998 53.2332 19.695L54.6155 19.6949Z"
          fill="white"
        />
        <path
          d="M63.7921 13.6486V15.3658H65.2596V16.4917H63.7921V19.9746C63.7921 20.6841 64.0845 20.9948 64.7499 20.9948C64.9202 20.9943 65.0904 20.984 65.2596 20.964V22.0774C65.0196 22.1204 64.7763 22.1432 64.5325 22.1457C63.0459 22.1457 62.4539 21.6228 62.4539 20.3169V16.4917H61.3786V15.3657H62.4539V13.6486H63.7921Z"
          fill="white"
        />
        <path
          d="M66.6375 20.1931C66.6375 18.9738 67.5453 18.2709 69.1568 18.171L70.9915 18.0653V17.4806C70.9915 16.7652 70.5185 16.3613 69.6048 16.3613C68.8586 16.3613 68.3415 16.6352 68.1931 17.1141H66.899C67.0356 15.9507 68.13 15.2045 69.6665 15.2045C71.3646 15.2045 72.3224 16.0499 72.3224 17.4806V22.1086H71.0356V21.1567H70.9298C70.7151 21.4981 70.4137 21.7765 70.0562 21.9633C69.6988 22.1502 69.2982 22.2388 68.8953 22.2202C68.611 22.2497 68.3236 22.2194 68.0517 22.1311C67.7798 22.0428 67.5294 21.8985 67.3167 21.7075C67.1039 21.5166 66.9336 21.2831 66.8166 21.0223C66.6996 20.7614 66.6386 20.4789 66.6375 20.1931ZM70.9915 19.6143V19.048L69.3374 19.1538C68.4047 19.2162 67.9816 19.5335 67.9816 20.1306C67.9816 20.7402 68.5104 21.095 69.2376 21.095C69.4507 21.1165 69.6659 21.095 69.8705 21.0317C70.0751 20.9684 70.2648 20.8646 70.4285 20.7264C70.5921 20.5883 70.7263 20.4186 70.823 20.2276C70.9197 20.0365 70.977 19.8279 70.9915 19.6143ZM68.8644 14.2026L70.426 12.0631H71.9933L70.2453 14.2026H68.8644Z"
          fill="white"
        />
        <path
          d="M74.4774 12.6908H75.8024V16.4234H75.9082C76.0859 16.0333 76.3795 15.7075 76.7491 15.4904C77.1187 15.2733 77.5462 15.1754 77.9735 15.2101C78.3013 15.1922 78.6291 15.247 78.9334 15.3704C79.2376 15.4938 79.5109 15.6828 79.7337 15.924C79.9565 16.1652 80.1233 16.4526 80.2223 16.7656C80.3213 17.0787 80.3499 17.4098 80.3062 17.7352V22.1083H78.968V18.065C78.968 16.9831 78.464 16.4352 77.5196 16.4352C77.2898 16.4163 77.0587 16.4479 76.8424 16.5277C76.6262 16.6075 76.43 16.7336 76.2675 16.8972C76.1051 17.0607 75.9803 17.2578 75.902 17.4746C75.8237 17.6914 75.7937 17.9227 75.8141 18.1524V22.1083H74.4774L74.4774 12.6908Z"
          fill="white"
        />
        <path
          d="M82.373 15.3353H83.6598V16.4113H83.7597C83.9291 16.0248 84.2149 15.7009 84.5773 15.4846C84.9396 15.2682 85.3604 15.1704 85.781 15.2045C86.1105 15.1798 86.4415 15.2295 86.7492 15.3499C87.057 15.4704 87.3337 15.6586 87.5588 15.9005C87.784 16.1425 87.9518 16.432 88.0499 16.7476C88.148 17.0632 88.1738 17.3969 88.1254 17.7238V22.1086H86.7887V18.0595C86.7887 16.971 86.3157 16.4297 85.3271 16.4297C85.1033 16.4192 84.8799 16.4573 84.6723 16.5413C84.4646 16.6253 84.2775 16.7532 84.1239 16.9162C83.9703 17.0792 83.8538 17.2736 83.7823 17.4859C83.7108 17.6981 83.686 17.9234 83.7097 18.1461V22.1086H82.373V15.3353Z"
          fill="white"
        />
        <path
          d="M89.8573 18.7186C89.8147 18.2735 89.8656 17.8244 90.0068 17.4001C90.148 16.9758 90.3762 16.5857 90.677 16.2548C90.9777 15.9239 91.3443 15.6595 91.7532 15.4786C92.1622 15.2977 92.6044 15.2042 93.0515 15.2042C93.4987 15.2042 93.9409 15.2977 94.3498 15.4786C94.7588 15.6595 95.1254 15.9239 95.4261 16.2548C95.7269 16.5857 95.9551 16.9758 96.0963 17.4001C96.2375 17.8244 96.2884 18.2735 96.2458 18.7186C96.2892 19.1642 96.2389 19.6139 96.0981 20.0389C95.9573 20.4639 95.7292 20.8547 95.4283 21.1863C95.1275 21.5178 94.7606 21.7828 94.3513 21.9641C93.942 22.1454 93.4992 22.239 93.0515 22.239C92.6039 22.239 92.1611 22.1454 91.7518 21.9641C91.3425 21.7828 90.9756 21.5178 90.6748 21.1863C90.3739 20.8547 90.1458 20.4639 90.005 20.0389C89.8642 19.6139 89.8139 19.1642 89.8573 18.7186ZM94.8708 18.7186C94.8708 17.2504 94.2113 16.3918 93.0538 16.3918C91.8918 16.3918 91.2382 17.2504 91.2382 18.7186C91.2382 20.1986 91.8919 21.0506 93.0538 21.0506C94.2113 21.0506 94.8708 20.1927 94.8708 18.7186Z"
          fill="white"
        />
        <path
          d="M103.75 22.1083H102.462V21.0323H102.356C102.185 21.4228 101.895 21.7494 101.528 21.966C101.16 22.1826 100.734 22.2781 100.31 22.239C99.982 22.2623 99.6534 22.2118 99.3478 22.0915C99.0423 21.9711 98.7676 21.7838 98.5438 21.5434C98.3201 21.303 98.1531 21.0156 98.055 20.7021C97.9569 20.3887 97.9302 20.0573 97.9769 19.7323V15.3349H99.3151V19.3841C99.3151 20.4785 99.7558 21.0014 100.739 21.0014C100.966 21.023 101.196 20.9935 101.411 20.915C101.626 20.8365 101.821 20.711 101.981 20.5477C102.142 20.3844 102.263 20.1873 102.338 19.9709C102.412 19.7544 102.438 19.5241 102.412 19.2967V15.3349H103.75V22.1083Z"
          fill="white"
        />
        <path
          d="M107.69 13.6486V15.3658H109.157V16.4917H107.69V19.9746C107.69 20.6841 107.982 20.9948 108.648 20.9948C108.818 20.9943 108.988 20.984 109.157 20.964V22.0774C108.917 22.1204 108.674 22.1433 108.43 22.1457C106.944 22.1457 106.352 21.6228 106.352 20.3169V16.4917H105.276V15.3657H106.352V13.6486H107.69Z"
          fill="white"
        />
        <path
          d="M117.892 22.1083H116.455L114.011 15.3349H115.44L117.126 20.6775H117.232L118.911 15.3349H120.33L117.892 22.1083Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10057_61437">
          <rect width="180" height="60" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
