import React from "react";

import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import Section from "src/components/molecules/Section";
import { StyledParagraphText } from "src/components/atoms/StyledParagraphText";
import { StyledParagraphTextDangerous } from "src/components/atoms/StyledParagraphTextDangerous";
import { HeadphonesIcon } from "src/components/icons/HeadphonesIcon";
import SecondarySection from "src/components/molecules/SecondarySection";
import { ScheduleIcon } from "src/components/icons/ScheduleIcon";

import styles from "./styles.module.css";

export const PanelChurchContentMoreInfo = () => {
  const { t } = useTranslation();
  const guidesInfo = [
    t("tab_multimedia_experience_more_info_base_version"),
    t("tab_multimedia_experience_more_info_complete_version"),
    t("tab_multimedia_experience_more_info_headphones"),
  ];

  const moreInfoOnGuides = [
    t("tab_multimedia_experience_more_info_details_footwear"),
    t("tab_multimedia_experience_more_info_details_children"),
    t("tab_multimedia_experience_more_info_details_permit"),
  ];

  const tours = [
    t("tab_multimedia_experience_more_info_tour_duration_5"),
    t("tab_multimedia_experience_more_info_tour_duration_2"),
    t("tab_multimedia_experience_more_info_tour_duration_8"),
    t("tab_multimedia_experience_more_info_tour_duration_10"),
    t("tab_multimedia_experience_more_info_tour_duration_20_40"),
  ];

  return (
    <Box className={styles.more_info_container}>
      <Stack direction="column">
        <Stack direction="row" justifyContent="start" alignItems="center" gap={1} mb={2}>
          <HeadphonesIcon width={24} height={24} />
          <StyledParagraphText>
            {t("tab_multimedia_experience_more_info_video_guides")}
          </StyledParagraphText>
        </Stack>

        {guidesInfo.map((text) => (
          <Box sx={{ mb: 2 }} key={`guidesInfo ${text}`}>
            <Section text={text} TextAsNode={<StyledParagraphTextDangerous text={text} />} />
          </Box>
        ))}
        <StyledParagraphText>
          {t("tab_multimedia_experience_more_info_details_heading")}
        </StyledParagraphText>
        <Box sx={{ ml: 5, mb: 2 }}>
          {moreInfoOnGuides.map((text) => (
            <Box sx={{ mb: 0.5 }} key={`moreInfoOnGuides ${text}`}>
              <SecondarySection text={text} />
            </Box>
          ))}
        </Box>
      </Stack>
      <Stack direction="column">
        <Stack direction="row" justifyContent="start" alignItems="center" gap={1} mb={2}>
          <ScheduleIcon width={24} height={24} />
          <StyledParagraphText>
            {t("tab_multimedia_experience_more_info_tour_duration_heading")}
          </StyledParagraphText>
        </Stack>
        <Box sx={{ mb: 2 }}>
          {tours.map((text) => (
            <Box sx={{ mb: 0.7 }} key={`tours ${text}`}>
              <StyledParagraphTextDangerous text={text} />
            </Box>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};
