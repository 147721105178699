import React from "react";

import { SmallCircle } from "src/components/icons/Rectangles/SmallCircle";
import { BigCircle } from "src/components/icons/Rectangles/BigCircle";
import { SmallCircleWrapper, Wrapper } from "src/components/icons/Rectangles/styledComponents";

const Rectangles = ({ isMobile }: { isMobile: boolean }) => (
  <Wrapper>
    <BigCircle isMobile={isMobile} />
    <SmallCircleWrapper>
      <SmallCircle isMobile={isMobile} />
    </SmallCircleWrapper>
  </Wrapper>
);

export default Rectangles;
