import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { styles } from "./styles";
import styless from "./styles.module.css";
import { useGetTickets } from "src/core/queries/useGetTickets";
import { useDispatch, useSelector } from "react-redux";
import ticketIcon from "../../../assets/ticket_red.svg";
import AmountPeopleComponent from "src/components/atoms/AmountPeopleComponent";
import { setToNextStep, setStepToPen } from "src/core/utils";
import { CheckOutStep, Tab, TourList } from "src/core/types";
import { RootState } from "src/core/redux/store";
import { IPreReservation, IPreReservationResponse, SlotKey } from "src/core/types/budnles";
import { CounterState, StateValue } from "src/core/types/action";
import { prepareTicketToIncrement } from "src/core/utils/prepareTicketToIncrement";
import { prepareTicketToDecrement } from "src/core/utils/prepareTicketToDecrement";
import { usePostPreReservation } from "src/core/queries/usePostPreReservation";
import { prepareUpdateReservationData } from "src/core/utils/prepareUpdateReservationData";
import { setCapacity } from "src/core/redux/actions/capacityAction";
import TicketsReceipt from "src/components/atoms/TicketsReceipt";
import NextStepButton from "src/components/atoms/NextStepButton";
import { Loc } from "src/core/types/loc";
import PenComponent from "src/components/molecules/PenComponent";
import {
  PenWrapper,
  TicketDescription,
} from "src/components/molecules/AmountOfPeople/styledComponents";
import { PenTicketsIcon } from "src/components/icons/PenTicketsIcon";
import {
  setSelectedTickets as setGlobalSelectedTickets,
  setTickets,
} from "src/core/redux/actions/reservationDataActions";

interface AmountOfPeopleProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  style: React.CSSProperties;
  tabs: Tab[];
  nextStep: string;
}
const AmountOfPeople: React.FC<AmountOfPeopleProps> = ({
  updateCheckOutState,
  step,
  panels,
  style,
  tabs,
  nextStep,
}) => {
  const dispatch = useDispatch();
  const reservationId = useSelector((state: RootState) => state.reservationID?.reservationID);
  const selectedSlots = useSelector((state: RootState) => state.timeSlot);
  const selectedBundle = useSelector((state: RootState) => state.bundle);
  const baseCapacity = useSelector((state: RootState) => state.capacity);
  const [selectedTickets, setSelectedTickets] = useState<CounterState>({} as CounterState);
  const isApp = sessionStorage.getItem("isApp");
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";

  const { t } = useTranslation();

  const transformedSlotsForQuery: { name: SlotKey }[] = selectedBundle?.tours.map(
    (tour: SlotKey) => {
      return { name: tour };
    },
  );

  //queries
  const { data, status } = useGetTickets({
    bundleName: selectedBundle?.name || undefined,
    tours: transformedSlotsForQuery,
  });
  const onSuccess = (
    response: IPreReservationResponse,
    transformedData?: IPreReservation,
    tour?: SlotKey,
    slotId?: number,
  ) => {
    dispatch(setCapacity(response.capacity));
    if (response.result === "OK" && tour && transformedData && transformedData.tickets && slotId) {
      const filteredTickets = transformedData.tickets.filter(
        (ticket) => ticket.time_slot_id === slotId,
      );
      setSelectedTickets((prevState) => ({ ...prevState, [tour]: filteredTickets }));
      dispatch(setGlobalSelectedTickets({ ...selectedTickets, [tour]: filteredTickets }));
    }
  };

  const { mutate, status: mutateStatus } = usePostPreReservation({ onSuccess: onSuccess });

  const slotKeys: SlotKey[] | undefined = data ? (Object.keys(data) as SlotKey[]) : undefined;

  const getCountOfTickets = (tour: SlotKey, ticketId: number): number => {
    return selectedTickets[tour]?.find((ticket) => ticket.type === ticketId)?.amount || 0;
  };

  const getTourPrice = (tour: SlotKey): number => {
    let total = 0;
    if (
      data &&
      selectedBundle &&
      Array.isArray(data[tour]) &&
      Array.isArray(selectedTickets[tour])
    ) {
      data[tour].forEach((ticket: TourList) => {
        selectedTickets[tour].forEach((selectedTicket: StateValue) => {
          if (ticket.id === selectedTicket.type) {
            total += Number(ticket.price) * selectedTicket.amount;
          }
        });
      });
      return total;
    }
    return total;
  };

  const getTotalAmount = (): number => {
    let totalAmount = 0;
    if (selectedBundle && selectedBundle.tours) {
      selectedBundle.tours.forEach((tour: SlotKey) => {
        if (selectedTickets[tour]) {
          selectedTickets[tour].forEach((ticket: StateValue) => (totalAmount += ticket.amount));
        }
      });
    }
    return totalAmount;
  };

  const transformUpdateReservationData = (preParedState: CounterState) => {
    return prepareUpdateReservationData({
      reservation: reservationId ?? "",
      pos: isApp ? "mob" : "web",
      tickets: { ...selectedTickets, ...preParedState },
      slotKeys: slotKeys ?? [],
      selectedSlots: selectedSlots,
    });
  };

  const isToursSelected = (): boolean => {
    if (selectedBundle && selectedBundle.tours) {
      const tiketsState = selectedBundle?.tours.map((selectedTour: SlotKey) => {
        if (selectedTickets[selectedTour]) {
          return selectedTickets[selectedTour]?.length > 0;
        }
        return false;
      });
      return !tiketsState?.some((state) => !state);
    }
    return false;
  };

  const goToPreviousStep = () => {
    const { newTabs, newPanels } = setStepToPen({
      tabs: tabs,
      panels: panels,
      currentKey: Number(step.key),
    });
    updateCheckOutState(newTabs, newPanels);
  };

  const handleIncrement = (tour: SlotKey, ticket: TourList) => {
    const capacity = baseCapacity[selectedSlots[tour].id];
    if (!capacity || mutateStatus === "loading") return;

    const preParedState = prepareTicketToIncrement({
      tour: tour,
      selectedTickets: selectedTickets,
      ticket: ticket,
      slot: selectedSlots[tour],
    });

    const transformedData = transformUpdateReservationData(preParedState as CounterState);

    const slotId = selectedSlots[tour].id;
    mutate({ transformedData, tour, slotId });
  };
  const handleDecrement = (tour: SlotKey, ticket: TourList) => {
    if (!getCountOfTickets(tour, ticket.id)) return;
    const preParedState = prepareTicketToDecrement({
      tour: tour,
      selectedTickets: selectedTickets,
      ticket: ticket,
      slot: selectedSlots[tour],
    });

    const transformedData = transformUpdateReservationData(preParedState as CounterState);
    const slotId = selectedSlots[tour].id;
    mutate({ transformedData, tour, slotId });
  };

  const handleNextStep = () => {
    if (isToursSelected()) {
      const { newTabs, newPanels } = setToNextStep({
        tabs: tabs,
        panels: panels,
        currentKey: Number(step.key),
      });
      updateCheckOutState(newTabs, newPanels);
    }
  };

  const descriptions: string[] = [
    "adult_condition",
    "family_condition",
    "children_type1",
    "children_type2",
    "senior_condition",
  ];

  const errorMessages = [
    `${t("tickets_limit")} ${"dynamicCapacity"} ${t("places")}`,
    `${t("tickets_limit")} ${"dynamicCapacity"} ${t("places")}`,
    `${t("child_with_adult")}`,
    `${t("child_with_adult")}`,
    null,
  ];

  useEffect(() => {
    setSelectedTickets({} as CounterState);
  }, [selectedSlots, reservationId]);

  useEffect(() => {
    if (data) {
      const ticketTypes = (Object.keys(data) as SlotKey[]).flatMap((tour: SlotKey) => data[tour]);
      dispatch(setTickets(ticketTypes));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box style={style}>
      {step.isEnabled ? (
        <Box className={styless.container}>
          <Box className={styless.title_row}>
            <Box style={styles.title}>
              <img src={ticketIcon} alt="ticket" style={{ marginRight: "10px", width: "24px" }} />
              {t("choose_ticket")}
            </Box>
          </Box>
          {status !== "success" && <CircularProgress color={"error"} />}
          {data &&
            status === "success" &&
            slotKeys &&
            slotKeys.length > 0 &&
            slotKeys.map((slotKey: SlotKey, i: number) => (
              <>
                <Box className={styless.notesBox}>
                  <span className={styless.notes}>
                    {`${baseCapacity[selectedSlots[slotKey].id]} ${t("free-capacity-count")}`}
                  </span>
                </Box>
                <div className={styless.ticketsBox}>
                  <Box className={styless.mainBox}>
                    <div className={styless.tour_name}>{selectedBundle?.items[i].name[loc]}</div>
                    <Box className={styless.innerBox}>
                      {data[slotKey].map((ticket: TourList, index: number) => (
                        <AmountPeopleComponent
                          key={ticket.id}
                          title={ticket.name}
                          subtitle={descriptions[index]}
                          price={Number(ticket.price)}
                          count={getCountOfTickets(slotKey, ticket.id)}
                          isError={false}
                          status={"success"}
                          errorMessage={errorMessages[index]}
                          increment={() => handleIncrement(slotKey, ticket)}
                          decrement={() => handleDecrement(slotKey, ticket)}
                          isPermanentMessage={ticket.name === "children"}
                          bundle={selectedBundle}
                        />
                      ))}
                    </Box>
                  </Box>
                  {slotKeys.length === i + 1 && (
                    <TicketsReceipt getTourPrice={getTourPrice} bundle={selectedBundle} />
                  )}
                </div>
              </>
            ))}
          <div className={styless.buttonNext}>
            <NextStepButton
              text={t(nextStep)}
              handler={handleNextStep}
              isActive={isToursSelected()}
            />
          </div>
        </Box>
      ) : (
        step.isPen && (
          <PenComponent prevStep={goToPreviousStep}>
            <PenWrapper>
              <PenTicketsIcon />
              <TicketDescription>{`${t(
                "amount_of_tickets",
              )}   ${getTotalAmount()}`}</TicketDescription>
            </PenWrapper>
          </PenComponent>
        )
      )}
    </Box>
  );
};
AmountOfPeople.displayName = "AmountOfPeople";
export default AmountOfPeople;
