import { IPreReservation, SlotKey, SlotState } from "src/core/types/budnles";

export const preparePreReservationData = ({
  date,
  pos,
  reservation,
  slots,
}: {
  date: string;
  pos: string;
  reservation: string | null;
  slots: SlotState;
}): IPreReservation => {
  return {
    date: date,
    pos: pos,
    reservation: reservation,
    slots: (Object.keys(slots) as SlotKey[]).map((key: SlotKey) => slots[key].id),
  };
};
