import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SuccessIcon from "src/components/icons/SuccessIcon";
import BadgesComponent from "src/components/molecules/BadgesComponent";

import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";
import styles from "./styles.module.css";

const PageSuccessfulResultDesktop = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <img className={styles.image} src={mainPhoto} alt="" />
      <div className={styles.wrapper}>
        <div className={styles.header} dangerouslySetInnerHTML={{ __html: t("payment_success") }} />
        <div className={styles.middle_icon}>
          <SuccessIcon />
        </div>
        <div className={styles.sub_descirption}>
          {t("next_info")}
          <br />
          {t("additional_info2")}
        </div>
        <div className={styles.dotted_line} />
        <div className={styles.description}>{t("download_application")}</div>
        <div className={styles.sub_descirption}>
          {t("have_tickets")} <br />
          {t("mobile_and_app")}
        </div>
        <BadgesComponent />
        <div className={styles.dotted_line} />
        <div className={styles.button_home} onClick={() => navigate("/")}>
          {t("home")}
        </div>
      </div>
    </>
  );
};

export default PageSuccessfulResultDesktop;
