import { CounterState } from "src/core/types/action";
import { ReservationActions } from "src/core/types/reduxTypes";
import { TourList } from "src/core/types";
import { IPerson } from "src/core/types/personType";

interface IReservationDataReducer {
  selectedTickets: CounterState | undefined;
  selectedDate: Date | undefined;
  tickets: TourList[] | undefined;
  userData: IPerson | undefined;
}

const initialState: IReservationDataReducer = {
  selectedTickets: undefined,
  selectedDate: undefined,
  userData: undefined,
  tickets: undefined,
};

export const ReservationDataReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: ReservationActions,
): IReservationDataReducer => {
  switch (action.type) {
    case "SET_TICKETS":
      return {
        ...state,
        tickets: action.payload,
      };
    case "SET_SELECTED_TICKETS":
      return {
        ...state,
        selectedTickets: action.payload,
      };
    case "SET_SELECTED_DATE":
      return {
        ...state,
        selectedDate: action.payload,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};
