import React from "react";
import { IBundle, ISlot, SlotKey, SlotState } from "src/core/types/budnles";
import { SmallKalendar } from "src/components/icons/SmallKalendar";
import { getFormattedDateToText } from "src/core/helpers/getFormattedDateToText";
import { SmallVolume } from "src/components/icons/SmallVolume";
import {
  BlockWithIcon,
  BlockWithIconText,
  BundleName,
  Description,
  InfoWrapper,
  SlotWrapper,
} from "src/components/molecules/DateAndTime/components/PenContent/styledComponents";
import { Loc } from "src/core/types/loc";

const PenContent = ({
  slots,
  bundle,
  date,
  loc,
}: {
  slots: SlotState;
  bundle: IBundle;
  date: Date;
  loc: Loc;
}) => {
  const transformedSlots = (Object.keys(slots) as SlotKey[]).map((slot: SlotKey) => slots[slot]);
  return (
    <SlotWrapper>
      {transformedSlots.map((slot: ISlot, i: number) => (
        <InfoWrapper key={slot.id}>
          <BundleName>{bundle.items[i].name[loc]}</BundleName>
          {slot.story_name && <Description>{slot.story_name[loc]}</Description>}
          <BlockWithIcon>
            <SmallKalendar />
            <BlockWithIconText>{`${getFormattedDateToText(date)}, ${slot.time.slice(
              0,
              5,
            )}`}</BlockWithIconText>
          </BlockWithIcon>
          {!slot.is_guided && <Description>Bez pruvodce</Description>}
          <BlockWithIcon>
            <SmallVolume />
            <BlockWithIconText>{slot.language.toUpperCase()}</BlockWithIconText>
          </BlockWithIcon>
        </InfoWrapper>
      ))}
    </SlotWrapper>
  );
};

export default PenContent;
