import React, { useEffect, useState } from "react";

import {
  BundlesWrapper,
  ButtonWrapper,
  CalendarContainer,
  Checked,
  Wrapper,
} from "src/components/molecules/DateAndTime/styledComponents";
import BundleComponent from "src/components/molecules/BundleComponent";
import NextStepButton from "src/components/atoms/NextStepButton";
import { CalendarDialog } from "src/components/atoms/CalendarDialog";
import PenComponent from "src/components/molecules/PenComponent";
import PenContent from "src/components/molecules/DateAndTime/components/PenContent";
import { useGetSlots } from "src/core/queries/useGetSlots";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import { Loc } from "src/core/types/loc";
import { IPreReservationResponse, ISlot, SlotKey, SlotState } from "src/core/types/budnles";
import { preparePreReservationData } from "src/core/utils/preparePreReservationData";
import { usePostPreReservation } from "src/core/queries/usePostPreReservation";
import { setReservationId } from "src/core/redux/actions/reservationActions";
import { CheckOutStep, Tab } from "src/core/types";
import { setStepToPen, setToNextStep } from "src/core/utils";
import { setGlobalTimeSlots } from "src/core/redux/actions/timeSlotAction";
import { getFormattedDate } from "src/core/helpers/formatDate";
import { setCapacity } from "src/core/redux/actions/capacityAction";

import checkIcon from "../../../assets/white_checked.svg";
import { setSelectedDate as setGlobalDate } from "src/core/redux/actions/reservationDataActions";
import { useGetRemoveReservation } from "src/core/queries/useGetRemoveReservation";

interface IDateAndTimeProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  tabs: Tab[];
}

const DateAndTime = ({ updateCheckOutState, step, panels, tabs }: IDateAndTimeProps) => {
  const dispatch = useDispatch();
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const isApp = sessionStorage.getItem("isApp");
  const reservationId = useSelector((state: RootState) => state.reservationID?.reservationID);
  const selectedGlobalSlots = useSelector((state: RootState) => state.timeSlot);
  const selectedBundle = useSelector((state: RootState) => state.bundle);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedSlots, setSelectedSlots] = useState<SlotState>({} as SlotState);

  const onSelectDate = (date: Date) => {
    setSelectedDate(date);
  };

  const deleteSlot = (tour: SlotKey) => {
    setSelectedSlots((prevState) => {
      const updatedSlots = { ...prevState };
      delete updatedSlots[tour];
      return updatedSlots;
    });
  };

  const resetSlot = () => {
    setSelectedSlots({} as SlotState);
  };

  const onSuccess = (data: IPreReservationResponse) => {
    dispatch(setReservationId(data.reservation));
    dispatch(setCapacity(data.capacity));
    dispatch(setGlobalDate(selectedDate));
    dispatch(setGlobalTimeSlots(selectedSlots));
    const { newTabs, newPanels } = setToNextStep({
      tabs: tabs,
      panels: panels,
      currentKey: Number(step.key),
    });
    updateCheckOutState(newTabs, newPanels);
  };

  const goToPreviousStep = () => {
    const { newTabs, newPanels } = setStepToPen({
      tabs: tabs,
      panels: panels,
      currentKey: Number(step.key),
    });
    updateCheckOutState(newTabs, newPanels);
  };

  //queries
  const { data } = useGetSlots({ date: selectedDate });
  const { mutate } = usePostPreReservation({ onSuccess: onSuccess });
  const { refetch } = useGetRemoveReservation({ reservationId: reservationId });

  const selectSlot = (slot: ISlot, tour: SlotKey) => {
    setSelectedSlots((prevState) => ({
      ...prevState,
      [tour]: { ...slot, priority: data[tour].priority },
    }));
  };

  const getIsSlotSelected = (tour: SlotKey): boolean => {
    if (data) {
      return data[tour].slots.some((slot: ISlot) => slot.id === selectedSlots[tour]?.id);
    }
    return false;
  };

  const isAllSelected = selectedBundle?.tours.length === Object.keys(selectedSlots).length;
  const isAlone = selectedBundle?.tours.length < 2;
  const isCheckIcon = selectedBundle?.tours.length > 1;

  const invokeReservation = () => {
    const preparedData = preparePreReservationData({
      date: getFormattedDate(selectedDate),
      reservation: null,
      pos: isApp ? "mob" : "web",
      slots: selectedSlots,
    });
    mutate({ transformedData: preparedData });
  };

  const handlePreReservation = async () => {
    if (reservationId && JSON.stringify(selectedGlobalSlots) !== JSON.stringify(selectedSlots)) {
      await refetch();
      invokeReservation();
    } else if (
      reservationId &&
      JSON.stringify(selectedGlobalSlots) === JSON.stringify(selectedSlots)
    ) {
      const { newTabs, newPanels } = setToNextStep({
        tabs: tabs,
        panels: panels,
        currentKey: Number(step.key),
      });
      updateCheckOutState(newTabs, newPanels);
    } else {
      invokeReservation();
    }
  };

  useEffect(() => {
    resetSlot();
  }, [selectedDate]);

  return step.isEnabled ? (
    <Wrapper>
      <CalendarContainer>
        <CalendarDialog onClick={onSelectDate} selectedValue={selectedDate} fullSlots={[]} />
      </CalendarContainer>
      {!isAllSelected && !isAlone && (
        <div
          style={{
            width: "1px",
            backgroundColor: "red",
            alignSelf: "stretch",
            marginLeft: "100px",
          }}
        ></div>
      )}
      <BundlesWrapper isSelectedTwo={Object.keys(selectedSlots).length > 1}>
        {isAllSelected && isCheckIcon && (
          <Checked>
            <img src={checkIcon} alt="check_icon" />
          </Checked>
        )}
        {selectedBundle &&
          selectedBundle.tours.map((tour: SlotKey, i) => (
            <BundleComponent
              key={tour}
              tour={tour}
              i={i}
              selectSlot={selectSlot}
              selectedSlots={selectedSlots}
              isSelected={getIsSlotSelected(tour)}
              bundle={data ? data[tour] : undefined}
              selectedSlot={selectedSlots[tour] || undefined}
              is_alone={Object.keys(selectedSlots).length === 1}
              deleteSlot={deleteSlot}
              loc={loc}
            />
          ))}
        {isAllSelected && (
          <ButtonWrapper>
            <NextStepButton text={"Vyber vstupenky"} handler={handlePreReservation} />
          </ButtonWrapper>
        )}
      </BundlesWrapper>
    </Wrapper>
  ) : (
    <PenComponent prevStep={goToPreviousStep}>
      <PenContent slots={selectedSlots} bundle={selectedBundle} date={selectedDate} loc={loc} />
    </PenComponent>
  );
};

export default DateAndTime;
