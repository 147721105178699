import React, { useState } from "react";
import {
  CircleWrapper,
  Image,
  ImageWrapper,
  PreWrapper,
  SectionsWrapper,
  SeeMoreText,
  SeeMoreWrapper,
  TextWrapper,
  Title,
  Wrapper,
  Container,
} from "src/components/pages/PageHome/components";
import ArrowRight from "src/components/icons/ArrowRight";
import Rectangles from "src/components/icons/Rectangles";

import { IMiddlePanel } from "src/core/types/middlePanel";

import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Stack, Typography } from "@mui/material";

const animationVariants = {
  hidden: { opacity: 0, height: 0 },
  visible: { opacity: 1, height: "auto" },
  exit: { opacity: 0, height: 0 },
};

const MiddlePanel = ({
  title,
  image,
  moreInfo,
  content,
  operator,
  isMobile,
  isFlexReversed,
}: IMiddlePanel & { isMobile: boolean }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleInfoComponent = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Container>
      <Wrapper flexDirection={isMobile ? "column" : isFlexReversed ? "row-reverse" : "row"}>
        <Stack
          direction="column"
          gap="10px"
          sx={isMobile ? { textAlign: "start" } : { textAlign: "end" }}
        >
          <ImageWrapper>
            <Image src={image} isMobile={isMobile} />
            <CircleWrapper>
              <Rectangles isMobile={isMobile} />
            </CircleWrapper>
          </ImageWrapper>
          {operator && (
            <Typography variant="body2" color="#707070" bgcolor="#fff">
              {t("run_by")} {operator}
            </Typography>
          )}
        </Stack>
        <TextWrapper>
          <PreWrapper>
            <Stack direction="column" gap={1}>
              <Title>{t(title)}</Title>
              <SectionsWrapper>{content}</SectionsWrapper>
            </Stack>
            {!isMobile && moreInfo && (
              <SeeMoreWrapper onClick={toggleInfoComponent}>
                <SeeMoreText>{t("learn_more")}</SeeMoreText>
                <ArrowRight rotate={isOpen ? "270deg" : "90deg"} size={{ w: "25px", h: "32px" }} />
              </SeeMoreWrapper>
            )}
          </PreWrapper>
        </TextWrapper>
      </Wrapper>
      <motion.div
        initial="hidden"
        animate={isOpen ? "visible" : "hidden"}
        exit="exit"
        variants={animationVariants}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        {moreInfo}
      </motion.div>
      {isMobile && (
        <SeeMoreWrapper onClick={toggleInfoComponent}>
          <SeeMoreText>{t("learn_more")}</SeeMoreText>
          <ArrowRight rotate={isOpen ? "270deg" : "90deg"} size={{ w: "25px", h: "32px" }} />
        </SeeMoreWrapper>
      )}
    </Container>
  );
};

export default MiddlePanel;
