import styled from "styled-components";

interface IPayButton {
  isActive: boolean;
}

export const Wrapper = styled.div`
  width: 1360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 48px 48px 52px;
  background: #f8f8f8;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const InfoContent = styled.div`
  width: 756px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 15px;
`;

export const InfoSubContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
`;

export const PaymentContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const CheckBoxWrapper = styled.div`
  width: 393px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
`;

export const PayButton = styled.div<IPayButton>`
  width: 422px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  padding: 13px 16px 13px 24px;
  border-radius: 25px;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  background: ${(props) => (props.isActive ? "#e31340" : "grey")};
  cursor: pointer;
`;
