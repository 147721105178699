import { useMutation } from "react-query";
import axios from "axios";
import { RESERVATION_API_ROUTE } from "../endpoints";
import { IReservation, Reservation } from "src/core/types";

export const usePostReservation = () => {
  return useMutation(
    (submitData: IReservation | Reservation) => {
      return axios.post(`${window.BASE_API_URL}/${RESERVATION_API_ROUTE}`, submitData);
    },
    {
      onSuccess: (response) => {
        const payUrl = response.data.pay_url;
        if (payUrl) {
          window.location.href = payUrl;
        }
      },
    },
  );
};
