import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ButtonFillOutForm } from "src/components/atoms/ButtonFillOutForm";

import styles from "./styles.module.css";

export const SubHeader = ({
  mainPhoto,
  title,
  subtitle,
}: {
  mainPhoto: string;
  title: string;
  subtitle: string;
}) => {
  const { t } = useTranslation();
  return (
    <Box className={styles.box}>
      <Box className={styles.overlay}></Box>
      <Box className={styles.imageContainer}>
        <img alt="mainPhoto" src={mainPhoto} className={styles.image} />
      </Box>
      <Box className={styles.titles}>
        <span className={styles.subTitleKrovy} dangerouslySetInnerHTML={{ __html: t(title) }} />
        <span className={styles.subTitle}>{t(subtitle)}</span>
        <div style={{ marginTop: "140px" }}>
          <Box className={styles.buttonBox}>
            <ButtonFillOutForm />
          </Box>
        </div>
      </Box>
    </Box>
  );
};
