import { TicketFilterPayload } from "core/types/reduxTypes";

export const preferButtons: { id: TicketFilterPayload; text_content: string }[] = [
  {
    id: "everytime",
    text_content: "preffered_time_any",
  },
  {
    id: "morning",
    text_content: "preffered_time_morning",
  },
  {
    id: "evening",
    text_content: "preffered_time_evening",
  },
];
