export const style = {
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  transparent: {
    minHeight: "50px",
    minWidth: "150px",
    width: "210px",
    height: "20px",
    background: "transparent",
    boxShadow: "none",
    border: "none",
    color: "black",
    textDecoration: "none",
    flexShrink: 0,
    cursor: "pointer",
  },
  primary: {
    minHeight: "50px",
    minWidth: "150px",
    width: "220px",
    marginLeft: "15px",
    background: "#E31340 0% 0% no-repeat padding-box",
    color: "white",
    textDecoration: "none !important",
    padding: "10px",
    borderRadius: "25px",
    opacity: "1",
    flexShrink: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F1375F",
      color: "#FFFFFF !important",
      textDecoration: "none !important",
    },
  },
  secondary: {
    minHeight: "50px",
    minWidth: "120px",
    width: "190px",
    height: "20px",
    background: "#FFFFFF",
    border: "1px solid #E31340",
    color: "black !important",
    textDecoration: "none",
    padding: "15px",
    borderRadius: "25px",
    flexShrink: 0,
    cursor: "pointer",
  },
  contact: {
    minHeight: "50px",
    width: "64px",
    height: "20px",
    marginLeft: "50px",
    marginRight: "50px",
    background: "transparent",
    boxShadow: "none",
    border: "none",
    color: "black",
    textDecoration: "none",
    flexShrink: 0,
    cursor: "pointer",
  },
  buttonText: {
    fontSize: "15px",
    cursor: "pointer",
    color: "#062762",
    fontWeight: "400px",
    textDecoration: "none",
    fontFamily: "Montserrat",
  },
  LocSelect: {
    flexShrink: 0,
    width: "110px",
    cursor: "pointer",
  },
};
