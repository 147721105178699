import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RecapPaymentTravelMobile from "src/components/atoms/RecapPaymentTravelMobile";
import { Loc } from "src/core/types/loc";

import styles from "./styles.module.css";
import logo from "../../../assets/logo_jakub_pos.svg";

const RecapPaymentTravel = ({
  changeClick,
  nameSurname,
  email,
}: {
  changeClick: () => void;
  nameSurname: string;
  email: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const finalPrice = sessionStorage.getItem("totalPrice");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const updateMedia = (): void => {
    setIsMobile(window.innerWidth <= 768);
  };
  const handleChangeClick = (): void => {
    changeClick();
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const horizontalLineByLanguage = (): string => {
    if (loc === "de" && !isMobile) {
      return styles.hoziontal_line_mutated;
    }
    return styles.hoziontal_line;
  };

  return !isMobile ? (
    <div className={styles.wrapper}>
      <div className={styles.content_left}>
        <div className={styles.content_left_header}>
          <img alt="logo" src={logo} className={styles.icon} />
        </div>
        <div className={styles.content_left_body}>
          {t("fullPrice")} {finalPrice}
          {t("cz_currency")}
        </div>
      </div>
      <div className={styles.vertical_line} />
      <div className={styles.content_right}>
        <div className={styles.content_personal}>
          <span className={styles.perosnal_data_head}>{t("contact_information")}</span>
          <div className={horizontalLineByLanguage()} />
          <span className={styles.red_button_change} onClick={handleChangeClick}>
            {t("to_change")}
          </span>
        </div>
        <div className={styles.personal_data}>
          <div className={styles.perosnal_data_description_wrapper}>
            <div className={styles.perosnal_data_description}>{t("name_surname")}</div>
            <div className={styles.perosnal_data_prop}>{nameSurname}</div>
          </div>
          <div className={styles.perosnal_data_description_wrapper}>
            <div className={styles.perosnal_data_description}>{t("email_address")}</div>
            <div className={styles.perosnal_data_prop}>{email}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <RecapPaymentTravelMobile nameSurname={nameSurname} email={email} changeClick={changeClick} />
  );
};

export default RecapPaymentTravel;
