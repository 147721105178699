import styled from "styled-components";

export const Bundle = styled.div`
  width: 756px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const BundleNameWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: auto;
`;

export const BundleInfoWrapper = styled.div`
  width: 1000px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

export const BundleName = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  text-align: left;
  margin-bottom: auto;
  color: #062762;
`;

export const HistoryName = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #e31340;
`;

export const DateWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;
