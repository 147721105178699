import styled from "styled-components";

export const Wrapper = styled.div`
  width: 92dvw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 430px;
  margin-top: 90px;
  margin-bottom: 30px;
  gap: 3px;
`;

export const MainPhoto = styled.img`
  width: 100%;
  height: 320px;
  margin-bottom: 1px;
  object-fit: cover;
  border-radius: 10px;
`;

export const PhotosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
`;

export const Image = styled.img`
  width: 100%;
  height: 121px;
  object-fit: cover;
  border-radius: 10px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const TitleDescription = styled.span`
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
  text-align: left;
  color: #000000;
`;

export const SubTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #000000;
`;
