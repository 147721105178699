import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import linePoint from "../../../assets/list_item_red.svg";
import travelIcon from "../../../assets/travel_ikon.svg";
import { visitorsCategories } from "src/core/lists";
import CartIcon from "src/assets/tickets_white.svg";

import styles from "./styles.module.css";
import purchaseStyle from "./purchaseStyle.module.css";

export const TravelContent: React.FC<{
  formClick?: () => void;
  handleButtonClick?: () => void;
  containerRef?: React.RefObject<HTMLDivElement>;
}> = ({ formClick, handleButtonClick, containerRef }) => {
  const stylesObj = {
    buttonMain: {
      minWidth: "330px",
      minHeight: "50px",
      padding: "5px",
      flexShrink: 0,
      backgroundColor: "#E1E1E1",
      color: "#062762 !important",
      display: "flex",
      justifyContent: "flex-end",
      borderRadius: "25px",
    },
    buttonToBuy: {
      minWidth: "200px",
      minHeight: "50px",
      width: "220px",
      height: "50px",
      padding: "5px",
      flexShrink: 0,
      backgroundColor: "#E31340",
      color: "white",
      display: "flex",
      justifyContent: "center",
      borderRadius: "25px",
    },
  };

  const { t } = useTranslation();

  return (
    <Box className={styles.container} ref={containerRef}>
      <Box className={styles.mainBox}>
        <Box className={styles.titleBox}>
          <img alt="travelIcon" src={travelIcon} style={{ margin: "0 20px 0 0" }} />
          <span className={styles.titleString}>{t("travel_info")}</span>
          <span
            className={styles.subtitleText1_sub}
            dangerouslySetInnerHTML={{ __html: t("travel_subtitle_text_sub") }}
          />
        </Box>
        <Box className={styles.subtitleBox}>
          <span
            className={styles.subtitleText1}
            dangerouslySetInnerHTML={{ __html: t("travel_show_to_clients") }}
          />
          <span
            className={styles.subtitleText1}
            dangerouslySetInnerHTML={{ __html: t("travel_subtitle_text") }}
          />
          <span
            className={styles.subtitleText1_sub}
            dangerouslySetInnerHTML={{ __html: t("travel_subtitle_text_three") }}
          />
        </Box>
        <Box className={styles.noticeBox}>
          <div className={styles.line}></div>
          <Box className={styles.noticeBox}>
            <Box className={styles.noticeWrapper}>
              <span
                className={styles.text_centered}
                dangerouslySetInnerHTML={{
                  __html: t("travel_head_warning"),
                }}
              />
              <Box className={styles.price_wrapper}>
                <span
                  className={styles.text_centered_red}
                  dangerouslySetInnerHTML={{
                    __html: t("travel_price"),
                  }}
                />
                <span
                  className={styles.text_centered}
                  dangerouslySetInnerHTML={{
                    __html: t("price_description"),
                  }}
                />
              </Box>
            </Box>
            <Box className={styles.price_wrapper}>
              <span
                className={styles.text_centered}
                dangerouslySetInnerHTML={{
                  __html: t("travel_buy_desc"),
                }}
              />
            </Box>
          </Box>
          <Box className={styles.noticeWrapper}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("travel_condition_exclusion"),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("travel_condition_one"),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("travel_condition_two"),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("travel_condition_three"),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("travel_condition_four"),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("travel_condition_five"),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("travel_condition_six"),
                }}
              />
            </Box>
            <Box className={styles.notice}>
              <img alt="img" src={linePoint} style={{ margin: "0 1.5vw 0 0" }} />
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t("travel_condition_seven"),
                }}
              />
            </Box>
            <Box className={styles.notice_bold}>{t("travel_bottom_text")}</Box>
          </Box>
          <div className={styles.line}></div>
          <Box className={purchaseStyle.buttonBox}>
            <Button
              onClick={handleButtonClick}
              style={{ textDecoration: "none" }}
              sx={{
                ...stylesObj.buttonMain,
                "&:hover": {
                  backgroundColor: "#E1E1E1",
                  color: "#062762",
                },
              }}
            >
              <div className={purchaseStyle.priceContainer}>
                <div className={purchaseStyle.price}>{visitorsCategories[6].price},- Kč</div>
                <div className={purchaseStyle.fullPrice}>{t("fullPrice")}</div>
              </div>
              <Button
                sx={{
                  ...stylesObj.buttonToBuy,
                  "&:hover": {
                    backgroundColor: "#E31340",
                    color: "white",
                  },
                }}
                style={{ textDecoration: "none" }}
                onClick={formClick}
              >
                {t("purchase_button")} <img src={CartIcon} style={{ marginLeft: "10px" }} />
              </Button>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
