import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SchoolContentText } from "src/components/atoms/SchoolContentText";
import { ContentPicture } from "src/components/atoms/ContentPicture";
import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";
import phoneIcon from "../../../assets/phone-call.svg";
import { Loc } from "src/core/types/loc";

export const PageSchool = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const isApp = sessionStorage.getItem("isApp");
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const numberRef = useRef<HTMLDivElement | null>(null);
  const phoneButtonCLick = (): void => {
    window.scrollTo({
      top: numberRef.current?.offsetTop,
      behavior: "smooth",
    });
  };
  const style = {
    container: {
      marginTop: isApp ? "-50px" : "0px",
    },
  };
  return (
    <Box sx={style.container}>
      <ContentPicture
        title={"visit_with_school"}
        buttonText={
          loc === "de" ? (isMobile ? "call_invitation_mob" : "call_invitation") : "call_invitation"
        }
        subtitle={"jacobs_church_in_brno"}
        mainPhoto={mainPhoto}
        click={phoneButtonCLick}
        buttonIcon={phoneIcon}
      />
      <SchoolContentText />
    </Box>
  );
};
