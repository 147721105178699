import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import style from "./styles.module.css";

export const ContentPicture = ({
  mainPhoto,
  title,
  subtitle,
  hasButton = true,
  buttonText,
  buttonIcon,
  click,
  className,
}: {
  mainPhoto: string;
  title: string;
  subtitle: string;
  hasButton?: boolean;
  buttonText: string;
  click?: () => void;
  buttonIcon?: string;
  className?: string;
}) => {
  const { t } = useTranslation();

  const stylesObj = {
    buttonMain: {
      minWidth: "330px",
      minHeight: "50px",
      padding: "5px",
      flexShrink: 0,
      backgroundColor: "#E1E1E1",
      color: "#062762 !important",
      display: "flex",
      justifyContent: "flex-end",
      borderRadius: "25px",
    },
    buttonToBuy: {
      maxWidth: "460px",
      minHeight: "50px",
      height: "50px",
      padding: "15px !important",
      marginTop: "70px",
      flexShrink: 0,
      backgroundColor: "#E31340",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alingItems: "center",
      borderRadius: "25px",
    },
  };
  return (
    <Box className={[style.box, className].join(" ")}>
      <Box className={style.overlay}></Box>
      <Box className={style.imageContainer}>
        <img alt="mainPhoto" src={mainPhoto} className={style.image} />
      </Box>
      <Box className={style.titles}>
        <span className={style.subTitleKrovy} dangerouslySetInnerHTML={{ __html: t(title) }} />
        <span className={style.subTitle}>{t(subtitle)}</span>
        {hasButton && (
          <Button
            onClick={() => click && click()}
            sx={{
              ...stylesObj.buttonToBuy,
              "&:hover": {
                backgroundColor: "#E31340",
                color: "white",
              },
            }}
            style={{ textDecoration: "none" }}
          >
            <span dangerouslySetInnerHTML={{ __html: t(buttonText) }} />{" "}
            <img alt="buttonIcon" src={buttonIcon} style={{ marginLeft: "10px" }} />
          </Button>
        )}
      </Box>
    </Box>
  );
};
