import React from "react";

//components
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import { ScheduleIcon } from "src/components/icons/ScheduleIcon";
import RedButtonMore from "src/components/atoms/RedButtonMore";
import { useTranslation } from "react-i18next";
import { useGetTimeTable } from "src/core/queries/useGetTimeTable";

const Schedule = ({ onVisible }: { onVisible: () => void }): JSX.Element => {
  const { data } = useGetTimeTable();
  const schedule = [
    {
      header: "schedule_opens",
      from: "week_days_mond",
      to: "week_days_sun",
      fromTime: data?.open_from || "",
      toTime: data?.open_to || "",
    },
    {
      header: "church_service",
      from: "week_days_mond",
      to: "week_days_friday",
      fromTime: data?.liturgy_time_week || "",
      toTime: "",
    },
    {
      header: "",
      from: "week_days_sat",
      to: "",
      fromTime: data?.liturgy_time_saturday || "",
      toTime: "",
    },
    {
      header: "",
      from: "week_days_sunday",
      to: "",
      fromTime: ` ${data?.liturgy_time_sunday}, ${data?.liturgy_time_slovak}SK` || "",
      toTime: "",
    },
  ];

  const { t } = useTranslation();
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header}>
        <ScheduleIcon
          sx={{
            width: "32px",
            height: "32px",
            "@media (max-width: 768px)": { width: "24px", height: "24px" },
          }}
        />
        <span className={styles.header_text}>{t("main_page_visit")}</span>
      </Box>
      <Box className={styles.content}>
        {schedule.map((item) => (
          <Box className={styles.mapped_wrapper} key={item.header}>
            <Box className={styles.inner_text}>{t(item.header)}</Box>
            <Box className={styles.content_inner}>
              <span className={styles.red_text}>{`${t(item.from)}${
                (item.from && item.to && "-") || ""
              }${t(item.to)}`}</span>
              <span className={styles.red_text}>
                {item.fromTime} {(item.fromTime && item.toTime && "-") || " "} {item.toTime}
              </span>
            </Box>
          </Box>
        ))}
        <Box className={styles.footer}>
          <span className={styles.red_text_bottom} onClick={onVisible}>
            {t("how_to_enter")}
          </span>
          <RedButtonMore size={{ w: "20", h: "20" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default Schedule;
