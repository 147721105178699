import { IBundle, INote } from "src/core/types/budnles";
import { Action, BundleType } from "src/core/types/reduxTypes";

const initialState: IBundle = {
  name: "",
  image_url: "",
  tours: [],
  items: [],
  is_favourite: false,
  note: {} as INote,
  discount: null,
  guide_price: null,
  is_single: false,
};

//eslint-disable-next-line @typescript-eslint/default-param-last
export const BundleReducer = (state = initialState, action: Action<BundleType, IBundle>) => {
  switch (action.type) {
    case "SET_BUNDLE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
