import React from "react";
import { Wrapper } from "src/components/atoms/NextStepButton/styledComponents";
import { ButtonArrow } from "src/components/icons/ButtonArrow";

const NextStepButton = ({
  text,
  handler,
  isActive,
}: {
  text: string;
  handler: () => void;
  isActive?: boolean;
}) => {
  return (
    <Wrapper onClick={handler} isActive={isActive ?? true}>
      {text} <ButtonArrow />
    </Wrapper>
  );
};

export default NextStepButton;
